interface IconProps {
    color?: string
    className?: string
    // @ts-ignore
    props?: React.ComponentProps
}

export type EyeCon = React.FunctionComponent<IconProps>

export const ArrowIcon: EyeCon = ({ className }) => {
    return (
        <svg
            className={`w-6 h-6 transform stroke-gray-400 ${className}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
        >
            <path strokeLinecap="round" strokeWidth="2" strokeLinejoin="round" d="M5 15l7-7 7 7"></path>
        </svg>
    )
}

export const TooltipIcon: EyeCon = ({ color = "currentColor" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke={color}
            className="ml-2 w-5 h-5 stroke-gray-500 hover:stroke-[#e5e7eb] active:stroke-[#e5e7eb] rotate-180"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
            ></path>
        </svg>
    )
}

export const LogoutIcon: EyeCon = ({ className, props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            className={className}
            {...props}
        >
            <path d="M13.7 21.1H7.3c-1.3 0-2.3-1-2.3-2.3V5.1c0-1.3 1-2.3 2.3-2.3h6.4c1.3 0 2.3 1 2.3 2.3v13.8c0 1.2-1 2.2-2.3 2.2z" />
            <path d="M18.2 8.5c.1-.1.3-.2.5-.2s.4.1.5.2l3 3c.1.1.2.3.2.5s-.1.4-.2.5l-3 3c-.1.1-.2.1-.2.2-.1 0-.2.1-.3.1-.1 0-.2 0-.3-.1-.1 0-.2-.1-.2-.2-.1-.1-.1-.2-.2-.2s0-.2 0-.3c0-.1 0-.2.1-.3s.1-.2.2-.2l1.7-1.7H9c-.2 0-.4-.1-.5-.2-.1-.1-.2-.3-.2-.5s.1-.4.2-.5c.1-.1.3-.2.5-.2h10.9l-1.7-1.7c-.1-.3-.2-.5-.2-.7s.1-.4.2-.5z" />
            <path d="M9 12.7h7.5v-1.5H9c-.4 0-.7.3-.7.7-.1.5.3.8.7.8z" fill="#FFFFFF" />
        </svg>
    )
}

export const NoDataIcon: EyeCon = ({ className, props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} {...props}>
            <path
                strokeLinecap="round"
                strokeWidth="2"
                strokeLinejoin="round"
                d="M20,0C8.95,0,0,8.95,0,20s8.95,20,20,20,20-8.95,20-20S31.05,0,20,0Zm11,21.5H9c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5H31c.83,0,1.5,.67,1.5,1.5s-.67,1.5-1.5,1.5Z"
            />
        </svg>
    )
}

export const OnboardingIcon: EyeCon = ({ props }) => (
    <svg width={31} height={36} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g filter="url(#a)">
            <path d="M9 29c-2.76 0-5-2.239-5-5V6c0-2.761 2.24-5 5-5h24v28H9Z" fill="#FEECD7" />
            <path
                d="M14.89 16.017h11.62v-1.282c0-4.191-2.72-7.84-7.92-7.84-4.81 0-8.09 3.279-8.09 7.982 0 4.482 3.36 7.732 7.85 7.804v-3.398c-2-.132-3.32-1.454-3.46-3.266Zm3.7-5.758c2.06 0 3.44 1.226 3.61 2.822h-7.22c.21-1.482 1.64-2.822 3.61-2.822Z"
                fill="#192240"
            />
            <path
                d="M23.921 23.105c1.32 0 2.39-.984 2.39-2.199 0-1.214-1.07-2.199-2.39-2.199s-2.39.985-2.39 2.199c0 1.215 1.07 2.199 2.39 2.199Z"
                fill="#F4971E"
            />
        </g>
        <defs>
            <filter
                id="a"
                x={0}
                y={0}
                width={37}
                height={36}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy={3} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2465_2680" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_2465_2680" result="shape" />
            </filter>
        </defs>
    </svg>
)

export const EyeInsureLogo: EyeCon = ({ className }) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Laag_1"
            x="0px"
            y="0px"
            viewBox="0 0 1099.84 351.5"
            xmlSpace="preserve"
        >
            <g>
                <path
                    className="st0"
                    d="M321,253.87h18.55c12.77,0,20.07-7.23,24.33-18.24l2.74-7.86L303.06,58.64h49.27l35.28,105.32h2.43   L423.5,58.64h49.88l-68.13,189.89c-10.95,30.5-29.2,48.42-62.96,48.42H321V253.87z"
                />
                <path
                    className="st0"
                    d="M525.7,155.16h118.32v-14.15c0-46.22-27.68-86.46-80.6-86.46c-48.97,0-82.43,36.16-82.43,88.03   c0,49.99,34.98,86.14,81.51,86.14c0.45,0,0.88-0.04,1.33-0.04v-37.39C541.91,191.07,527.21,176.06,525.7,155.16z M563.41,91.65   c20.99,0,34.98,13.52,36.8,31.12h-73.61C528.74,106.43,543.34,91.65,563.41,91.65z"
                />
                <ellipse className="st1" cx="620.53" cy="209.06" rx="24.34" ry="24.25" />
                <path
                    className="st0"
                    d="M176.53,155.16h118.32v-14.15c0-46.22-27.68-86.46-80.6-86.46c-48.97,0-82.43,36.16-82.43,88.03   c0,49.43,34.23,85.27,79.99,86.07v-37.48C191.48,189.72,177.98,175.14,176.53,155.16z M214.25,91.65   c20.99,0,34.98,13.52,36.8,31.12h-73.61C179.58,106.43,194.18,91.65,214.25,91.65z"
                />
                <ellipse className="st1" cx="268.51" cy="209.06" rx="24.34" ry="24.25" />
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M321,253.87h18.55c12.77,0,20.07-7.23,24.33-18.24l2.74-7.86L303.06,58.64h49.27l35.28,105.32h2.43   L423.5,58.64h49.88l-68.13,189.89c-10.95,30.5-29.2,48.42-62.96,48.42H321V253.87z"
                    />
                    <path
                        className="st0"
                        d="M525.7,155.16h118.32v-14.15c0-46.22-27.68-86.46-80.6-86.46c-48.97,0-82.43,36.16-82.43,88.03   c0,49.99,34.98,86.14,81.51,86.14c0.45,0,0.88-0.04,1.33-0.04v-37.39C541.91,191.07,527.21,176.06,525.7,155.16z M563.41,91.65   c20.99,0,34.98,13.52,36.8,31.12h-73.61C528.74,106.43,543.34,91.65,563.41,91.65z"
                    />
                    <ellipse className="fill-brand-eye-orange" cx="620.53" cy="209.06" rx="24.34" ry="24.25" />
                    <path
                        className="st0"
                        d="M176.53,155.16h118.32v-14.15c0-46.22-27.68-86.46-80.6-86.46c-48.97,0-82.43,36.16-82.43,88.03   c0,49.43,34.23,85.27,79.99,86.07v-37.48C191.48,189.72,177.98,175.14,176.53,155.16z M214.25,91.65   c20.99,0,34.98,13.52,36.8,31.12h-73.61C179.58,106.43,194.18,91.65,214.25,91.65z"
                    />
                    <ellipse className="fill-brand-eye-orange" cx="268.51" cy="209.06" rx="24.34" ry="24.25" />
                </g>
                <g>
                    <g>
                        <path
                            className="st2"
                            d="M685.59,155.38c0-5.72,4.18-9.24,10.01-9.24c5.83,0,10.01,3.52,10.01,9.24c0,5.72-4.18,9.24-10.01,9.24      C689.76,164.62,685.59,161.1,685.59,155.38z M687.13,171.11h17.04v58.06h-17.04V171.11z"
                        />
                        <path
                            className="st2"
                            d="M716.7,171.11h16.05v6.38h0.88c2.31-3.96,7.8-7.81,15.61-7.81c12.87,0,20.78,8.69,20.78,20.78v38.7h-17.04      v-33.43c0-5.94-3.52-10.23-9.35-10.23c-5.94,0-9.9,4.95-9.9,11.11v32.55H716.7V171.11z"
                        />
                        <path
                            className="st2"
                            d="M779.38,211.35h15.5c0.44,4.07,3.85,6.16,9.46,6.16c5.72,0,8.91-2.09,8.91-5.39      c0-10.45-32.77,0.99-32.77-23.97c0-9.9,8.36-18.47,23.86-18.47c13.41,0,23.09,6.38,23.86,18.47h-14.73      c-0.55-3.52-3.52-5.94-9.13-5.94c-5.17,0-8.47,2.09-8.47,5.17c0,9.46,33.87-1.87,33.87,23.86c0,11.21-9.02,19.35-25.51,19.35      C787.52,230.59,779.71,223.12,779.38,211.35z"
                        />
                        <path
                            className="st2"
                            d="M840.74,209.81v-38.7h17.04v33.43c0,6.05,3.52,10.23,9.35,10.23c5.94,0,9.9-4.95,9.9-11.11v-32.55h17.04      v58.06h-16.16v-6.38h-0.88c-2.2,3.85-7.7,7.81-15.5,7.81C848.66,230.59,840.74,221.91,840.74,209.81z"
                        />
                        <path
                            className="st2"
                            d="M907.49,171.11h16.06v8.8h0.88c1.98-4.29,7.15-9.24,14.96-9.24h5.61v16.05h-6.71      c-8.36,0-13.74,6.05-13.74,16.05v26.39h-17.04V171.11z"
                        />
                        <path
                            className="st2"
                            d="M951.04,200.46c0-18.14,12.09-30.79,29.8-30.79c19.13,0,29.14,14.07,29.14,30.24v4.95H967.2      c0.55,7.37,5.94,12.65,13.96,12.65c6.71,0,11.21-3.3,12.86-6.93h15.4c-2.64,12.21-13.19,20.01-28.92,20.01      C963.68,230.59,951.04,217.95,951.04,200.46z M994.14,193.54c-0.66-6.16-5.72-10.89-13.3-10.89c-7.26,0-12.53,5.17-13.3,10.89      H994.14z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const EyeGuideYouHeader: EyeCon = ({ className, props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 100" {...props} className={className}>
        <defs>
            <clipPath id="clippath-header">
                <path className="cls-1" d="M44.5 39.37h54v25.61h-54z" />
            </clipPath>
            <clipPath id="clippath-header-1">
                <path className="cls-1" d="M44.5 39.37h54v25.61h-54z" />
            </clipPath>
            <clipPath id="clippath-header-2">
                <path className="cls-1" d="M0 0h800v99H0z" />
            </clipPath>
            <clipPath id="clippath-header-3">
                <path className="cls-1" d="M472.5.31h337v204.1h-337z" />
            </clipPath>
            <style>
                {".cls-1{fill:none}.cls-4{fill:#f49719}.cls-6{fill:#192440}.cls-7{fill:rgba(254,226,194,.8)}"}
            </style>
        </defs>
        <path
            style={{
                fill: "#feecd7",
            }}
            d="M-.5 0h800v100H-.5z"
            id="Laag_header_4"
            data-name="Laag 4"
        />
        <g id="Laag_header_2" data-name="Laag 2">
            <g
                style={{
                    clipPath: "url(#clippath-header)",
                }}
                id="Illustratie_1"
                data-name="Illustratie 1"
            >
                <g
                    style={{
                        clipPath: "url(#clippath-header-1)",
                    }}
                >
                    <path
                        className="cls-6"
                        d="M64.41 60.43h1.95c1.34 0 2.11-.76 2.56-1.93l.29-.83-6.69-17.87h5.19l3.71 11.13h.26L75.2 39.8h5.25l-7.17 20.06c-1.15 3.22-3.07 5.11-6.63 5.11h-2.24v-4.55ZM85.96 50h12.45v-1.49c0-4.88-2.91-9.13-8.48-9.13-5.15 0-8.68 3.82-8.68 9.3s3.68 9.1 8.58 9.1h.14v-3.95c-2.31-.02-3.86-1.61-4.01-3.82m3.97-6.71c2.21 0 3.68 1.43 3.87 3.29h-7.75c.22-1.73 1.76-3.29 3.87-3.29"
                    />
                    <path
                        className="cls-4"
                        d="M98.5 55.7c0 1.41-1.15 2.56-2.56 2.56s-2.56-1.15-2.56-2.56 1.15-2.56 2.56-2.56 2.56 1.15 2.56 2.56"
                    />
                    <path
                        className="cls-6"
                        d="M49.21 50h12.45v-1.49c0-4.88-2.91-9.13-8.48-9.13-5.15 0-8.68 3.82-8.68 9.3s3.6 9.01 8.42 9.09v-3.96c-2.14-.15-3.56-1.69-3.71-3.8m3.97-6.71c2.21 0 3.68 1.43 3.87 3.29H49.3c.22-1.73 1.76-3.29 3.87-3.29"
                    />
                    <path
                        className="cls-4"
                        d="M61.45 55.7c0 1.41-1.15 2.56-2.56 2.56s-2.56-1.15-2.56-2.56 1.15-2.56 2.56-2.56 2.56 1.15 2.56 2.56"
                    />
                </g>
            </g>
            <text
                transform="translate(107.59 58.53)"
                style={{
                    fill: "#1a243e",
                    fontFamily: "Silka-Regular,Silka",
                    fontSize: 28,
                }}
            >
                <tspan x={0} y={0}>
                    {"wi"}
                </tspan>
                <tspan
                    x={31.11}
                    y={0}
                    style={{
                        letterSpacing: "-.01em",
                    }}
                >
                    {"l"}
                </tspan>
                <tspan x={38.83} y={0}>
                    {"l guide "}
                </tspan>
                <tspan
                    x={139.83}
                    y={0}
                    style={{
                        letterSpacing: "-.02em",
                    }}
                >
                    {"y"}
                </tspan>
                <tspan x={155.12} y={0}>
                    {"ou"}
                </tspan>
            </text>
            <path d="M797.5-2h-2v2h2v-2z" />
            <g
                style={{
                    clipPath: "url(#clippath-header-2)",
                }}
            >
                <g
                    style={{
                        clipPath: "url(#clippath-header-3)",
                    }}
                >
                    <path
                        className="cls-7"
                        d="M624.58 69.68c-8.9 0-16.11 6.87-16.11 15.33s7.21 15.33 16.11 15.33c8.9 0 16.11-6.86 16.11-15.33 0-8.47-7.21-15.33-16.11-15.33ZM589.31 35c-8.9 0-16.11 6.87-16.11 15.33s7.21 15.33 16.11 15.33c8.9 0 16.11-6.86 16.11-15.33 0-8.47-7.21-15.33-16.11-15.33ZM793.39 35c-8.9 0-16.11 6.87-16.11 15.33s7.21 15.33 16.11 15.33c8.9 0 16.11-6.86 16.11-15.33S802.29 35 793.39 35ZM724.91 69.68h-64.44c-8.9 0-16.11 6.87-16.11 15.33 0 8.47 7.21 15.33 16.11 15.33h64.44c8.9 0 16.11-6.86 16.11-15.33s-7.21-15.33-16.11-15.33ZM689.14 35H624.7c-8.9 0-16.11 6.86-16.11 15.33 0 8.47 7.21 15.33 16.11 15.33h64.44c8.9 0 16.11-6.86 16.11-15.33 0-8.47-7.21-15.33-16.11-15.33ZM553.42 35H521.2c-8.9 0-16.11 6.86-16.11 15.33 0 8.47 7.21 15.33 16.11 15.33h32.22c8.9 0 16.11-6.86 16.11-15.33 0-8.47-7.21-15.33-16.11-15.33ZM793.02 69.68H760.8c-8.9 0-16.11 6.87-16.11 15.33s7.21 15.33 16.11 15.33h32.22c8.9 0 16.11-6.86 16.11-15.33s-7.21-15.33-16.11-15.33ZM757.51 35h-32.22c-8.9 0-16.11 6.86-16.11 15.33 0 8.47 7.21 15.33 16.11 15.33h32.22c8.9 0 16.11-6.86 16.11-15.33 0-8.47-7.21-15.33-16.11-15.33ZM656.8.31c-8.9 0-16.11 6.87-16.11 15.33 0 8.47 7.21 15.33 16.11 15.33s16.11-6.86 16.11-15.33S665.7.31 656.8.31ZM692.69.31c-8.9 0-16.11 6.87-16.11 15.33 0 8.47 7.21 15.33 16.11 15.33 8.9 0 16.11-6.86 16.11-15.33 0-8.47-7.21-15.33-16.11-15.33ZM488.61 30.98h64.44c8.9 0 16.11-6.86 16.11-15.33S561.95.32 553.05.32h-64.44c-8.9 0-16.11 6.87-16.11 15.33s7.21 15.33 16.11 15.33ZM728.59 30.98h64.44c8.9 0 16.11-6.86 16.11-15.33 0-8.47-7.21-15.33-16.11-15.33h-64.44c-8.9 0-16.11 6.87-16.11 15.33s7.21 15.33 16.11 15.33ZM588.69 30.98h32.22c8.9 0 16.11-6.86 16.11-15.33 0-8.47-7.21-15.33-16.11-15.33h-32.22c-8.9 0-16.11 6.87-16.11 15.33 0 8.47 7.21 15.33 16.11 15.33Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export const EyeUnderwritingLogo: EyeCon = ({ className, color = "currentColor", props }) => (
    <svg
        width="166"
        height="44"
        viewBox="0 0 166 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        color={color}
        {...props}
    >
        <g clipPath="url(#clip0_3708_30696)">
            <path
                d="M33.3159 36.5147H36.5827C38.8331 36.5147 40.117 35.2586 40.8678 33.3454L41.3492 31.9801L30.156 2.58673H38.8331L45.0459 20.8905H45.4738L51.3655 2.58673H60.1497L48.1522 35.5863C46.2246 40.8853 43.0094 44 37.0641 44H33.314V36.5147H33.3159Z"
                fill="#18243F"
            />
            <path
                d="M69.3617 19.3596H90.1968V16.9021C90.1968 8.87064 85.3233 1.8768 76.0024 1.8768C67.3787 1.8768 61.487 8.16069 61.487 17.1751C61.487 26.1896 67.6462 32.144 75.8419 32.144C75.9212 32.144 75.9969 32.1367 76.0762 32.1367V25.6398C72.2172 25.5998 69.6273 22.993 69.3617 19.3596ZM76.0042 8.32271C79.7009 8.32271 82.1635 10.671 82.4863 13.731H69.524C69.8985 10.8912 72.4699 8.32271 76.0042 8.32271Z"
                fill="#18243F"
            />
            <path
                d="M86.063 32.9413C88.4306 32.9413 90.35 31.0546 90.35 28.7272C90.35 26.3998 88.4306 24.513 86.063 24.513C83.6954 24.513 81.7761 26.3998 81.7761 28.7272C81.7761 31.0546 83.6954 32.9413 86.063 32.9413Z"
                fill="#F4971E"
            />
            <path
                d="M7.87472 19.3596H28.7098V16.9021C28.7098 8.87064 23.8363 1.8768 14.5154 1.8768C5.89175 1.8768 0 8.16069 0 17.1751C0 26.1896 6.0264 31.9947 14.0856 32.1312V25.618C10.507 25.3668 8.12928 22.8328 7.87472 19.3596ZM14.5172 8.32271C18.2139 8.32271 20.6764 10.671 20.9993 13.731H8.0352C8.41151 10.8912 10.9811 8.32271 14.5172 8.32271Z"
                fill="#18243F"
            />
            <path
                d="M24.0724 32.9413C26.44 32.9413 28.3594 31.0546 28.3594 28.7272C28.3594 26.3998 26.44 24.513 24.0724 24.513C21.7048 24.513 19.7855 26.3998 19.7855 28.7272C19.7855 31.0546 21.7048 32.9413 24.0724 32.9413Z"
                fill="#F4971E"
            />
            <path
                d="M98.2136 11.0824V4.28696H101.246V10.1558C101.246 11.2171 101.871 11.9507 102.91 11.9507C103.949 11.9507 104.67 11.0824 104.67 10.0011V4.28696H107.702V14.4792H104.827V13.3596H104.67C104.279 14.035 103.301 14.7304 101.912 14.7304C99.6229 14.7304 98.2136 13.2049 98.2136 11.0824Z"
                fill="#16243E"
            />
            <path
                d="M110.089 4.28695H112.945V5.40647H113.102C113.513 4.71109 114.491 4.03574 115.88 4.03574C118.169 4.03574 119.576 5.5612 119.576 7.68374V14.4792H116.544V8.61031C116.544 7.56724 115.918 6.81543 114.88 6.81543C113.841 6.81543 113.12 7.68374 113.12 8.76504V14.4792H110.087V4.28695H110.089Z"
                fill="#16243E"
            />
            <path
                d="M121.24 9.4404C121.24 6.23656 123.157 4.03575 125.935 4.03575C127.558 4.03575 128.497 4.90406 128.849 5.44471H129.006V0H132.039V14.4792H129.026V13.3214H128.869C128.576 13.7856 127.715 14.7304 126.014 14.7304C123.177 14.7304 121.24 12.6442 121.24 9.4404ZM129.065 9.38215C129.065 7.76021 128.067 6.73716 126.678 6.73716C125.289 6.73716 124.311 7.81846 124.311 9.38215C124.311 10.9458 125.271 12.0271 126.698 12.0271C128.126 12.0271 129.065 10.8894 129.065 9.38215Z"
                fill="#16243E"
            />
            <path
                d="M133.682 9.44039C133.682 6.25476 135.833 4.03574 138.984 4.03574C142.387 4.03574 144.167 6.50597 144.167 9.34392V10.2122H136.558C136.656 11.5065 137.615 12.4331 139.043 12.4331C140.236 12.4331 141.038 11.8542 141.332 11.2171H144.071C143.601 13.3596 141.723 14.7304 138.926 14.7304C135.933 14.7304 133.682 12.5095 133.682 9.44039ZM141.35 8.22439C141.234 7.1431 140.334 6.31301 138.984 6.31301C137.633 6.31301 136.753 7.21955 136.617 8.22439H141.35Z"
                fill="#16243E"
            />
            <path
                d="M146.163 4.28695H149.018V5.83062H149.175C149.527 5.07699 150.446 4.20868 151.835 4.20868H152.833V7.0266H151.64C150.153 7.0266 149.195 8.08787 149.195 9.84452V14.4773H146.163V4.28695Z"
                fill="#16243E"
            />
            <path
                d="M98.2136 21.9445H101.362L102.731 28.4887H102.888L104.843 21.9445H107.796L109.754 28.4887H109.91L111.279 21.9445H114.43L111.69 32.1367H108.365L106.389 25.9202H106.271L104.275 32.1367H100.949L98.2136 21.9445Z"
                fill="#16243E"
            />
            <path
                d="M116.173 21.9445H119.03V23.4882H119.187C119.539 22.7345 120.46 21.8662 121.849 21.8662H122.847V24.6841H121.653C120.166 24.6841 119.209 25.7454 119.209 27.5021V32.1349H116.177V21.9445H116.173Z"
                fill="#16243E"
            />
            <path
                d="M124.566 19.1848C124.566 18.1818 125.309 17.5629 126.346 17.5629C127.383 17.5629 128.126 18.18 128.126 19.1848C128.126 20.1896 127.383 20.8068 126.346 20.8068C125.309 20.8068 124.566 20.1878 124.566 19.1848ZM124.839 21.9445H127.872V32.1367H124.839V21.9445Z"
                fill="#16243E"
            />
            <path
                d="M131.197 28.6052V24.28H129.535V21.9627H130.513C131.061 21.9627 131.354 21.5968 131.354 21.0561V19.183H134.23V21.9445H136.929V24.28H134.23V28.4505C134.23 29.2041 134.66 29.6665 135.56 29.6665H136.89V32.1367H134.798C132.548 32.1367 131.197 30.8442 131.197 28.6052Z"
                fill="#16243E"
            />
            <path
                d="M138.515 19.1848C138.515 18.1818 139.258 17.5629 140.295 17.5629C141.332 17.5629 142.075 18.18 142.075 19.1848C142.075 20.1896 141.332 20.8068 140.295 20.8068C139.258 20.8049 138.515 20.1878 138.515 19.1848ZM138.788 21.9445H141.821V32.1367H138.788V21.9445Z"
                fill="#16243E"
            />
            <path
                d="M144.051 21.9445H146.906V23.064H147.063C147.474 22.3686 148.452 21.6933 149.841 21.6933C152.13 21.6933 153.538 23.2187 153.538 25.3413V32.1367H150.505V26.2678C150.505 25.2248 149.88 24.473 148.841 24.473C147.803 24.473 147.081 25.3413 147.081 26.4226V32.1367H144.049V21.9445H144.051Z"
                fill="#16243E"
            />
            <path
                d="M155.69 33.0651H158.468C158.605 33.8369 159.271 34.4158 160.601 34.4158C162.185 34.4158 162.988 33.6057 162.988 32.022V30.8442H162.831C162.479 31.3467 161.578 32.2532 159.937 32.2532C157.256 32.2532 155.202 30.2654 155.202 26.9832C155.202 23.7011 157.216 21.6933 159.857 21.6933C161.54 21.6933 162.499 22.5998 162.811 23.1022H162.967V21.9445H166V32.022C166 34.687 164.297 36.8296 160.601 36.8296C157.393 36.8296 155.788 35.0147 155.69 33.0651ZM163.026 26.9832C163.026 25.3613 162.029 24.3965 160.64 24.3965C159.25 24.3965 158.273 25.4196 158.273 26.9832C158.273 28.5469 159.232 29.55 160.66 29.55C162.108 29.55 163.026 28.4887 163.026 26.9832Z"
                fill="#16243E"
            />
        </g>
        <defs>
            <clipPath id="clip0_3708_30696">
                <rect width="166" height="44" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import { NoDataIcon } from "../../icons/Icons"
import { Typography } from "@eyectrl/baseplate-design-system"

interface AlertProps {
    type: "success" | "warning" | "loading" | "error"
    title?: string
    text?: string
    iconClass?: string
    className?: string
}

export const Alert: React.FC<AlertProps> = ({
    type,
    title,
    text,
    iconClass = "h-12 w-12",
    className = "space-x-4 p-4 w-full",
}) => {
    const backgroundColor =
        type === "success"
            ? "bg-eye-green-500/50"
            : type === "warning"
              ? "bg-yellow-100"
              : type === "loading"
                ? "bg-white"
                : "bg-neutral-200"

    return (
        <div className={`flex items-center ${className} ${backgroundColor}`}>
            {type === "success" ? (
                <CheckCircleIcon className={`fill-green-500 ${iconClass}`} />
            ) : type === "error" ? (
                <ExclamationCircleIcon className={`fill-red-500 ${iconClass}`} />
            ) : type === "warning" ? (
                <ExclamationTriangleIcon className={`fill-yellow-200 ${iconClass}`} />
            ) : type === "loading" ? (
                <svg
                    className="animate-spin h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            ) : (
                <NoDataIcon className={`fill-neutral-300 mt-2 ${iconClass}`} />
            )}
            <div className="flex flex-col">
                <Typography variant="body-1-semibold">{title}</Typography>

                {text && <Typography>{text}</Typography>}
            </div>
        </div>
    )
}

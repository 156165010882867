import { PageLayout } from "@components/layout/PageLayout"
import { Button } from "@eyectrl/baseplate-design-system"
import { Typography } from "@eyectrl/baseplate-design-system"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import ThreatHuntingIllustration from "@assets/illustrations/threatHuntingIllustration.svg?react"
import NoDataIllustration from "@assets/illustrations/searchIllustration.svg?react"

interface Props {
    hasThreatHuntingData: boolean
    onCompleteIntroduction: () => void
}

export const ThreatHuntingIntroduction: React.FC<Props> = ({ hasThreatHuntingData, onCompleteIntroduction }) => {
    const { t } = useTranslation()
    return (
        <PageLayout variant={"detailPage"}>
            <div className="flex justify-center items-center mt-12">
                <div>
                    <div className="flex justify-center">
                        {hasThreatHuntingData ? (
                            <ThreatHuntingIllustration className="max-w-[300px] md:max-w-[600px]" />
                        ) : (
                            <NoDataIllustration className="max-w-[300px] md:max-w-[600px]" />
                        )}
                    </div>
                    <div className="flex flex-col gap-4 justify-center text-center max-w-[600px] mt-10">
                        {hasThreatHuntingData ? (
                            <Typography variant="header-2">{t("threatHunting:introduction.title")}</Typography>
                        ) : (
                            <Typography variant="header-2">
                                {t("threatHunting:introduction.missingDataTitle")}
                            </Typography>
                        )}

                        {hasThreatHuntingData === false && (
                            <Typography>
                                <Trans
                                    i18nKey="threatHunting:introduction.descriptionNoData"
                                    components={[
                                        <a
                                            href="https://kb.eye.security/vulnerabilityandthreathuntingsummary"
                                            key="kb-link"
                                            className="inline underline"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        />,
                                    ]}
                                />
                            </Typography>
                        )}

                        {hasThreatHuntingData === true && (
                            <>
                                <Typography>
                                    <Trans i18nKey="threatHunting:introduction.descriptionWithData" />
                                </Typography>
                                <div className="mt-4" onClick={() => onCompleteIntroduction()}>
                                    <Button variant="primary">{t("threatHunting:introduction.button")}</Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

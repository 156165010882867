import { InfiniteData, UseInfiniteQueryResult, useInfiniteQuery } from "@tanstack/react-query"
import { getAwarenessEventsEndpoint } from "@/api/endpoints"
import { getRequest } from "@/api/requests"
import { useAuth0 } from "@auth0/auth0-react"
import { IAwarenessEvent } from "../awarenessTypes"

export interface IAwarenessEventsDataSource {
    data: IAwarenessEvent[] | null
    meta_data?: {
        last_key?: string
        last_updated?: string | null
    }
}

export const useAwarenessEvents = (
    eyed: string,
    selectedMonth: string,
    type: IAwarenessEvent["type"],
): UseInfiniteQueryResult<InfiniteData<IAwarenessEventsDataSource, unknown>, Error> => {
    const { getAccessTokenSilently } = useAuth0()

    return useInfiniteQuery<IAwarenessEventsDataSource, Error>({
        queryKey: getAwarenessEventsEndpoint(eyed, selectedMonth, type).queryKey,
        // The useInifiniteQuery will handle the concatenation of multiple requests, we only need to fetch calls with the last_key provided in the query params.
        queryFn: (pageData) => {
            // Check if the pageParam ( in this case the last_key string) is present
            if (pageData.pageParam && typeof pageData.pageParam === "string") {
                return getRequest(
                    getAwarenessEventsEndpoint(eyed, selectedMonth, type, pageData.pageParam).url,
                    getAccessTokenSilently,
                )
            }

            return getRequest(getAwarenessEventsEndpoint(eyed, selectedMonth, type).url, getAccessTokenSilently)
        },
        initialPageParam: undefined,
        // The return data has a last_key in the meta_data object which indicates there are more events available.
        // To get this data whe need to send a new fetch request with the last_key provided in query params
        getNextPageParam: (lastPage) => lastPage.meta_data?.last_key,
    })
}

import { getRoutes } from "@/config/routes/routeConfig"
import { getRouterLinks } from "@/config/routes/routeLinksConfig"
import { NavigateFunction, generatePath, matchPath } from "react-router-dom"

export const navigateToNewEyedRoute = (
    newEyed: string,
    currentUrl: string,
    navigate: NavigateFunction,
    restParams: {
        readonly [x: string]: string | undefined
    },
) => {
    const routes = getRoutes()
    const routerLinks = getRouterLinks()

    const eyedIdentifier = "EyeD"
    const urlSections = currentUrl.split("/")
    const eyedIndex = urlSections.findIndex((section) => section.startsWith(eyedIdentifier))

    // No EYED was found in the url, so we can just redirect the user to the dashboard.
    if (eyedIndex === -1) {
        // eyed not found,
        navigate(
            generatePath(routes.dashboard.path, {
                ...restParams,
                eyed: newEyed,
            }),
        )
    }

    // If we have a match on a detection detail page, we redirect the user to the parent page
    if (matchPath(routes.detections.detail, location.pathname)) {
        navigate(routerLinks.detections.root({ eyed: newEyed }))
        return
    }

    // If we have a match on a recommendation detail page, we redirect the user to the parent page
    if (matchPath(routes.recommendations.detail, location.pathname)) {
        navigate(routerLinks.recommendations.root({ eyed: newEyed }))
        return
    }

    // If none of the above applies, we simply gather the url, replace the EYED and navigate the user there.
    // Build the new url
    const newUrlSections = urlSections
        .map((section, index) => {
            if (index === eyedIndex) {
                return newEyed
            }

            return section
        })
        .join("/")

    // Navigate the user to the new url
    navigate(newUrlSections)
}

import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { IDateRange } from "@utils/dateRangeUtils"
import { getDetectionsV2Endpoint } from "@/api/endpoints"
import { useAuth0 } from "@auth0/auth0-react"
import { getRequest } from "@/api/requests"
import { IDetectionV2 } from "../types"
import { IDetectionsFilterOption, IDetectionsFilterSettings } from "./useDetectionsFilters"

interface DetectionsV2DataSource {
    data: IDetectionV2[] | null
    meta_data?: {
        last_key: string
        last_updated: string
    } | null
}

/**
 * Returns a list of detections given the eyed and an optional dateRange
 * NOTE: Make sure the date range is valid ( i.e. correct from / to set, no future dates are provided.)
 * @param eyed
 * @param dateRange
 * @returns
 */
export const useDetectionsV2 = (
    eyed: string,
    dateRange?: IDateRange,
    searchQuery?: string,
    sortBy?: IDetectionsFilterOption,
    sortDirection?: "ASC" | "DESC",
    filterSettings?: IDetectionsFilterSettings,
): UseQueryResult<DetectionsV2DataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()

    const detectionsEndpoint = getDetectionsV2Endpoint(
        eyed,
        dateRange?.from,
        dateRange?.to,
        searchQuery,
        sortBy,
        sortDirection,
        filterSettings,
    )

    return useQuery<DetectionsV2DataSource, Error>({
        queryKey: detectionsEndpoint.queryKey,
        queryFn: () => getRequest(detectionsEndpoint.url, getAccessTokenSilently),
    })
}

import { Menu, Transition, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import React from "react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import classNames from "classnames"

interface Props {
    options: Array<string | number>
    selectedOption: string | number
    onSelectOption: (option: string | number) => void
    yOrientation?: "top" | "bottom"
    xOrientation?: "left" | "right"
}

export const SelectMenu: React.FC<Props> = ({
    options,
    selectedOption,
    onSelectOption,
    yOrientation = "top",
    xOrientation = "right",
}) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton className="inline-flex w-full justify-center rounded-md border px-4 py-2 font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 bg-white">
                    {selectedOption}

                    <ChevronDownIcon className="ml-2 -mr-1 mt-1 h-5 w-5" aria-hidden="true" />
                </MenuButton>
            </div>

            <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems
                    className={classNames(
                        { "-translate-y-full": yOrientation === "top" },
                        { "top-[40px]": yOrientation === "bottom" },
                        { "left-0": xOrientation === "right" },
                        { "-translate-x-3/4": xOrientation === "left" },
                        "absolute mt-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50",
                    )}
                >
                    <div className="px-1 py-1 relative max-h-[300px] overflow-auto">
                        {options.map((option) => {
                            return (
                                <MenuItem key={option}>
                                    {({ active }) => (
                                        <button
                                            onClick={() => {
                                                onSelectOption(option)
                                            }}
                                            className={`${
                                                active
                                                    ? "bg-brand-eye-orange text-text-primary-dark"
                                                    : "text-brand-eye-blue"
                                            } group flex w-full items-center rounded-md px-2 py-2 `}
                                        >
                                            {option}
                                        </button>
                                    )}
                                </MenuItem>
                            )
                        })}
                    </div>
                </MenuItems>
            </Transition>
        </Menu>
    )
}

import { IAwarenessMetric, IEnrichedAwarenessMetric } from "@features/awareness/awarenessTypes"

/**
 * Function that normalises the sent data into the same range as the opened/reported data.
 * Example:
 * 2000 events are sent, 100 have been opened and 50 have been reported.
 * To normalise we sent events, divide them by the maximum value of opened and reported and add a little pading.
 *
 * Max division factor: 2000 / 100 = 20
 * Event has 1500 sent, we divide that by the division factor: 1500 / 20 = 75
 * Then we add a padding equal to the maximum value of phished or reported metrics to make sure the value is always above the bar chart.
 * Normalised sent = 75+50 = 125
 *
 * @param metrics
 * @returns
 */
export const enrichMetricsWithNormalisedSentData = (metrics: IAwarenessMetric[]) => {
    // Determine the max value of the sent data
    const maxSent = Math.max(...metrics.map((metric) => metric.sent))

    // Determine the max value of the opened/reported data
    const maxPhished = Math.max(...metrics.map((metric) => metric.phished))
    const maxReported = Math.max(...metrics.map((metric) => metric.reported))
    const maxPhishedReported = Math.max(maxPhished, maxReported)

    // Calculate a divisionFactor
    const divider = maxSent / maxPhishedReported
    const padding = maxPhishedReported * 1.1

    const withNormalisedMetrics: IEnrichedAwarenessMetric[] = metrics.map((metric) => {
        if (metric.sent === 0) {
            return { ...metric, normalisedSent: Math.round(padding) }
        }

        const normalisedMetric = metric.sent / divider
        return {
            ...metric,
            // Set the normalised metric, and also add a little bit of padding to make sure the line floats above the bar chart.
            normalisedSent: Math.round(normalisedMetric + padding),
        }
    })

    return withNormalisedMetrics
}

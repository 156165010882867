import { UserContext } from "@/providers/UserContext"
import React, { PropsWithChildren, useContext } from "react"
import { Navigate, generatePath, useParams } from "react-router-dom"

interface Props {
    path: string
}

/**
 * This component checks if an eyed is present in the current routes parameters
 * Then redirects the user to the desired path. If the eyed is not present, it redirects with the initial eyed.
 * @param param0
 * @returns
 */
export const EyedRedirect: React.FC<PropsWithChildren<Props>> = ({ path }) => {
    const { eyed: paramsEyed, ...rest } = useParams()
    const { initialEyed } = useContext(UserContext)

    // if a eyed is already present in the url, we can safely render the component
    if (paramsEyed) {
        return <Navigate to={generatePath(path, { eyed: paramsEyed, ...rest })} />
    }

    // otherwise we enhance the same url, with the initial eyed ( which is the first org in the org endpoint data )
    return <Navigate to={generatePath(path, { eyed: initialEyed, ...rest })} />
}

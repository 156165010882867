import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import { Typography } from "@eyectrl/baseplate-design-system"
import { ArrowLongRightIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "react-i18next"

export const AwarenessFaq: React.FC = () => {
    const { t } = useTranslation("pages")

    const faqEntries = t("awareness.faq.entries", { returnObjects: true }) ?? []

    return (
        <PageLayout variant="detailPage">
            <PageHeader title={t("awareness.faq.title")} withBreadcrumbs />

            <Typography>{t("awareness.faq.description")}</Typography>

            <section className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
                {faqEntries.map((entry, index) => {
                    return (
                        <div key={`faq-entry-${index}`} className="flex flex-col">
                            <Typography variant="body-1-semibold">{entry.title}</Typography>
                            <Typography variant="body-1" color="text-secondary" className="mt-2">
                                {entry.description}
                            </Typography>

                            <div className="flex items-center mt-4 text-text-link-light gap-1 hover:underline">
                                <a rel="noopener noreferrer" href={entry.linkTo} target="_blank">
                                    {t("awareness.faq.toKnowledgeBase")}
                                </a>
                                <ArrowLongRightIcon width={18} className="mt-0.5" />
                            </div>
                        </div>
                    )
                })}
            </section>
        </PageLayout>
    )
}

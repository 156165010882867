import { UserContext } from "@/providers/UserContext"
import { useDashboardMetrics } from "@features/dashboard/hooks/useDashboardMetrics"
import { calculatePercentage } from "@utils/calculationUtils"
import { useContext } from "react"

export const useInsuranceUpsell = () => {
    const { activeOrganisation, userProfile, updateUserProfileProperty } = useContext(UserContext)
    const { data } = useDashboardMetrics(activeOrganisation?.eyed ?? "")

    // Get all the component to check if the organisation is qualified for insurance.
    const MFAVerdict = data?.data.mfa?.verdict
    const endpointCoveragePercentage = calculatePercentage(
        data?.data.endpoint_coverage?.covered ?? 0,
        data?.data.endpoint_coverage?.total ?? 0,
    )
    const endpointCoverageQualifies = endpointCoveragePercentage >= 85
    const mfaQualifies = MFAVerdict === "Good" || MFAVerdict === "Sufficient"
    const hasCompletedInsuranceUpsell = userProfile.has_completed_insurance_upsell

    const showInsuranceUpsell =
        hasCompletedInsuranceUpsell === false &&
        activeOrganisation?.insured !== true &&
        endpointCoverageQualifies === true &&
        mfaQualifies === true

    const completeInsuranceUpsell = () => {
        updateUserProfileProperty({ has_completed_insurance_upsell: true })
    }

    return { showInsuranceUpsell, completeInsuranceUpsell }
}

import { getUserOrganisationsEndpoint, patchAwarenessStateEndpoint } from "@/api/endpoints"
import { patchRequest } from "@/api/requests"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { IAwarenessLanguage } from "../awarenessTypes"

export interface IAwarenessStateUpdate {
    completed: boolean
    step: number
    default_language: IAwarenessLanguage
}

export const usePatchAwarenessState = (onSuccess?: () => void, onError?: () => void) => {
    const { getAccessTokenSilently } = useAuth0()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (variables: { eyed: string; update: Partial<IAwarenessStateUpdate> }) => {
            const endpoint = patchAwarenessStateEndpoint(variables.eyed)

            return patchRequest<Partial<IAwarenessStateUpdate>, { data: boolean }>(
                endpoint.url,
                variables.update,
                getAccessTokenSilently,
            )
        },
        onSuccess: () => {
            onSuccess?.()
            // Refetches the user organisations. After patching the state the user might have his onboarding completed set in the organisation.
            // This will trigger a redirect from the AwarenessLanding wrapper component.
            queryClient.invalidateQueries({ queryKey: getUserOrganisationsEndpoint().queryKey })
        },
        onError: () => {
            onError?.()
        },
    })
}

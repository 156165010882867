import { Properties } from "posthog-js"
import { usePostHog } from "posthog-js/react"

export const useCapturePostHogEvent = () => {
    const posthog = usePostHog()

    const captureEvent = (eventType: string, properties?: Properties) => {
        posthog.capture(eventType, properties)
    }

    return { captureEvent }
}

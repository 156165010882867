import { Typography } from "@eyectrl/baseplate-design-system"
import React, { useState } from "react"

interface Props {
    acceptedFileExtensions: string[]
    onFileUpload: (file: File) => void
    helperText?: string
}

export const FileUpload: React.FC<Props> = ({ onFileUpload, helperText, acceptedFileExtensions }) => {
    const [draggingOver, setDraggingOver] = useState(false)
    const [uploadError, setUploadError] = useState<string>()

    const handleFileUpload = (file: File) => {
        if (acceptedFileExtensions.includes(file.type)) {
            onFileUpload(file)
            return
        }

        setUploadError(`You are not allowed to upload files with the ${file.type} extension`)
    }

    const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files ? Array.from(event.target.files) : []
        if (files[0]) {
            handleFileUpload(files[0])
        }
    }

    const stopEvent = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        stopEvent(event)
    }

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        stopEvent(event)
        setDraggingOver(false)
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        stopEvent(event)
        setDraggingOver(true)
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        stopEvent(event)
        setDraggingOver(false)
        const file = [...event.dataTransfer.files][0]

        handleFileUpload(file)
    }

    return (
        <div>
            <div>
                <div className="w-full">
                    <div
                        className={`${
                            draggingOver ? "border-brand-eye-orange" : "border-neutral-300"
                        } flex items-center justify-center p-20 border border-dashed rounded-md`}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                    >
                        <label
                            htmlFor="file-upload"
                            className="bg-neutral-300 px-4 py-1.5 rounded-md hover:cursor-pointer hover:bg-neutral-100 font-semibold text-base"
                        >
                            Choose File
                        </label>
                        <input id="file-upload" name="files" type="file" className="sr-only" onChange={onFileChanged} />

                        <p className="pl-2 text-base">or drag and drop</p>
                    </div>
                </div>
            </div>

            {helperText && (
                <div className="mt-4">
                    <Typography variant="body-2">Only CSV uploads allowed</Typography>
                </div>
            )}

            {uploadError && (
                <div>
                    <Typography variant="body-2" color="text-critical">
                        {uploadError}
                    </Typography>
                </div>
            )}
        </div>
    )
}

import React from "react"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { QueryClientProvider } from "@tanstack/react-query"
import { Router } from "./router/Router"
import { BrowserRouter } from "react-router-dom"
import { Auth0ProviderWithRedirect } from "./providers/Auth0ProviderWithRedirect"
import { setupQueryClient } from "./config/queryConfig"
import * as Sentry from "@sentry/react"
import { Toaster } from "react-hot-toast"

// Global stylesheets
import "tippy.js/dist/tippy.css"
import { ErrorPage } from "@pages/ErrorPage"
import { PostHogConsentHandler } from "./posthog/PostHogConsentHandler"
import { PostHogContextProvider } from "./posthog/PostHogContextProvider"

// Setup of React Query
const queryClient = setupQueryClient()

export const App: React.FC = () => {
    return (
        <React.StrictMode>
            <React.Suspense>
                {/* Context that provides the PostHog instance to the rest of the application. ( config happens in the PostHogConsentHandler ) */}
                <PostHogContextProvider>
                    <BrowserRouter>
                        <Sentry.ErrorBoundary
                            fallback={({ error }) => {
                                return <ErrorPage error={error} />
                            }}
                        >
                            <Auth0ProviderWithRedirect>
                                <QueryClientProvider client={queryClient}>
                                    {/* Checks the posthog cookies and sets posthog up accordingly. Also checks if the consent banner needs to be shown. */}
                                    <PostHogConsentHandler />

                                    <Toaster position="top-right" reverseOrder={true} />

                                    {/* Router component which contains all the routes in the application */}
                                    <Router />

                                    <ReactQueryDevtools initialIsOpen={false} />
                                </QueryClientProvider>
                            </Auth0ProviderWithRedirect>
                        </Sentry.ErrorBoundary>
                    </BrowserRouter>
                </PostHogContextProvider>
            </React.Suspense>
        </React.StrictMode>
    )
}

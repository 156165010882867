import classNames from "classnames"
import React, { PropsWithChildren, forwardRef } from "react"

interface Props {
    isActive?: boolean
}

export const TabButton: React.FC<PropsWithChildren<Props>> = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
    ({ children, isActive = false }, ref) => {
        return (
            <div
                ref={ref}
                className={classNames(
                    { "border-b-2 border- border-brand-eye-blue font-bold": isActive },
                    { "bg-white text-brand-eye-blue": !isActive },
                    "px-2 py-1",
                )}
            >
                {children}
            </div>
        )
    },
)

TabButton.displayName = "TabButton"

import React, { useState } from "react"
import { DateRange, DayPicker } from "react-day-picker"
import Tippy from "@tippyjs/react"
import { ArrowRightIcon, CalendarDaysIcon } from "@heroicons/react/24/solid"
import { DateInputMask } from "./DateInputMask"
import {
    DATE_FORMATS,
    formatDate,
    getEndofDayTimestampFromDate,
    getJSDateFromTimestamp,
    getStartofDayTimestampFromDate,
} from "@utils/dateUtils"
import { DateRangeOptions } from "./DateRangeOptions"
import { DateRangePickerHeader } from "./DateRangePickerHeader"
import { IDateRange, DefaultRange, getRangePickerStart, getValidDateRange } from "@utils/dateRangeUtils"

interface Props {
    range: IDateRange | undefined
    onChange: (range: IDateRange | undefined) => void
    placeholder?: string
    footer?: React.ReactNode
    initialRangeOption?: DefaultRange
    display?: {
        rangeOptions: boolean
        dateInput: boolean
    }
    customRangeDisplay?: React.ReactNode
}

const getDisplayValue = (range: Props["range"]): string => {
    const fromValue = range?.from ? formatDate(range.from, DATE_FORMATS.DATE_FORMAT) : undefined
    const toValue = range?.to ? formatDate(range.to, DATE_FORMATS.DATE_FORMAT) : undefined

    // Both values are present
    if (fromValue && toValue) {
        return `${fromValue} - ${toValue}`
    }

    // If either of the values are present, only show that value
    if (fromValue && !toValue) {
        return fromValue
    }

    // Only the to value is present
    if (toValue && !fromValue) {
        return `? - ${toValue}`
    }

    // Neither values are present, show the placeholder
    return ""
}

export const DateRangePicker: React.FC<Props> = ({
    range,
    onChange,
    placeholder,
    footer,
    initialRangeOption,
    display = { dateInput: true, rangeOptions: true },
    customRangeDisplay,
}) => {
    const [visible, setVisible] = useState(false)
    const show = (): void => setVisible(true)
    const hide = (): void => setVisible(false)

    const [rangeOption, setRangeOption] = useState<DefaultRange>(initialRangeOption ?? "custom")
    const [visibleMonth, setVisibleMonth] = useState<Date>(getRangePickerStart(range))

    const onChangeRange = (range: IDateRange | undefined) => {
        setRangeOption("custom")
        onChange(range)
    }

    const validRange = getValidDateRange({ from: range?.from, to: range?.to })

    // A dateRange in iso timestamp string format is supplied, but the DayPicker component
    // requires a JS Date object, so we cast the timestamp to a js Date to use in the component
    const dayPickerRange = validRange
        ? {
              from: validRange.from ? getJSDateFromTimestamp(validRange.from) : undefined,
              to: validRange.to ? getJSDateFromTimestamp(validRange.to) : undefined,
          }
        : undefined

    // On range selection we do the opposite of the above.
    // We take the Range in Date format and cast it back to a ISO timestamp string to be used outside of the RangePicker
    const onSelectRange = (
        selectedRange: DateRange | undefined,
        selectedDay: Date,
        currentRange: IDateRange | undefined,
    ): void => {
        // dayPickerRange is the entire range that was selected
        // the selectedDay was the last date the user clicked on

        // If a full range is currently set and defined, we want to start a new range from the selected day.
        if (currentRange?.from && currentRange.to && selectedDay) {
            onChangeRange({
                from: selectedDay ? getStartofDayTimestampFromDate(selectedDay) : undefined,
                to: undefined,
            })

            return
        }

        // If a full range is not currently set, we can create a range from the range passed by the day picker component itself.
        const timestampRange = selectedRange
            ? {
                  from: selectedRange.from ? getStartofDayTimestampFromDate(selectedRange.from) : undefined,
                  to: selectedRange.to ? getEndofDayTimestampFromDate(selectedRange.to) : undefined,
              }
            : undefined

        onChangeRange(timestampRange)
    }

    return (
        <div className="flex border rounded-lg items center flex-wrap flex-row bg-white" data-testid="daterange-picker">
            {display.rangeOptions && (
                <>
                    <DateRangeOptions
                        onChange={(range, option) => {
                            onChange(range)
                            setRangeOption(option)
                            // When selecting an option from the menu, the date range pickers selected month should also change
                            // This way the user will see their selection on opening the calendar.
                            if (range) {
                                setVisibleMonth(getRangePickerStart(range))
                            }
                        }}
                        rangeOption={rangeOption}
                    />
                    <div className="border-r"></div>
                </>
            )}

            <Tippy
                theme="picker"
                visible={visible}
                onClickOutside={hide}
                interactive
                arrow={false}
                placement="bottom-end"
                className="rangepicker-tippy"
                content={
                    <div>
                        <div className="bg-neutral-100 flex items-center space-x-2 p-3">
                            <DateInputMask
                                withFocus={visible} // WithFocus should be triggered when the picker is opened.
                                currentTimestamp={range?.from}
                                onChange={(timestamp) => {
                                    // Build the new range
                                    const newRange = { from: timestamp, to: range?.to }
                                    onChangeRange(newRange)
                                    // Programatically changes the visible month in the range picker
                                    setVisibleMonth(getRangePickerStart(newRange))
                                }}
                            />
                            <ArrowRightIcon height={20} width={20} className="text-neutral-100" />
                            <DateInputMask
                                currentTimestamp={range?.to}
                                onChange={(timestamp) => {
                                    const newRange = { from: range?.from, to: timestamp }
                                    onChangeRange(newRange)
                                    setVisibleMonth(getRangePickerStart(newRange))
                                }}
                            />
                        </div>

                        <div className="p-4 flex justify-center">
                            <DayPicker
                                showOutsideDays
                                mode="range"
                                ISOWeek
                                defaultMonth={getRangePickerStart(range)}
                                selected={dayPickerRange}
                                footer={footer}
                                onSelect={(dayPickerRange, selectedDay) => {
                                    // dayPickerRange is the entire range that was selected
                                    // the selectedDay was the last date the user clicked on
                                    onSelectRange(dayPickerRange, selectedDay, range)
                                }}
                                // Month handles which month is currently visible
                                month={visibleMonth}
                                // The onMonthChange triggers when the user uses the navigation buttons in the daypicker.
                                // This should override the current visible month settings
                                onMonthChange={(month) => setVisibleMonth(month)}
                                // Limits the picker to today
                                toDate={new Date()}
                                // @ts-ignore
                                components={{ Caption: DateRangePickerHeader }}
                            />
                        </div>
                    </div>
                }
            >
                <div>
                    {customRangeDisplay && <div onClick={visible ? hide : show}>{customRangeDisplay}</div>}

                    {display.dateInput && (
                        <div
                            className="border md:border-0 rounded-md md:rounded-tl-none md:rounded-bl-none md:border-l mt-2 md:mt-0 flex items-center flex-1 bg-white cursor-pointer"
                            onClick={visible ? hide : show}
                        >
                            <div className="flex items-center justify-center ml-2 mr-2">
                                <CalendarDaysIcon height={20} width={20} className="text-text-secondary-light" />
                            </div>

                            <input
                                name="date-range"
                                onClick={visible ? hide : show}
                                type="text"
                                readOnly
                                value={getDisplayValue(range)}
                                placeholder={placeholder}
                                className={`block border-none py-1 placeholder:text-gray-400 sm:leading-6 outline-none focus:ring-0 cursor-pointer min-w-[200px]`}
                            />
                        </div>
                    )}
                </div>
            </Tippy>
        </div>
    )
}

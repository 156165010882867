import { PieChartSkeleton } from "@designSystem/feedback/skeletons/PieChartSkeleton"
import React from "react"
import { useTranslation } from "react-i18next"
import { DetectionsCategoryChart } from "./DetectionsCategoryChart"
import { IDetectionV2Statistics } from "@features/detectionsv2/types"
import { getResolutionStatusCategoriesCount } from "@utils/detectionUtils"
import { Typography } from "@eyectrl/baseplate-design-system"

export const DetectionsOverviewWidget: React.FC<{
    isLoading?: boolean
    detectionsLast30Days: number
    detectionsByResolutionStatus?: IDetectionV2Statistics["count_detections_by_resolution_status_last_30_days"]
}> = ({ isLoading, detectionsLast30Days, detectionsByResolutionStatus }) => {
    const { t } = useTranslation()

    // Maps the BE data back to normal/abnormal/malicous category counts
    const resolutionStatusCategories = getResolutionStatusCategoriesCount(detectionsByResolutionStatus)

    if (isLoading) {
        return (
            <div className="card h-[250px] p-4">
                <PieChartSkeleton />
            </div>
        )
    }

    return (
        <div className="card h-[275px] p-4 w-full lg:w-auto">
            <div>
                <Typography variant="body-1-semibold">{t("detections:overviewWidget.title")}</Typography>
                <Typography variant="body-3" color="text-secondary">
                    {t("detections:overviewWidget.last30Days")}
                </Typography>
            </div>
            <DetectionsCategoryChart
                detectionsLast30Days={detectionsLast30Days}
                resolutionStatusCategories={resolutionStatusCategories}
            />
        </div>
    )
}

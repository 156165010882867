import { useLocation } from "react-router-dom"
import { Breadcrumb, getBreadcrumbs } from "@utils/breadcrumbUtils"
import { useActiveEyed } from "./useActiveEyed"

export const useBreadcrumbs = (): Breadcrumb[] => {
    // Get the location from react-router
    const location = useLocation()
    // Get the active eyed, which get's added at the end of each url
    const activeEyed = useActiveEyed()

    // Then we combine everything and build breadcrumbs.
    return getBreadcrumbs(location, activeEyed)
}

import { Skeleton } from "./Skeleton"

export const SteppedFlowSkeleton: React.FC = () => {
    return (
        <div className="w-full p-12">
            <div className="w-full flex gap-12">
                <Skeleton className="w-[200px] h-[25px]" />
                <Skeleton className="w-[200px] h-[25px]" />
                <Skeleton className="w-[200px] h-[25px]" />
                <Skeleton className="w-[200px] h-[25px]" />
            </div>

            <div className="mt-16 flex flex-col gap-4">
                <Skeleton className="w-[40%] h-[25px]" />
                <Skeleton className="w-[70%] h-[50px]" />
                <Skeleton className="w-[70%] h-[50px]" />
            </div>
        </div>
    )
}

import { Env } from "./envConfig"

const AUTH0_PRD_CONFIG = {
    domain: "login.eye.security",
    audience: "eye-portal-prd",
    clientId: "yGEPco3PfR2xBjUoAOmzuWMVBNjep8DG",
}

const AUTH0_TST_CONFIG = {
    domain: "login.eye.security",
    audience: "eye-portal-tst",
    clientId: "okU9VSRMlnFPlpqP95ZUxb6OzsibimVi",
}

const AUTH0_DEV_CONFIG = {
    domain: "login.eye.security",
    audience: "eye-portal-dev",
    clientId: "okU9VSRMlnFPlpqP95ZUxb6OzsibimVi",
}

export const getAuth0Config = (environment: Env) => {
    switch (environment) {
        case Env.production:
            return AUTH0_PRD_CONFIG
        case Env.test:
            return AUTH0_TST_CONFIG
        case Env.development:
            return AUTH0_DEV_CONFIG
        default:
            throw new Error("Failed to retrieve Auth0 configuration")
    }
}

import { createColumnHelper } from "@tanstack/react-table"
import { IDetection } from "./detectionsTypes"
import { Typography } from "@eyectrl/baseplate-design-system"
import i18n from "@/i18next"
import { ConclusionBadge } from "./components/ConclusionBadge"
import { HoverableDate } from "@components/dates/HoverableDate"
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline"
import Tippy from "@tippyjs/react"
import { DATE_FORMATS } from "@utils/dateUtils"
import copy from "copy-to-clipboard"
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline"

export const getDetectionColumns = () => {
    const columnHelper = createColumnHelper<IDetection>()

    return [
        columnHelper.accessor("occurredAt", {
            size: 100,
            header: i18n.t("pages:detections.table.date"),
            cell: (info) => <HoverableDate timestamp={info.getValue()} format={DATE_FORMATS.DATE_FORMAT} />,
            enableHiding: true,
        }),
        columnHelper.accessor("category", {
            size: 225,
            header: i18n.t("pages:detections.table.category"),
            cell: (info) => (
                <Tippy content={info.getValue()} placement="top-start" arrow={false}>
                    <Typography shouldPreventWrapping textElipsis>
                        {info.getValue()}
                    </Typography>
                </Tippy>
            ),
        }),
        columnHelper.accessor("resolutionStatus", {
            size: 150,
            header: i18n.t("pages:detections.table.resolutionStatus"),
            cell: (info) => <ConclusionBadge value={info.getValue()} />,
        }),
        columnHelper.accessor("assetIdentifier", {
            size: 225,
            header: i18n.t("pages:detections.table.assetIdentifier"),
            cell: (info) => (
                <Typography shouldPreventWrapping textElipsis>
                    {info.getValue()}
                </Typography>
            ),
            enableHiding: true,
        }),
        columnHelper.accessor("clientContacted", {
            size: 100,
            header: i18n.t("pages:detections.table.clientContacted"),
            cell: (info) => {
                if (info.getValue()) {
                    return (
                        <div className="text-text-success-light">
                            <Tippy content={i18n.t("pages:detections.table.clientContactNeeded")} placement={"top"}>
                                <CheckCircleIcon width={24} />
                            </Tippy>
                        </div>
                    )
                } else {
                    return (
                        <div className="text-text-secondary-light">
                            <Tippy content={i18n.t("pages:detections.table.clientContactNotNeeded")} placement={"top"}>
                                <XCircleIcon width={24} />
                            </Tippy>
                        </div>
                    )
                }
            },
            enableHiding: true,
        }),
        columnHelper.accessor("source", {
            size: 150,
            header: i18n.t("pages:detections.table.source"),
            cell: (info) => <Typography>{info.getValue()}</Typography>,
            enableHiding: true,
        }),
        columnHelper.display({
            id: "actions",
            size: 100,
            cell: (info) => (
                <div>
                    <Tippy content={i18n.t("pages:detections.table.copyReference")}>
                        <div
                            className="cursor-pointer text-text-primary-light hover:text-brand-eye-blue flex justify-center"
                            onClick={() => {
                                copy(info.row.original.id)
                            }}
                        >
                            <DocumentDuplicateIcon width={18} height={18} />
                        </div>
                    </Tippy>
                </div>
            ),
            enableHiding: true,
        }),
    ]
}

import { FunctionComponent } from "react"

type styleType = {
    parent: string
    child: string
}
interface styleProps {
    customStyles: styleType
}

export const EyeHeaderBackground: FunctionComponent<styleProps> = ({ customStyles }): JSX.Element => {
    return (
        <div
            className={
                customStyles.parent != "" ? customStyles.parent : "absolute inset-0 overflow-hidden origin-center"
            }
            style={{ transform: "scale(-1)" }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 1196 760"
                className={
                    customStyles.parent != ""
                        ? customStyles.child
                        : "absolute -left-12 xs:-left-16 md:-left-20 lg:-left-8 h-52 xs:h-64 md:h-80 lg:h-96-top-12 xs:-top-16 md:-top-20 lg:-top-8 text-eye-orange text-opacity-10"
                }
            >
                <rect width="345" height="116" rx="58" transform="translate(128)"></rect>
                <rect width="228" height="116" rx="58" transform="translate(485)"></rect>
                <rect width="116" height="116" rx="58" transform="translate(1)"></rect>
                <rect width="229" height="116" rx="58" transform="translate(1 128)"></rect>
                <rect width="116" height="116" rx="58" transform="translate(243 128)"></rect>
                <rect width="344" height="116" rx="58" transform="translate(369 128)"></rect>
                <rect width="227" height="116" rx="58" transform="translate(727 128)"></rect>
                <rect width="114" height="116" rx="58" transform="translate(969 128)"></rect>
                <rect width="115" height="116" rx="58" transform="translate(727 257)"></rect>
                <rect width="116" height="116" rx="58" transform="translate(597 257)"></rect>
                <rect width="227" height="116" rx="58" transform="translate(357 257)"></rect>
                <rect width="346" height="116" rx="58" transform="translate(1 257)"></rect>
                <rect width="116" height="116" rx="58" transform="translate(597 386)"></rect>
                <rect width="346" height="116" rx="58" transform="translate(243 386)"></rect>
                <rect width="229" height="116" rx="58" transform="translate(1 386)"></rect>
                <rect width="227" height="116" rx="58" transform="translate(852 517)"></rect>
                <rect width="113" height="116" rx="58" transform="translate(726 517)"></rect>
                <rect width="116" height="116" rx="58" transform="translate(0 516)"></rect>
                <rect width="114" height="116" rx="58" transform="translate(359 646)"></rect>
                <rect width="115" height="116" rx="58" transform="translate(485 646)"></rect>
                <rect width="344" height="116" rx="58" transform="translate(369 516)"></rect>
                <rect width="344" height="116" rx="58" transform="translate(852 646)"></rect>
                <rect width="346" height="116" rx="58" transform="translate(1 646)"></rect>
                <rect width="229" height="116" rx="58" transform="translate(128 516)"></rect>
                <rect width="227" height="116" rx="58" transform="translate(612 646)"></rect>
            </svg>
        </div>
    )
}

export const EyePageBackground: FunctionComponent<styleProps> = ({ customStyles }): JSX.Element => {
    return (
        <div
            className={
                customStyles.parent != "" ? customStyles.parent : "absolute inset-0 overflow-hidden origin-center"
            }
        >
            <svg width="444" height="188" viewBox="0 0 444 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M341.915 187.613L443.102 187.613L443.102 128.55L341.915 128.55C327.946 128.55 316.622 141.772 316.622 158.082C316.622 174.391 327.946 187.613 341.915 187.613Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M405.293 59.0635L442.5 59.0635L442.5 4.57764e-05L405.293 4.56434e-05C391.324 4.55935e-05 380 13.2218 380 29.5318C380 45.8417 391.324 59.0635 405.293 59.0635Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M383.293 123.063L442.5 123.063L442.5 64L383.293 64C369.324 64 358 77.2218 358 93.5318C358 109.842 369.324 123.063 383.293 123.063Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M239.801 123.338L325.258 123.338C339.374 123.338 350.817 110.117 350.817 93.8067C350.817 77.4967 339.374 64.2749 325.258 64.2749L239.801 64.2749C225.685 64.2749 214.242 77.4967 214.242 93.8067C214.242 110.117 225.685 123.338 239.801 123.338Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M233.542 187.613L284.673 187.613C298.788 187.613 310.231 174.391 310.231 158.082C310.231 141.772 298.788 128.55 284.673 128.55L233.542 128.55C219.426 128.55 207.984 141.772 207.984 158.082C207.984 174.391 219.426 187.613 233.542 187.613Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M133.359 123.338L184.09 123.338C198.095 123.338 209.449 110.117 209.449 93.8067C209.449 77.4967 198.095 64.2749 184.09 64.2749L133.359 64.2749C119.353 64.2749 108 77.4967 108 93.8067C108 110.117 119.353 123.338 133.359 123.338Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M239.243 3.80536e-05C224.905 3.8002e-05 213.282 13.2218 213.282 29.5318C213.282 45.8417 224.905 59.0635 239.243 59.0635C253.581 59.0635 265.204 45.8417 265.204 29.5318C265.204 13.2218 253.581 3.81052e-05 239.243 3.80536e-05Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M182.528 3.80536e-05C168.19 3.8002e-05 156.566 13.2218 156.566 29.5318C156.566 45.8417 168.19 59.0635 182.528 59.0635C196.866 59.0635 208.489 45.8417 208.489 29.5318C208.489 13.2218 196.866 3.81052e-05 182.528 3.80536e-05Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M126.48 0.868653L25.2933 0.868652C11.3242 0.868652 3.81825e-08 13.896 8.5283e-08 29.9661C1.32383e-07 46.0361 11.3242 59.0635 25.2933 59.0635L126.48 59.0635C140.449 59.0635 151.774 46.0361 151.774 29.9661C151.774 13.896 140.449 0.868653 126.48 0.868653Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M347.485 0.868653L296.354 0.868652C282.239 0.868652 270.796 13.896 270.796 29.9661C270.796 46.0361 282.239 59.0635 296.354 59.0635L347.485 59.0635C361.601 59.0635 373.043 46.0361 373.043 29.9661C373.043 13.896 361.601 0.868653 347.485 0.868653Z"
                    fill="#F4F5F7"
                />
            </svg>
        </div>
    )
}

export const EyeElementBackground: FunctionComponent<{ className: string }> = ({ className }): JSX.Element => {
    return (
        <div className={className}>
            <svg width="119" height="50" viewBox="0 0 119 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M91.1223 50L118.089 50L118.089 34.2593L91.1223 34.2593C87.3995 34.2593 84.3815 37.7829 84.3815 42.1296C84.3815 46.4763 87.3995 50 91.1223 50Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M108.013 15.7407L117.929 15.7407L117.929 -1.43051e-05L108.013 -1.43405e-05C104.29 -1.43538e-05 101.272 3.52367 101.272 7.87035C101.272 12.217 104.29 15.7407 108.013 15.7407Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M102.15 32.7971L117.929 32.7971L117.929 17.0564L102.15 17.0564C98.4269 17.0564 95.409 20.5801 95.409 24.9268C95.409 29.2734 98.4269 32.7971 102.15 32.7971Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M63.9083 32.8704L86.683 32.8704C90.445 32.8704 93.4948 29.3467 93.4948 25C93.4948 20.6533 90.445 17.1296 86.683 17.1296L63.9083 17.1296C60.1462 17.1296 57.0966 20.6533 57.0966 25C57.0966 29.3467 60.1462 32.8704 63.9083 32.8704Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M62.2403 50L75.8669 50C79.6288 50 82.6783 46.4763 82.6783 42.1296C82.6783 37.7829 79.6288 34.2593 75.8669 34.2593L62.2403 34.2593C58.4784 34.2593 55.4288 37.7829 55.4288 42.1296C55.4288 46.4763 58.4784 50 62.2403 50Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M35.5408 32.8704L49.061 32.8704C52.7934 32.8704 55.8192 29.3467 55.8192 25C55.8192 20.6533 52.7934 17.1296 49.061 17.1296L35.5408 17.1296C31.8083 17.1296 28.7826 20.6533 28.7826 25C28.7826 29.3467 31.8083 32.8704 35.5408 32.8704Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M63.7596 -1.52837e-05C59.9385 -1.52974e-05 56.8408 3.52367 56.8408 7.87036C56.8408 12.217 59.9385 15.7407 63.7596 15.7407C67.5808 15.7407 70.6785 12.217 70.6785 7.87036C70.6785 3.52367 67.5808 -1.52699e-05 63.7596 -1.52837e-05Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M48.6446 -1.52837e-05C44.8235 -1.52974e-05 41.7258 3.52367 41.7258 7.87036C41.7258 12.217 44.8235 15.7407 48.6446 15.7407C52.4658 15.7407 55.5635 12.217 55.5635 7.87036C55.5635 3.52367 52.4658 -1.52699e-05 48.6446 -1.52837e-05Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M33.7077 0.231445L6.74082 0.231445C3.01797 0.231445 1.01758e-08 3.70331 2.27284e-08 7.98607C3.52809e-08 12.2688 3.01797 15.7407 6.74082 15.7407L33.7077 15.7407C37.4306 15.7407 40.4485 12.2688 40.4485 7.98607C40.4485 3.70331 37.4306 0.231445 33.7077 0.231445Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M92.6067 0.231445L78.98 0.231445C75.2182 0.231445 72.1686 3.70331 72.1686 7.98607C72.1686 12.2688 75.2182 15.7407 78.98 15.7407L92.6067 15.7407C96.3685 15.7407 99.4181 12.2688 99.4181 7.98607C99.4181 3.70331 96.3685 0.231445 92.6067 0.231445Z"
                    fill="#F4F5F7"
                />
            </svg>
        </div>
    )
}

import { useApplicationStore } from "@/stores/applicationStore"
import { useAuth0 } from "@auth0/auth0-react"
import { EyeOrganisation } from "@features/accountSwitcher/hooks/useUserOrganisations"
import { organisationUtils } from "@utils/organisationUtils"
import React, { PropsWithChildren, useCallback, useEffect } from "react"
import { POSTHOG_CAPTURE_TYPES } from "./postHogConfig"
import { useCapturePostHogEvent } from "./useCapturePostHogEvent"
import { usePostHog } from "posthog-js/react"
import { ORGANISATION_GROUP_TYPE } from "./postHogConfig"

interface Props {
    activeOrganisation: EyeOrganisation
    userOrganisations: EyeOrganisation[]
}

export const PostHogPropertySync: React.FC<PropsWithChildren<Props>> = ({
    children,
    activeOrganisation,
    userOrganisations,
}) => {
    const { user } = useAuth0()
    const posthog = usePostHog()
    const { captureEvent } = useCapturePostHogEvent()
    const isPostHogUserIdentified = useApplicationStore((state) => state.isUserIdentified)
    const arePostHogUserPropertiesSynced = useApplicationStore((state) => state.arePostHogUserPropertiesSynced)
    const setArePostHogUserPropertiesSynced = useApplicationStore((state) => state.setArePostHogUserPropertiesSynced)

    // Groups all events by the organisation that's currently active
    // Switches organisation if someone changes the active org in the account switcher.
    const setPostHogGroup = useCallback(
        (organisation: EyeOrganisation) => {
            posthog.group(ORGANISATION_GROUP_TYPE, organisation.eyed, {
                name: organisation.name,
            })
        },
        [posthog],
    )

    // Listens to changes of the active organisation, then triggers the setting of a posthog group.
    useEffect(() => {
        setPostHogGroup(activeOrganisation)
    }, [activeOrganisation, setPostHogGroup])

    useEffect(() => {
        if (!user || !isPostHogUserIdentified) {
            return
        }

        if (arePostHogUserPropertiesSynced === true) {
            console.log("[PORTAL] User Properties are synced already, skipping the sync.")
            return
        }

        // If the user has only 1 user organisation, we can safely sink all the organisations properties to the user information
        if (userOrganisations.length === 1) {
            console.log("[PORTAL] Setting user properties ( individual organisation )")
            captureEvent(POSTHOG_CAPTURE_TYPES.SET_PROPERTIES_EVENT, {
                $set: {
                    email: user.email,
                    has_multiple_organisations: false,
                    // Organisation properties ( posthog can't handle nested properties )
                    organisation_name: activeOrganisation.name,
                    organisation_insured: activeOrganisation.insured,
                    organisation_onboarded: activeOrganisation.onboarded,
                    organisation_plan: organisationUtils.getOrganisationPlan(activeOrganisation),
                    organisation_awareness_in_contract: activeOrganisation.awareness_in_contract,
                },
            })
        } else {
            // If the user has multiple organisations we sync only the has_multiple_organisations prop.
            console.log("[PORTAL] Setting user properties ( multiple organisations )")
            captureEvent(POSTHOG_CAPTURE_TYPES.SET_PROPERTIES_EVENT, {
                $set: {
                    email: user.email,
                    has_multiple_organisations: true,
                },
            })
        }

        setArePostHogUserPropertiesSynced(true)
    }, [
        activeOrganisation,
        user,
        isPostHogUserIdentified,
        userOrganisations,
        arePostHogUserPropertiesSynced,
        setArePostHogUserPropertiesSynced,
    ])

    return children
}

import { UserContext } from "@/providers/UserContext"
import { NotFound } from "@pages/NotFound"
import React, { PropsWithChildren, useContext } from "react"

export const AdminRoute: React.FC<PropsWithChildren> = ({ children }) => {
    const { isPortalAdmin } = useContext(UserContext)

    if (!isPortalAdmin) {
        return <NotFound />
    }

    return children
}

import React, { Fragment } from "react"
import { useDashboardMetrics } from "../hooks/useDashboardMetrics"
import { IDashboardMetrics } from "../dashboardTypes"
import { isNullish } from "@utils/formatUtils"
import { EndpointCoverageWidget } from "./widgets/EndpointCoverageWidget"
import { useNavigate } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { DiskEncryptionWidget } from "./widgets/DiskEncryptionWidget"
import { MfaWidget } from "./widgets/MfaWidget"
import { IntroductionHotspot } from "@designSystem/hotspot/IntroductionHotspot"
import { useTranslation } from "react-i18next"
import { HotspotConfig } from "@config/hotspotConfig"

interface Props {
    activeEyed: string
}

export const DashboardMetrics: React.FC<Props> = ({ activeEyed }) => {
    const { t } = useTranslation()
    const { isSuccess, isPending, isError, data } = useDashboardMetrics(activeEyed)
    const metricsData: IDashboardMetrics | undefined = data?.data ?? undefined
    const routerLinks = getRouterLinks()
    const navigate = useNavigate()
    const showSkeletons = isPending === true || isSuccess === false

    const renderIntroductionHotspot = () => {
        return (
            <IntroductionHotspot
                buttonText={t("introductionHotspots:metricsHotspot.button")}
                description={t("introductionHotspots:metricsHotspot.description")}
                title={t("introductionHotspots:metricsHotspot.title")}
                hotspotId={HotspotConfig.METRICS_HOTSPOT}
            />
        )
    }

    // Create mapping if below widgets should render based on which metric blocks are visible.
    // The actual hotspots themselves determine if they should be rendered based on FF and local storage.
    const shouldRenderHotspot = {
        endpoint_coverage: !isNullish(metricsData?.endpoint_coverage),
        disk_encryption: !isNullish(metricsData?.disk_encryption) && isNullish(metricsData?.endpoint_coverage),
        mfa:
            !isNullish(metricsData?.mfa) &&
            isNullish(metricsData?.disk_encryption) &&
            isNullish(metricsData?.endpoint_coverage),
    }

    const getRecommendationLink = (
        recommendationId: string | undefined | null,
        linkText: string,
    ): { value: string; onNavigate?: () => void } => {
        if (isNullish(recommendationId)) {
            return {
                value: linkText,
            }
        }

        return {
            value: linkText,
            onNavigate: () => {
                navigate(
                    routerLinks.recommendations.detail({
                        id: recommendationId,
                        eyed: activeEyed,
                    }),
                )
            },
        }
    }

    return (
        <Fragment>
            <EndpointCoverageWidget
                metric={metricsData?.endpoint_coverage}
                isError={isError}
                getRecommendationLink={getRecommendationLink}
                showSkeleton={showSkeletons}
                renderIntroductionHotspot={
                    shouldRenderHotspot.endpoint_coverage ? renderIntroductionHotspot : undefined
                }
            />

            <DiskEncryptionWidget
                metric={metricsData?.disk_encryption}
                isError={isError}
                getRecommendationLink={getRecommendationLink}
                showSkeleton={showSkeletons}
                renderIntroductionHotspot={shouldRenderHotspot.disk_encryption ? renderIntroductionHotspot : undefined}
            />

            <MfaWidget
                metric={metricsData?.mfa}
                isError={isError}
                getRecommendationLink={getRecommendationLink}
                showSkeleton={showSkeletons}
                renderIntroductionHotspot={shouldRenderHotspot.mfa ? renderIntroductionHotspot : undefined}
            />
        </Fragment>
    )
}

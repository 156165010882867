import { getAuth0Config } from "@/config/auth0Config"
import { getEnv } from "@/config/envConfig"
import { AppState, Auth0Provider } from "@auth0/auth0-react"
import React, { PropsWithChildren } from "react"
import { useNavigate } from "react-router-dom"

export const Auth0ProviderWithRedirect: React.FC<PropsWithChildren> = ({ children }) => {
    const navigate = useNavigate()
    const env = getEnv()
    const auth0Config = getAuth0Config(env)

    const onRedirectCallback = (appState?: AppState): void => {
        navigate(appState?.returnTo || window.location.pathname)
    }

    return (
        <Auth0Provider
            domain={auth0Config.domain}
            clientId={auth0Config.clientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: auth0Config.audience,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    )
}

import { isNullish } from "@utils/formatUtils"
import React from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

interface Props {
    children: string | undefined | null
    className?: string
}

export const MarkdownText: React.FC<Props> = ({ children, className = "" }) => {
    if (isNullish(children)) {
        return null
    }

    return (
        <div className={`prose max-w-[800px] ${className}`}>
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                    code({ inline, children, ...props }) {
                        if (inline) {
                            return (
                                <code
                                    className="bg-neutral-100 py-0.5 px-1 border rounded-md before:content-['']  after:content-[''] text-xxs font-light"
                                    {...props}
                                >
                                    {children}
                                </code>
                            )
                        }

                        return (
                            <pre
                                className="not-prose m-0 before:content-[''] after:content-[''] text-xxs font-light text-brand-eye-blue"
                                {...props}
                            >
                                {children}
                            </pre>
                        )
                    },
                    a({ children, ...props }) {
                        return (
                            <a rel="noopener noreferrer" target="_blank" {...props}>
                                {children}
                            </a>
                        )
                    },
                }}
            >
                {children}
            </ReactMarkdown>
        </div>
    )
}

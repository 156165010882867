import { Button, Dropdown, DropdownListItem, DropdownPanel } from "@eyectrl/baseplate-design-system"
import Modal from "@designSystem/overlays/modal/Modal"
import { ModalFooter } from "@designSystem/overlays/modal/ModalFooter"
import { Typography } from "@eyectrl/baseplate-design-system"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { useAwarenessLanguages } from "../hooks/useAwarenessLanguages"
import { IAwarenessLanguage } from "../awarenessTypes"
import { isNullish } from "@utils/formatUtils"
import { UserContext } from "@/providers/UserContext"
import { usePatchAwarenessState } from "../hooks/usePatchAwarenessState"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { toastService } from "@/services/toastService"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"

interface Props {
    isEditLanguageModalActive: boolean
    setIsEditLanguageModalActive: (active: boolean) => void
}

export const RecipientsLanguageSettingsModal: React.FC<Props> = ({
    isEditLanguageModalActive,
    setIsEditLanguageModalActive,
}) => {
    const { t } = useTranslation()
    const { data } = useAwarenessLanguages()
    const { activeOrganisation } = useContext(UserContext)
    const activeEyed = useActiveEyed()
    const currentDefaultLanguage = activeOrganisation?.awareness?.default_language
    const [selectedLanguage, setSelectedLanguage] = useState<IAwarenessLanguage>()
    const languages = data?.data ?? []
    const { captureEvent } = useCapturePostHogEvent()
    const { mutate } = usePatchAwarenessState(
        () => {
            // Deactivates the modal once the mutation has been completed successfully.
            setIsEditLanguageModalActive(false)
        },
        () => {
            toastService.errorMessage(t("awareness.recipientInfo.editLanguageModal.errorMessage"))
        },
    )

    const onUpdatedDefaultLanguage = (language: IAwarenessLanguage) => {
        mutate({ eyed: activeEyed, update: { default_language: language } })
        captureEvent(POSTHOG_CAPTURE_TYPES.AWARENESS.SWITCHED_DEFAULT_LANGUAGE, { language: language.Name })
    }

    // If the current default language ( coming from the orgsanisation ) changes, we can also update the dropdown.
    useEffect(() => {
        if (currentDefaultLanguage) {
            setSelectedLanguage(currentDefaultLanguage)
        }
    }, [currentDefaultLanguage])

    return (
        <Modal open={isEditLanguageModalActive} setOpen={setIsEditLanguageModalActive} withCloseButton>
            <div className="w-[460px] p-10 text-left">
                <div>
                    <Typography variant="header-4">{t("awareness.recipientInfo.editLanguageModal.title")}</Typography>
                    <Typography color="text-secondary" className="mt-4">
                        {t("awareness.recipientInfo.editLanguageModal.description")}
                    </Typography>

                    {!isNullish(currentDefaultLanguage) && !isNullish(currentDefaultLanguage.Name) && (
                        <Typography color="text-secondary">
                            {` ${t("awareness.recipientInfo.editLanguageModal.currentLanguage")} ${
                                currentDefaultLanguage.Name
                            }`}
                        </Typography>
                    )}
                </div>

                <div className="mt-4">
                    <Typography variant="body-1-semibold">
                        {t("awareness.recipientInfo.editLanguageModal.languageLabel")}
                    </Typography>
                    <Dropdown
                        button={
                            <div className="h-[36px] w-[400px] border rounded-md px-4 items-center flex gap-2 justify-between mt-2">
                                <div className="flex gap-2 items-center">
                                    {selectedLanguage
                                        ? selectedLanguage.Name
                                        : t("awareness.onboarding.steps.languageSelectionStep.chooseLanguage")}
                                </div>
                                <ChevronDownIcon height={16} width={16} className="text-brand-eye-blue" />
                            </div>
                        }
                    >
                        {(onClosePanel) => {
                            return (
                                <DropdownPanel className="max-h-[300px] overflow-x-auto">
                                    {languages.map((language) => {
                                        return (
                                            <DropdownListItem
                                                key={language.Name}
                                                onClick={() => {
                                                    setSelectedLanguage(language)
                                                    onClosePanel()
                                                }}
                                            >
                                                {language.Name}
                                            </DropdownListItem>
                                        )
                                    })}
                                </DropdownPanel>
                            )
                        }}
                    </Dropdown>
                </div>
            </div>

            <ModalFooter>
                <div className="flex justify-end items-center space-x-4">
                    <Button
                        variant="secondary"
                        size="small"
                        onClick={() => {
                            setIsEditLanguageModalActive(false)
                        }}
                    >
                        {t("common:cancel")}
                    </Button>
                    <Button
                        variant="primary"
                        size="small"
                        disabled={isNullish(selectedLanguage)}
                        onClick={() => {
                            if (selectedLanguage) {
                                onUpdatedDefaultLanguage(selectedLanguage)
                            }
                        }}
                    >
                        {t("common:save")}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

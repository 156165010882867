import { UserContext } from "@/providers/UserContext"
import { useContext } from "react"
import { useParams } from "react-router-dom"

export const useActiveEyed = (): string => {
    // The initial eyed is the first eyed that's present in the organisations endpoint
    // We can use this initial eyed as a fallback for the moments when an eyed was not supplied in the parameters of the url.
    const { initialEyed } = useContext(UserContext)
    const { eyed: paramsEyed } = useParams()

    return paramsEyed ? paramsEyed : initialEyed
}

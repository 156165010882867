/**
 * Cleans the URL that's being sent. Strips off the query params and the everything that comes before the version tag like the Eyed.
 * @param url
 * @returns
 */
export function cleanEndpointsForSentry(url: string): string {
    const withoutQueryParams = stripQueryParamsFromUrl(url)
    const splittedUrl = withoutQueryParams.split("/")
    const versionTagIndex = findVersionTagIndex(splittedUrl)

    const relevantSections = splittedUrl.slice(versionTagIndex)
    return relevantSections.join("/")
}

/**
 * Takes in a splitted url like ['section', 'section', 'v1', 'section', 'section'] and returns the index of the version tag.
 * @param splittedUrl
 * @returns
 */
export const findVersionTagIndex = (splittedUrl: string[]) => {
    const versionTagRegex = new RegExp("v[0-9]")

    const index = splittedUrl.findIndex((value) => versionTagRegex.test(value))

    if (index === -1) {
        return 0
    }

    return index
}

/**
 * Takes in an url and strips of the query parameters.
 * @param url
 * @returns
 */
export function stripQueryParamsFromUrl(url: string): string {
    const urlWithoutQueryParams = url.split("?")[0]
    return urlWithoutQueryParams
}

import { AdminStep } from "@features/awareness/components/AdminStep"
import React from "react"
import { DocumentTextIcon } from "@heroicons/react/24/outline"
import { Typography } from "@eyectrl/baseplate-design-system"
import { Button } from "@eyectrl/baseplate-design-system"
import { EyeOrganisation } from "@features/accountSwitcher/hooks/useUserOrganisations"
import { RecipientsUploadStep, UploadedCSV } from "@features/awareness/awarenessTypes"
import { FileUpload } from "@components/fileUpload/FileUpload"

interface Props {
    confirmedOrganistion?: EyeOrganisation
    uploadedCSV?: UploadedCSV
    clearRecipientsStep: (step: RecipientsUploadStep) => void
    onUploadCSV: (file: File) => void
}

export const UploadCSVStep: React.FC<Props> = ({
    confirmedOrganistion,
    uploadedCSV,
    clearRecipientsStep,
    onUploadCSV,
}) => {
    if (confirmedOrganistion === undefined) {
        return (
            <AdminStep subtitle="Step 2" title="Upload a CSV File">
                <Typography color="text-secondary">Please select an organistion first</Typography>
            </AdminStep>
        )
    }

    if (uploadedCSV) {
        return (
            <AdminStep subtitle="Step 2" title="Upload a CSV File">
                <div>
                    <div className="border p-4 rounded-md flex items-center justify-between shadow-sm">
                        <div className="flex items-center gap-2">
                            <DocumentTextIcon width={24} className="text-brand-eye-orange" />
                            <Typography variant="body-1-semibold">{uploadedCSV.fileName}</Typography>
                        </div>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                clearRecipientsStep("UploadStep")
                            }}
                        >
                            Clear Selection
                        </Button>
                    </div>
                </div>
            </AdminStep>
        )
    }

    return (
        <AdminStep subtitle="Step 2" title="Upload a CSV File">
            <div>
                <Typography color="text-secondary">
                    Upload a CSV which contains columns for first name, last name and email adresses.
                </Typography>

                <FileUpload
                    acceptedFileExtensions={["text/csv"]}
                    onFileUpload={onUploadCSV}
                    helperText="Only CSV files allowed"
                />
            </div>
        </AdminStep>
    )
}

import { toastService } from "@/services/toastService"
import { Mutation, MutationCache, Query, QueryCache, QueryClient, QueryKey } from "@tanstack/react-query"

// The amount of retries react-query will do before going into error state
const RETRY_COUNT = 3

export const setupQueryClient = (): QueryClient => {
    // Setup of React Query
    const queryClient = new QueryClient({
        // Setup of custom query/mutation handling functions
        defaultOptions: {
            // For certain request errors we will use the error boundary to show a global error screen.
            queries: {
                refetchOnWindowFocus: false,
                staleTime: 10 * (60 * 4000),
                retry(failureCount) {
                    // The function will keep retrying untill the failurecount exceeds the RETRY_COUNT
                    if (failureCount < RETRY_COUNT) {
                        return true
                    }

                    return false
                },
            },
        },
        queryCache: new QueryCache({
            onError: handleQueryError,
        }),
        mutationCache: new MutationCache({
            onError: handleMutationError,
        }),
    })

    return queryClient
}

// Custom query error handler. Looks at a possible error message in the meta object.
// If so, sends a toast message with the meta info.
export const handleQueryError = (_error: unknown, query: Query<unknown, unknown, unknown, QueryKey>) => {
    const errorMessage = query.meta?.errorMessage as string | undefined
    if (errorMessage) {
        toastService.errorMessage(errorMessage)
    }
}

// Custom mutation error handler. Looks at a possible error message in the meta object.
// If so, sends a toast message with the meta info.
export const handleMutationError = (
    _error: unknown,
    _variables: unknown,
    _context: unknown,
    mutation: Mutation<unknown, unknown, unknown, unknown>,
) => {
    const errorMessage = mutation.meta?.errorMessage as string | undefined

    if (errorMessage) {
        toastService.errorMessage(errorMessage)
    }
}

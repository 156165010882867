import { Popover, Transition, PopoverPanel, PopoverButton } from "@headlessui/react"
import React, { Fragment } from "react"

interface Props {
    button?: JSX.Element
    renderButton?: (active: boolean) => JSX.Element
    buttonClass?: string
    children: (closePanel: () => void) => React.ReactNode
}

const NavigationDropoutMenu: React.FC<Props> = ({ button, renderButton, children }) => {
    return (
        <Popover as="div" className="relative z-20">
            {({ open }) => (
                <>
                    <PopoverButton as="div" className={`w-full`}>
                        {button ? button : renderButton ? renderButton(open) : ""}
                    </PopoverButton>
                    <Transition
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <PopoverPanel
                            className={`absolute left-0 sm:left-[100%] sm:ml-7 bottom-14 sm:bottom-0 z-20 rounded-md bg-blue-600 sm:bg-brand-eye-blue focus:outline-none w-[315px]`}
                        >
                            {({ close }) => (
                                <Fragment>
                                    <div className="arrow-left absolute bottom-3 -left-1 z-10 hidden sm:block"></div>
                                    {children(close)}
                                </Fragment>
                            )}
                        </PopoverPanel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

export default NavigationDropoutMenu

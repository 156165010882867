import { Typography } from "@eyectrl/baseplate-design-system"
import React from "react"
import { MetricBlockWrapper, MetricBlockContent, MetricBlockHeader } from "./MetricBlock"
import WIDGET_COMPLETED_ILLUSTRATION from "@assets/illustrations/widget/widgetCompletedIllustration.svg?react"
import WIDGET_ERROR_ILLUSTRATION from "@assets/illustrations/widget/widgetErrorIllustration.svg?react"
import WIDGET_NO_DATA_ILLUSTRATION from "@assets/illustrations/widget/widgetNoDataIllustration.svg?react"
import i18n from "@/i18next"
import Tippy from "@tippyjs/react"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

type MetricVariant = "NO_DATA" | "ERROR" | "COMPLETED"
type DataType = "threatHunt" | "other"
interface Props {
    title: string
    dataType?: DataType
    variant: MetricVariant
}

const Illustration: React.FC<{ variant: MetricVariant; className: string }> = ({ variant, className }) => {
    switch (variant) {
        case "NO_DATA":
            return <WIDGET_NO_DATA_ILLUSTRATION className={className} />
        case "ERROR":
            return <WIDGET_ERROR_ILLUSTRATION className={className} />
        case "COMPLETED":
            return <WIDGET_COMPLETED_ILLUSTRATION className={className} />
        default:
            break
    }
}

const getMessage = (variant: MetricVariant, dataType: DataType): { subtitle: string; tooltip?: string | undefined } => {
    switch (variant) {
        case "NO_DATA":
            return {
                subtitle: i18n.t("dashboardMetrics:messages.noData.subtitle"),
                tooltip: i18n.t("dashboardMetrics:messages.noData.tooltip"),
            }
        case "ERROR":
            return {
                subtitle: i18n.t("dashboardMetrics:messages.error.subtitle"),
                tooltip: i18n.t("dashboardMetrics:messages.error.tooltip"),
            }
        case "COMPLETED":
            if (dataType === "threatHunt") {
                return { subtitle: i18n.t("dashboardMetrics:messages.completed.subtitleAcknowledged") }
            }
            return { subtitle: i18n.t("dashboardMetrics:messages.completed.subtitle") }
        default:
            return { subtitle: "" }
            break
    }
}

export const MetricBlockWithMessage: React.FC<Props> = ({ title, variant, dataType = "other" }) => {
    const message = getMessage(variant, dataType)

    return (
        <MetricBlockWrapper>
            <MetricBlockContent>
                <MetricBlockHeader title={title} />
                <div className="flex justify-center items-center h-full pt-5 flex-col">
                    <Illustration variant={variant} className="w-[150px]" />
                    <div className="flex items-center mt-4 gap-2">
                        <Typography variant="body-2" color="text-secondary">
                            {message?.subtitle}
                        </Typography>
                        {message?.tooltip && (
                            <div>
                                <Tippy
                                    interactive
                                    content={
                                        <Typography color="text-invert" variant="body-2">
                                            {message.tooltip}
                                        </Typography>
                                    }
                                >
                                    <div>
                                        <InformationCircleIcon width={18} className="text-text-secondary-light" />
                                    </div>
                                </Tippy>
                            </div>
                        )}
                    </div>
                </div>
            </MetricBlockContent>
        </MetricBlockWrapper>
    )
}

import React, { useEffect, useState } from "react"

import { StepHeader } from "./StepHeader"

export interface Step {
    title: string
    subtitle: string
    content: React.ReactNode
    actions: (onNextStep: () => void, onPreviousStep: () => void) => React.ReactNode
}

interface Props {
    steps: Step[]
    initialStepIndex: number
    onChangeStep?: (currentStep: number, updatedStep: number) => void
    onFinished?: () => void
}

export const SteppedFlow: React.FC<Props> = ({ initialStepIndex, steps, onChangeStep, onFinished }) => {
    const initialStep = steps[initialStepIndex] ? steps[initialStepIndex].title : undefined
    const [activeStepIndex, setActiveStepIndex] = useState(initialStepIndex)
    const activeStep = steps[activeStepIndex]

    /*
        todo: verify with @bas.meijer if this is the best way to solve the following
            the state should reset after switching to another organisation

        todo maybe this edge case:
            1. "Test Organisation ABC" has 2 as initial step, go to the next step (3). The request is done to mutate the step.
            2. go now to "Test Organisation JKL". The steps resets to step 1.
            3. If you now go back to "Test Organisation ABC", the steps wil reset to step 2 (the initial state).
     */
    useEffect(() => {
        setActiveStepIndex(initialStepIndex)
    }, [initialStepIndex])

    const onNextStep = () => {
        const newIndex = activeStepIndex + 1
        if (newIndex < steps.length) {
            // Update the parent component on change
            onChangeStep?.(activeStepIndex, newIndex)
            // Update the internal index
            setActiveStepIndex(newIndex)
        }

        // Calls the finish call if provided
        if (newIndex === steps.length && onFinished) {
            onFinished()
        }
    }

    const onPreviousStep = () => {
        const newIndex = activeStepIndex - 1
        if (newIndex >= 0) {
            // Update the parent component on change
            onChangeStep?.(activeStepIndex, newIndex)
            // Update the internal index
            setActiveStepIndex(newIndex)
        }
    }

    if (initialStep === undefined) {
        return null
    }

    return (
        <section className="h-full w-full flex flex-col">
            {/* Mobile step header */}
            <div className="w-full block lg:hidden p-4">
                <StepHeader title={activeStep.title} subtitle={activeStep.subtitle} isActive={true} />
            </div>

            {/* Desktop Header */}
            <div className="w-full hidden lg:flex flex-row gap-2 md:gap-4 z-30 relative p-12">
                {steps.map((step, index) => {
                    return (
                        <StepHeader
                            key={`step-${step.title}`}
                            title={step.title}
                            subtitle={step.subtitle}
                            isActive={index <= activeStepIndex}
                        />
                    )
                })}
            </div>

            {/* Main Content */}
            <div className="flex-1 overflow-auto px-4 lg:px-12 pb-12 pr-12 eye-page-width">{activeStep.content}</div>

            {/* Bottom nav bar */}
            <div className="flex justify-end items-center gap-4 border-t p-6">
                {activeStep.actions(onNextStep, onPreviousStep)}
            </div>
        </section>
    )
}

import React from "react"
import { Skeleton } from "./Skeleton"

export const SidebarSkeleton: React.FC = () => {
    return (
        <div className="flex flex-col p-4 w-full space-y-12">
            <div className="space-y-4 flex-1">
                <Skeleton className="w-[100%] h-[50px]" />
                <Skeleton className="w-[75px] h-[25px]" />
            </div>

            <div className="space-y-4 flex-1">
                <Skeleton className="w-[80%] h-[25px]" />
                <Skeleton className="w-[40%] h-[25px]" />
                <Skeleton className="w-[50%] h-[25px]" />
                <Skeleton className="w-[35%] h-[25px]" />
            </div>
        </div>
    )
}

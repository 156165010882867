import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import { ReportsSkeleton } from "@designSystem/feedback/skeletons/ReportsSkeleton"
import { MangementReportsTable } from "@features/managementReport/components/ManagementReportsTable"
import { useManagementReportsList } from "@features/managementReport/hooks/useManagementReportsList"
import { useActiveEyed } from "@hooks/useActiveEyed"
import React from "react"

export const Reports: React.FC = () => {
    const activeEyed = useActiveEyed()
    const { data, isPending } = useManagementReportsList(activeEyed)
    const managementReports = data?.data ?? []

    return (
        <PageLayout variant="detailPage">
            <PageHeader title="Reports" />

            {isPending ? <ReportsSkeleton /> : <MangementReportsTable managementReports={managementReports} />}
        </PageLayout>
    )
}

import axios from "axios"
import { getEnv } from "./envConfig"
import * as Sentry from "@sentry/react"
import { cleanEndpointsForSentry } from "@utils/sentryUtils"

export const PROD_API_URL = "https://api.portal.eye.security"
export const TST_API_URL = "https://api.portal.tst.eye.security"
export const LOCAL_API_URL = "http://localhost:8080"

/**
 * Initialises the base url and the interceptors for axios.
 */
export const setupAxios = () => {
    setApiBaseUrl()
    setAxiosInterceptors()
}

const setApiBaseUrl = () => {
    const env = getEnv()

    if (env === "production") {
        axios.defaults.baseURL = PROD_API_URL
        return
    }

    if (env === "test") {
        axios.defaults.baseURL = TST_API_URL
        return
    }

    axios.defaults.baseURL = LOCAL_API_URL
}

const setAxiosInterceptors = () => {
    // Add a response interceptor
    axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // We can do something with the response here...
            return response
        },
        function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // If the error is in the 500 range, we want to report it to Sentry
            const isNetworkError = error?.code === "ERR_NETWORK"
            const isServerError = error?.response?.status >= 429
            const endpoint = error?.config?.url

            if (isNetworkError) {
                Sentry.captureMessage(`Network error: ${cleanEndpointsForSentry(endpoint)}`)
            }

            if (isServerError) {
                Sentry.captureMessage(`${error?.response?.status} Server error: ${cleanEndpointsForSentry(endpoint)}`)
            }

            return Promise.reject(error)
        },
    )
}

export const getApiBaseUrl = () => {
    const env = getEnv()

    if (env === "production") {
        return PROD_API_URL
    }

    if (env === "test") {
        return TST_API_URL
    }

    return LOCAL_API_URL
}

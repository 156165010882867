import React from "react"
import { useRiskLocales } from "./useRiskLocales"
import { Typography } from "@eyectrl/baseplate-design-system"
import { ReadMoreSection } from "@designSystem/typography/ReadMoreSection"
import { MarkdownText } from "@components/markdownText/MarkdownText"
import { useTranslation } from "react-i18next"

export const LocalesOverview: React.FC = () => {
    const { data } = useRiskLocales()
    const { t } = useTranslation(["pages"])

    return (
        <div>
            <Typography variant="body-1" spacingBottom>
                Below shows every locale available to the Eye Portal. Each block shows the text as its being rendered
                inside the recommendation detail page markdown & read more components.
            </Typography>

            <div className="space-y-8">
                {data?.data.map((locale) => {
                    return (
                        <div key={locale.id} className="p-12 border">
                            <div className="mb-4">
                                <Typography variant="body-1" color="text-secondary">
                                    {locale.id}
                                </Typography>

                                <Typography variant="header-3">{locale.title}</Typography>
                            </div>

                            <div>
                                <Typography variant="header-2" spacingBottom>
                                    {t("recommendations.modaldescription")}
                                </Typography>
                                <ReadMoreSection
                                    text={locale?.finding_description ?? ""}
                                    typographyProps={{ variant: "body-1", shouldPreserveNewLines: true }}
                                    className="mb-8"
                                    renderText={(text) => <MarkdownText>{text}</MarkdownText>}
                                />
                            </div>

                            <div className="mt-4">
                                <Typography spacingBottom variant="header-2">
                                    {t("recommendations.modalsolution")}
                                </Typography>

                                <ReadMoreSection
                                    text={locale?.recommendation_description ?? ""}
                                    typographyProps={{ variant: "body-1", shouldPreserveNewLines: true }}
                                    renderText={(text) => <MarkdownText>{text}</MarkdownText>}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

import React, { useMemo } from "react"
import { BellIcon, EyeIcon, ShieldCheckIcon, ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline"
import { useTranslation } from "react-i18next"
import { IDetectionV2 } from "../types"
import { Timeline } from "@designSystem/timeline/Timeline"
import { isNullish } from "@utils/formatUtils"
import { DATE_FORMATS, formatDate } from "@utils/dateUtils"
import { Typography } from "@eyectrl/baseplate-design-system"

interface Props {
    detection: IDetectionV2
}

export const DetectionTimeline: React.FC<Props> = ({ detection }) => {
    const { t } = useTranslation()

    const buildTimeline = React.useCallback(
        (timelineDetection: IDetectionV2) => {
            const timeline = []

            if (!isNullish(timelineDetection.triggered_at)) {
                timeline.push({
                    content: t("detections:detailsView.timeline.triggeredAt"),
                    date: formatDate(timelineDetection.triggered_at, DATE_FORMATS.DATE_FORMAT_WRITTEN),
                    icon: <BellIcon width={20} height={20} className="text-text-critical-light" />,
                    iconBackground: "bg-background-critical-light",
                })
            }

            if (!isNullish(timelineDetection.acknowledged_at)) {
                timeline.push({
                    content: t("detections:detailsView.timeline.acknowledgedAt"),
                    date: formatDate(timelineDetection.acknowledged_at, DATE_FORMATS.DATE_FORMAT_WRITTEN),
                    icon: <EyeIcon width={20} height={20} className="text-text-secondary-light" />,
                    iconBackground: "bg-background-grey-light",
                })
            }

            if (!isNullish(timelineDetection.escalated_at)) {
                timeline.push({
                    content: t("detections:detailsView.timeline.escalatedAt"),
                    date: formatDate(timelineDetection.escalated_at, DATE_FORMATS.DATE_FORMAT_WRITTEN),
                    icon: <ChatBubbleOvalLeftEllipsisIcon width={20} height={20} className="text-text-link-light" />,
                    iconBackground: "bg-brand-eye-ice",
                })
            }

            if (isNullish(timelineDetection.escalated_at) && timelineDetection.contacted === true) {
                timeline.push({
                    content: t("detections:detailsView.timeline.escalatedAt"),
                    icon: <ChatBubbleOvalLeftEllipsisIcon width={20} height={20} className="text-text-link-light" />,
                    iconBackground: "bg-brand-eye-ice",
                })
            }

            if (!isNullish(timelineDetection.resolved_at)) {
                timeline.push({
                    content: t("detections:detailsView.timeline.resolvedAt"),
                    date: formatDate(timelineDetection.resolved_at, DATE_FORMATS.DATE_FORMAT_WRITTEN),
                    icon: <ShieldCheckIcon width={20} height={20} className="text-text-success-light" />,
                    iconBackground: "bg-background-success-light",
                })
            }

            return timeline
        },
        [t],
    )

    const timeline = useMemo(() => {
        return buildTimeline(detection)
    }, [detection, buildTimeline])

    if (timeline.length === 0) {
        return null
    }

    return (
        <section className="mt-8">
            <Typography variant="body-2-semibold">{t("detections:detailsView.timeline.title")}</Typography>
            <div className="mt-4">
                <Timeline items={timeline} />
            </div>
        </section>
    )
}

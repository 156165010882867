import classNames from "classnames"
import { Fragment } from "react"

interface Props {
    N: number
    className?: string
    alternative?: number[]
    alternativeClass?: string
    alternativeAfter?: number
}

export const Circles = ({
    N,
    alternativeAfter,
    className = "bg-black",
    alternative,
    alternativeClass,
}: Props): JSX.Element => (
    // Loops x times to produce x circles
    <Fragment>
        {[...Array(N)].map((_, i) => (
            <div
                key={i}
                className={classNames(`rounded-full w-3 h-3 |`, {
                    [`${alternativeClass}`]: alternative !== undefined ? alternative?.includes(i) : false,
                    [`${className}`]: alternative !== undefined ? !alternative?.includes(i) : true,

                    [`${alternativeClass}`]: alternativeAfter !== undefined ? i < alternativeAfter : false,
                    [`${className}`]: alternativeAfter !== undefined ? i >= alternativeAfter : true,
                })}
            ></div>
        ))}
    </Fragment>
)

import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import Tippy from "@tippyjs/react"
import React, { PropsWithChildren } from "react"
import { Trans, useTranslation } from "react-i18next"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

export const ThreatHuntingPageLayout: React.FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation()

    return (
        <PageLayout variant="detailPage">
            <PageHeader
                title={t("threatHunting:title")}
                titleRightContent={
                    <Tippy
                        interactive
                        content={
                            <Trans
                                i18nKey="threatHunting:tooltip"
                                components={[
                                    <a
                                        href="https://kb.eye.security/vulnerabilityandthreathuntingsummary"
                                        key="kb-link"
                                        className="inline underline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    />,
                                ]}
                            />
                        }
                    >
                        <div className="ml-2">
                            <InformationCircleIcon width={24} />
                        </div>
                    </Tippy>
                }
            />
            {children}
        </PageLayout>
    )
}

import React, { useContext, useMemo, useState } from "react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { BriefcaseIcon } from "@heroicons/react/24/outline"
import { UserContext } from "../../../providers/UserContext"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { LOCAL_STORAGE_ACTIVE_EYED_KEY } from "@/localStorage/localStorage"
import { useTranslation } from "react-i18next"
import { navigateToNewEyedRoute } from "@utils/routeUtils"
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react"
import classNames from "classnames"
import { EyeOrganisation } from "../hooks/useUserOrganisations"
import { Typography } from "@eyectrl/baseplate-design-system"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"

const PLACEHOLDER_SEARCH = "PLACEHOLDER_SEARCH"

const AccountSwitcher: React.FC = () => {
    const activeEyed = useActiveEyed()
    const { userOrganisations } = useContext(UserContext)
    const [searchValue, setSearchValue] = useState("")
    const location = useLocation()
    const navigate = useNavigate()
    const { ...restParams } = useParams()
    const { t } = useTranslation(["common"])
    const { captureEvent } = useCapturePostHogEvent()

    /**
     * Filters the organisations based on the searchValue.
     * If the result contains the active organisation, this is always return as the first.
     */
    const filteredOrganisations = useMemo(() => {
        const searchValueToUse = searchValue === PLACEHOLDER_SEARCH ? "" : searchValue

        const filtered = userOrganisations.filter((organistion) => {
            return organistion.name.toLowerCase().includes(searchValueToUse.toLowerCase())
        })

        // Filter the filtered results. If it contains the active organisation, put that first in the array.
        return [
            ...filtered.filter((c) => c.eyed === activeEyed),
            // After filtering, we also sort the remaining non-active items alphabetically.
            ...filtered.filter((c) => c.eyed !== activeEyed).sort((a, b) => a.name.localeCompare(b.name)),
        ]
    }, [searchValue, userOrganisations, activeEyed])

    const activeOrganistionName = userOrganisations.find((org) => org.eyed === activeEyed)

    const switchUserToNewEyedRoute = (newEyed: string, currentUrl: string) => {
        // Build new url and navigates the user there.
        navigateToNewEyedRoute(newEyed, currentUrl, navigate, restParams)
        // Capture the event with posthog.
        captureEvent(POSTHOG_CAPTURE_TYPES.USER_SWITCHED_ORGANISATION)
        // Set local storage active eyed and clear the search
        localStorage.setItem(LOCAL_STORAGE_ACTIVE_EYED_KEY, newEyed)
        setSearchValue("")
    }

    // If there are no organisations to choose from, simply don't load the switcher
    if (userOrganisations.length <= 1) {
        return null
    }

    /**
     * The ComboBox interaction doesn't 100% work as we'd like out of the box.
     * For example, when a user clicks the input or the input buttons. We'd like the form input to be emptied and show the placeholder text.
     * Out of the Box the combobox shows the last selected value in the input, therefor we add a PLACEHOLDER_SEARCH in the searvhValue and
     * then in the displayValue attribute we tell the combobox to render "" if that placeholder is set.
     *
     * We then need to use the onClick handlers and onBlur to make sure the PLACEHOLDER_SEARCH is set at the correct times.
     */
    return (
        <Combobox
            value={activeOrganistionName}
            onChange={(value) => {
                if (value) {
                    switchUserToNewEyedRoute(value.eyed, location.pathname)
                }
            }}
            immediate
        >
            <div className="relative cursor-pointer">
                <div>
                    <ComboboxButton
                        className="group absolute inset-y-0 left-0 px-3 pointer-events-none"
                        onClick={() => {
                            setSearchValue(PLACEHOLDER_SEARCH)
                        }}
                    >
                        <div className="bg-white p-2 rounded-md">
                            <BriefcaseIcon className="w-5 h-5" />
                        </div>
                    </ComboboxButton>

                    <ComboboxInput<EyeOrganisation>
                        onClick={(event) => {
                            // Select all the text in the input box ( makes searching easier )
                            event.currentTarget.select()
                            // Initially set the PLACEHOLDER_SEARCH as the search value, so the input knows to show an empty string
                            // This makes sure the combobox input is cleared for the user to indicate they can type a new search value.
                            setSearchValue(PLACEHOLDER_SEARCH)
                        }}
                        data-testid="account-switcher-input"
                        spellCheck={false}
                        autoComplete="off"
                        placeholder={t("accountSwitcher.placeholder")}
                        onBlur={() => {
                            // After the user is done editing AND the placeholder search is set, we can safely set the searchValue to an empty string again.
                            if (searchValue === PLACEHOLDER_SEARCH) {
                                setSearchValue("")
                            }
                        }}
                        className={classNames(
                            "w-full rounded-md border-none py-4 pl-16 pr-8 text-white font-semibold cursor-pointer placeholder:text-text-inactive-light",
                            "focus:bg-background-brand-hover-light bg-background-page-primary-light/10",
                        )}
                        displayValue={(organisation) => {
                            // If the placeholder text is present, show an empty search input to indicate to the user that they can type to search.
                            if (searchValue === PLACEHOLDER_SEARCH) {
                                return ""
                            }

                            if (organisation) {
                                return organisation?.name
                            }

                            return searchValue
                        }}
                        onChange={(event) => setSearchValue(event.target.value)}
                    />

                    <ComboboxButton
                        className="group absolute inset-y-0 right-0 px-3"
                        data-testid="account-switcher-button"
                        onClick={() => {
                            setSearchValue(PLACEHOLDER_SEARCH)
                        }}
                    >
                        <ChevronDownIcon className="size-4 fill-white/60 group-data-[hover]:fill-white" />
                    </ComboboxButton>
                </div>
            </div>

            <ComboboxOptions anchor="bottom" className="w-[var(--input-width)] rounded-md border mt-4 z-40 bg-white">
                <div className="max-h-[300px] overflow-auto p-2">
                    {filteredOrganisations.length === 0 && (
                        <div>
                            <div className="text-base p-4 text-text-secondary-light">
                                {t("accountSwitcher.notFound")}
                            </div>
                        </div>
                    )}

                    {filteredOrganisations.map((organisation) => (
                        <ComboboxOption
                            as="button"
                            key={organisation.eyed}
                            data-testid="account-switcher-option"
                            value={organisation}
                            className="group w-full flex cursor-pointer items-center gap-2 rounded-md py-1.5 px-4 select-none data-[focus]:bg-button-primary-background-disabled-light relative"
                        >
                            {organisation.eyed === activeEyed && (
                                <div className="rounded-full w-1 h-1 bg-brand-eye-orange absolute left-[6px]"></div>
                            )}
                            <Typography textElipsis shouldPreventWrapping>
                                {organisation.name}
                            </Typography>
                        </ComboboxOption>
                    ))}
                </div>
            </ComboboxOptions>
        </Combobox>
    )
}

export default AccountSwitcher

import { useEffect, useState } from "react"
// hook copied from: https://github.com/streamich/react-use/blob/master/docs/useMedia.md

export const isBrowser = typeof window !== "undefined"

const getInitialState = (query: string, defaultState?: boolean): boolean => {
    // Prevent a React hydration mismatch when a default value is provided by not defaulting to window.matchMedia(query).matches.
    if (defaultState !== undefined) {
        return defaultState
    }

    if (isBrowser) {
        return window.matchMedia(query).matches
    }

    return false
}

const useMediaQuery = (query: string, defaultState?: boolean): boolean => {
    const [state, setState] = useState(getInitialState(query, defaultState))

    useEffect(() => {
        let mounted = true
        const mql = window.matchMedia(query)
        const onChange = (): void => {
            if (!mounted) {
                return
            }
            setState(!!mql.matches)
        }

        mql.addListener(onChange)
        setState(mql.matches)

        return () => {
            mounted = false
            mql.removeListener(onChange)
        }
    }, [query])

    return state
}

export default useMediaQuery

import toast, { Renderable, Toast, ToastPosition, ValueFunction } from "react-hot-toast"

const successMessage = (message: string, options?: Partial<{ id: string; position?: ToastPosition }> | undefined) => {
    toast.success(message, options)
}

const errorMessage = (message: string, options?: Partial<{ id: string; position?: ToastPosition }> | undefined) => {
    toast.error(message, options)
}

const loadingMessage = (message: string, options?: Partial<{ id: string; position?: ToastPosition }> | undefined) => {
    toast.loading(message, options)
}

const customMessage = (
    renderToastMessage: ValueFunction<Renderable, Toast>,
    options?: Partial<{ id: string; duration: number | undefined; position?: ToastPosition }> | undefined,
) => {
    toast(renderToastMessage, options)
}

export const toastService = {
    successMessage,
    errorMessage,
    loadingMessage,
    customMessage,
}

import React, { PropsWithChildren, useEffect } from "react"
import { useApplicationStore } from "@/stores/applicationStore"
import { useSetupPostHog } from "./useSetupPostHog"

/**
 * Component that checks if users have already opted in or out of our analytics settings and sets up posthog accordingly.
 * If no prior consent was given, we show the cookie consent banner to handle this.
 */
export const PostHogConsentHandler: React.FC<PropsWithChildren> = ({ children }) => {
    const postHogConfigured = useApplicationStore((state) => state.postHogConfigured)
    const setShowConsentBanner = useApplicationStore((state) => state.setShowConsentBanner)
    const { setupPostHogWithConsent, setupPostHogWithoutConsent, hasOptedIn, hasOptedOut } = useSetupPostHog()

    // Triggers on render
    // Determines the current posthog state, checks if the user has already opted in, or opted out and then sets up accordingly.
    // If the user has not opted in or out, the consent banner will be activated.
    useEffect(() => {
        if (postHogConfigured) {
            // Posthog is already configured initially, so we can skip this setup step.
            return
        }

        // Means the user has already accepted the analytics previously ( cookie is present )
        if (hasOptedIn) {
            console.log("[PORTAL] User gave analytics consent previously, initialising analytics...")
            setupPostHogWithConsent()
            return
        }

        // Means the user has already declined the analytics previously ( cookie is present )
        if (hasOptedOut) {
            console.log("[PORTAL] User denied analytics consent previously, skipping analytics...")
            setupPostHogWithoutConsent()
            return
        }

        // User has not previously opted in or out, so we should see the consent banner.
        console.log("[PORTAL] Requesting analytics consent...")
        setShowConsentBanner(true)
    }, [
        setupPostHogWithConsent,
        setupPostHogWithoutConsent,
        hasOptedIn,
        hasOptedOut,
        postHogConfigured,
        setShowConsentBanner,
    ])

    return children
}

import React from "react"
import { Typography } from "@eyectrl/baseplate-design-system"
import { PolicyStatus } from "@features/recommendations/recommendationTypes"
import classNames from "classnames"

interface Props {
    status: PolicyStatus
}

export const ClassificationBadge: React.FC<Props> = ({ status }) => {
    const textColor = status === "Good" ? "text-success" : status === "Bad" ? "text-critical" : "text-secondary"

    return (
        <div
            className={classNames(
                { "bg-eye-green-500/10 ": status === "Good" },
                { "bg-neutral-100/10 ": status === "Neutral" },
                { "bg-red-100/10": status === "Bad" },
                "inline-flex py-1 px-3 items-center justify-center rounded-md",
            )}
        >
            <Typography variant="body-2-semibold" color={textColor} shouldPreventWrapping>
                {status}
            </Typography>
        </div>
    )
}

import React, { Fragment, useContext, useState } from "react"
import { PageHeader } from "../components/header/PageHeader"
import { UserContext } from "../providers/UserContext"
import { useSingleRecommendation } from "../features/recommendations/hooks/useRecommendations"
import { useParams } from "react-router-dom"
import { PageLayout } from "../components/layout/PageLayout"
import { useTranslation } from "react-i18next"
import { Typography } from "@eyectrl/baseplate-design-system"
import { RecommendationHeader } from "../features/recommendations/components/RecommendationHeader"
import { ReadMoreSection } from "../components/designSystem/typography/ReadMoreSection"
import FeedbackModal from "../components/designSystem/overlays/modal/FeedbackModal"
import { RecommendationDetailSkeleton } from "../components/designSystem/feedback/skeletons/RecommendationDetailSkeleton"
import { TimeAgoDate } from "../components/dates/TimeAgoDate"
import { Alert } from "../components/designSystem/feedback/notifications/Alert"
import { AssetsTable } from "../features/recommendations/components/tables/AssetsTable"
import { MarkdownText } from "@components/markdownText/MarkdownText"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { MFAUserInfoTable } from "@features/recommendations/components/tables/MFAUserInfoTable"
import { MFAPoliciesv2Table } from "@features/recommendations/components/tables/MFAPoliciesv2Table"
import { MFAPoliciesTable } from "@features/recommendations/components/tables/MFAPoliciesTable"
import { DownloadRecommendationTableButton } from "@features/recommendations/components/DownloadRecommendationTableButton"
import { InsuranceUpsellEDRBanner } from "@features/insuranceUpsell/InsuranceUpsellEDRBanner"

const formatSubject = (label?: string): string | undefined => {
    if (label) {
        return `Subject: ${label}`
    }

    return undefined
}

export const RecommendationDetails: React.FC = () => {
    const { language } = useContext(UserContext)
    const activeEyed = useActiveEyed()
    const { id = "" } = useParams()
    const { selectedRecommendation, isPending } = useSingleRecommendation(activeEyed, language, id)
    const { t } = useTranslation(["pages"])
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false)

    if (isPending) {
        return <RecommendationDetailSkeleton />
    }

    return (
        <PageLayout variant="detailPage">
            <PageHeader
                title={selectedRecommendation?.label ?? ""}
                withBreadcrumbs
                bannerContent={<InsuranceUpsellEDRBanner />}
                subtitle={
                    <TimeAgoDate
                        prefix={t("details.last_scanned")}
                        timestamp={selectedRecommendation?.last_scanned}
                        tooltipPlacement="right"
                    />
                }
            />

            <FeedbackModal
                open={isFeedbackOpen}
                setOpen={() => setIsFeedbackOpen(false)}
                hideRating
                subject={formatSubject(selectedRecommendation?.label)}
            />

            {!selectedRecommendation && (
                <Alert
                    type="warning"
                    title={t("recommendations.noDataWarning.title")}
                    text={t("recommendations.noDataWarning.text")}
                />
            )}

            {selectedRecommendation && (
                <Fragment>
                    <RecommendationHeader
                        recommendation={selectedRecommendation}
                        setIsFeedbackOpen={setIsFeedbackOpen}
                    />

                    {/* Description section  */}
                    <div>
                        <Typography variant="header-2" spacingBottom>
                            {t("recommendations.modaldescription")}
                        </Typography>

                        <ReadMoreSection
                            text={selectedRecommendation?.description ?? ""}
                            typographyProps={{ variant: "body-1", shouldPreserveNewLines: true }}
                            className="mb-8"
                            renderText={(text) => <MarkdownText>{text}</MarkdownText>}
                        />
                    </div>

                    {/* Solution section  */}
                    <div className="mt-4">
                        <Typography spacingBottom variant="header-2">
                            {t("recommendations.modalsolution")}
                        </Typography>

                        <ReadMoreSection
                            text={selectedRecommendation?.solution ?? ""}
                            typographyProps={{ variant: "body-1", shouldPreserveNewLines: true }}
                            renderText={(text) => <MarkdownText>{text}</MarkdownText>}
                        />
                    </div>

                    {/* Show the assets table if there are assets and there are more than 0 assets */}
                    {selectedRecommendation.assets && Object.values(selectedRecommendation?.assets).length > 0 && (
                        <div className="mt-8">
                            <div className="mb-4 flex justify-end">
                                <DownloadRecommendationTableButton
                                    recommendation={selectedRecommendation}
                                    tableType="assets"
                                />
                            </div>
                            <AssetsTable assets={selectedRecommendation.assets} />
                        </div>
                    )}

                    {/* Show the MFA Policies table if the policies exist ( in practice this will only be on the MFA Recommendation ) */}
                    {selectedRecommendation.details && selectedRecommendation.details.mfa_policies && (
                        <div className="mt-8">
                            <MFAPoliciesTable MfaPolicies={selectedRecommendation.details.mfa_policies} />
                        </div>
                    )}

                    {/* Show the MFA V2 Policies table if the policies exist ( in practice this will only be on the MFA Recommendation ) */}
                    {selectedRecommendation.details && selectedRecommendation.details.mfa_policies_v2 && (
                        <div className="mt-8">
                            <div className="mb-4 flex justify-between items-center">
                                <Typography spacingBottom variant="header-5">
                                    {t("recommendations.policies")}
                                </Typography>

                                <DownloadRecommendationTableButton
                                    recommendation={selectedRecommendation}
                                    tableType="policies"
                                />
                            </div>

                            <MFAPoliciesv2Table policies={selectedRecommendation.details.mfa_policies_v2} />
                        </div>
                    )}

                    {/* Show the MFA User info table if the user mfa info exists ( in practice this will only be on the MFA Recommendation ) */}
                    {selectedRecommendation.details && selectedRecommendation.details.mfa_user_info && (
                        <div className="mt-8">
                            <div className="mb-4 flex justify-between items-center">
                                <Typography spacingBottom variant="header-5">
                                    {t("recommendations.assets")}
                                </Typography>
                                <DownloadRecommendationTableButton
                                    recommendation={selectedRecommendation}
                                    tableType="assets"
                                />
                            </div>
                            <MFAUserInfoTable UserMfaInfo={selectedRecommendation.details.mfa_user_info} />
                        </div>
                    )}
                </Fragment>
            )}
        </PageLayout>
    )
}

export interface IOnboardingState {
    awarenessOnboarding: "ONBOARDING_IN_PROGRESS" | "ONBOARDING_COMPLETED" | "ONBOARDING_NOT_APPLICABLE"
    serviceOnboarding: "ONBOARDING_IN_PROGRESS" | "ONBOARDING_COMPLETED"
    sentinelOnboarding:
        | "AZURE_SUBSCRIPTION_REQUESTED"
        | "OPERATIONS_ONBOARDING_IN_PROGRESS"
        | "ONBOARDING_COMPLETED"
        | "ONBOARDING_NOT_APPLICABLE"
}

export const getOnboardingProgress = (onboardingState: IOnboardingState) => {
    let steps = 1 // The serviceOnboarding step is present for ALL organisations so it starts from 1
    let completedSteps = 0

    // Check if the awarenessOnboarding & sentinel onboarding steps are applicable, if so add it to the steps list.
    if (onboardingState.awarenessOnboarding !== "ONBOARDING_NOT_APPLICABLE") {
        steps += 1
    }

    if (onboardingState.sentinelOnboarding !== "ONBOARDING_NOT_APPLICABLE") {
        steps += 1
    }

    // Then check the states for each step to see which ones are completed
    if (onboardingState.awarenessOnboarding === "ONBOARDING_COMPLETED") {
        completedSteps += 1
    }

    if (onboardingState.serviceOnboarding === "ONBOARDING_COMPLETED") {
        completedSteps += 1
    }

    if (onboardingState.sentinelOnboarding === "ONBOARDING_COMPLETED") {
        completedSteps += 1
    }

    const completedPercentage = (completedSteps / steps) * 100

    return {
        label: `${completedSteps}/${steps}`,
        percentage: completedPercentage,
    }
}

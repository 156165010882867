import React from "react"
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getPaginationRowModel,
} from "@tanstack/react-table"
import { TablePaginationWrapper } from "@designSystem/layout/table/TablePaginationWrapper"
import { useDetectionsColumnVisibility } from "../../detections/hooks/useDetectionsColumnVisibility"
import { WidgetSize } from "@features/dashboard/dashboardTypes"
import { IDetectionV2 } from "@features/detectionsv2/types"
import { getDetectionV2Columns } from "../detectionv2Columns"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import { useTranslation } from "react-i18next"
import SearchIllustration from "@assets/illustrations/searchIllustration.svg?react"
import {
    Table,
    TableBody,
    TableCell,
    TableColumnHeaderCell,
    TableHeader,
    TableRow,
} from "@eyectrl/baseplate-design-system"

/**
 * The columns that will be used in the table
 */
const columns = getDetectionV2Columns()

interface Props {
    data: IDetectionV2[]
    onClickRow?: (detection: IDetectionV2) => void
    selectedDetectionId?: string
    isRounded?: boolean
    hasBorder?: boolean
    size?: WidgetSize
    selectedDetection?: IDetectionV2
}

export const DetectionsV2Table: React.FC<Props> = ({
    data,
    onClickRow,
    selectedDetectionId,
    isRounded = true,
    hasBorder = true,
    size = "full",
    selectedDetection,
}) => {
    const [sorting, setSorting] = React.useState<SortingState>([])
    const { columnVisibility } = useDetectionsColumnVisibility(size)
    const { t } = useTranslation()

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnVisibility,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    // If the data fetching is still pending, the table component should not load. So we can safely show this warning.
    if (data.length === 0) {
        return (
            <div className="bg-white border p-12 rounded-md">
                <NoDataMessage
                    title={t("detections:dataMessages.couldNotFindTitle")}
                    subtitle={t("detections:dataMessages.couldNotFindDescription")}
                    variant="row"
                    image={<SearchIllustration className="w-[250px]" />}
                />
            </div>
        )
    }

    return (
        <TablePaginationWrapper
            data={data}
            table={table}
            isRounded={isRounded}
            hasBorder={hasBorder}
            className="bg-white"
        >
            <Table isRounded={isRounded} hasBorder={hasBorder}>
                <TableHeader className="bg-white">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id} isHoverable={false}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableColumnHeaderCell key={header.id} width={header.getSize()}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableColumnHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow
                                key={row.id}
                                dataTestId="detections-widget-row"
                                className={
                                    // Switch the hover color to the table hover color, if the selected items is this row.
                                    row.original.id === selectedDetection?.id
                                        ? "bg-table-selected-primary-light hover:bg-table-selected-primary-light"
                                        : ""
                                }
                                isSelected={selectedDetectionId === row.original.id}
                                onClick={
                                    onClickRow
                                        ? () => {
                                              onClickRow(row.original)
                                          }
                                        : undefined
                                }
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id} width={cell.column.getSize()}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TablePaginationWrapper>
    )
}

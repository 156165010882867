import React, { useContext } from "react"
import Modal from "@designSystem/overlays/modal/Modal"
import { useTranslation } from "react-i18next"
import HelpdeskIllustration from "@assets/illustrations/helpdeskIllustration.svg?react"
import { Button, Typography } from "@eyectrl/baseplate-design-system"
import { UserContext } from "@/providers/UserContext"

export const DetectionsIntroModal: React.FC = () => {
    const { userProfile, updateUserProfileProperty } = useContext(UserContext)
    const showIntroductionModal = !userProfile.has_visited_detections_v2
    const { t } = useTranslation()

    const onCloseModal = () => {
        updateUserProfileProperty({ has_visited_detections_v2: true })
    }

    if (!showIntroductionModal) {
        return null
    }

    return (
        <Modal open={showIntroductionModal} setOpen={onCloseModal} className="p-10" withCloseButton>
            <div className="w-[400px]">
                <div className="flex justify-center mb-4">
                    <HelpdeskIllustration />
                </div>

                <div className="mt-6">
                    <Typography variant="header-2">{t("detections:modal.title")}</Typography>
                    <Typography className="mt-4">{t("detections:modal.description")}</Typography>
                    <Button
                        variant="primary"
                        className="mt-6"
                        size="small"
                        onClick={() => {
                            onCloseModal()
                        }}
                    >
                        {t("detections:modal.button")}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

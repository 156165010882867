import { useAuth0 } from "@auth0/auth0-react"
import * as Sentry from "@sentry/browser"
import { usePostHog } from "posthog-js/react"
import { clearAxiosAuthToken } from "@/api/requests"
import { localStorageSettings } from "@/localStorage/localStorage"

export const useLogoutUser = () => {
    const { logout } = useAuth0()
    const posthog = usePostHog()

    const logoutUser = (): void => {
        // Logout the user in auth0
        logout({ logoutParams: { returnTo: window.location.origin } })
        // reset the user sentry context
        Sentry.setUser(null)
        // reset makes sure the user is reset to an "unknown user" until they login again.
        posthog.reset()
        // clears the default auth token in axios config
        clearAxiosAuthToken()
        // Remove the active eyed from the local storage.
        localStorageSettings.removeActiveEyed()
    }

    return { logoutUser }
}

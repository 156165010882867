import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { DEFAULT_DATE_RANGES, DefaultRange, IDateRange, getSpecifiedDateRange } from "@utils/dateRangeUtils"
import React from "react"
import { useTranslation } from "react-i18next"
import { Dropdown, DropdownListItem, DropdownPanel, Typography } from "@eyectrl/baseplate-design-system"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"

interface Props {
    rangeOption: DefaultRange
    onChange: (range: IDateRange | undefined, option: DefaultRange) => void
}

export const DateRangeOptions: React.FC<Props> = ({ rangeOption, onChange }) => {
    const { t } = useTranslation(["common"])
    const { captureEvent } = useCapturePostHogEvent()

    const handleChange = (defaultRange: DefaultRange) => {
        onChange(getSpecifiedDateRange(defaultRange), defaultRange)
    }

    const rangeOptions = [
        {
            label: t("common:dateRangePicker.options.today"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.TODAY)
                captureEvent(POSTHOG_CAPTURE_TYPES.DATE_RANGE_PICKER.TODAY)
            },
        },
        {
            label: t("common:dateRangePicker.options.last7Days"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.LAST_7_DAYS)
                captureEvent(POSTHOG_CAPTURE_TYPES.DATE_RANGE_PICKER.LAST_7_DAYS)
            },
        },
        {
            label: t("common:dateRangePicker.options.thisWeek"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.THIS_WEEK)
                captureEvent(POSTHOG_CAPTURE_TYPES.DATE_RANGE_PICKER.THIS_WEEK)
            },
        },
        {
            label: t("common:dateRangePicker.options.last30Days"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.LAST_30_DAYS)
                captureEvent(POSTHOG_CAPTURE_TYPES.DATE_RANGE_PICKER.LAST_30_DAYS)
            },
        },
        {
            label: t("common:dateRangePicker.options.thisMonth"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.THIS_MONTH)
                captureEvent(POSTHOG_CAPTURE_TYPES.DATE_RANGE_PICKER.THIS_MONTH)
            },
        },
        {
            label: t("common:dateRangePicker.options.last6Months"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.LAST_6_MONTHS)
                captureEvent(POSTHOG_CAPTURE_TYPES.DATE_RANGE_PICKER.LAST_6_MONTHS)
            },
        },
        {
            label: t("common:dateRangePicker.options.thisYear"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.THIS_YEAR)
                captureEvent(POSTHOG_CAPTURE_TYPES.DATE_RANGE_PICKER.THIS_YEAR)
            },
        },
        {
            label: t("common:dateRangePicker.options.allTime"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.ALL_TIME)
                captureEvent(POSTHOG_CAPTURE_TYPES.DATE_RANGE_PICKER.ALL_TIME)
            },
        },
    ]

    return (
        <Dropdown
            button={
                <div className="h-[36px] border-0 rounded-none w-[140px] flex items-center justify-between px-4">
                    <Typography variant="body-2">{t(`common:dateRangePicker.options.${rangeOption}`)}</Typography>
                    <ChevronDownIcon height={16} width={16} className="text-text-secondary-light" />
                </div>
            }
        >
            {(closePanel) => {
                return (
                    <DropdownPanel>
                        {rangeOptions.map((rangeOption) => {
                            return (
                                <DropdownListItem
                                    key={`range-option-${rangeOption.label}`}
                                    onClick={() => {
                                        rangeOption.onClick()
                                        closePanel()
                                    }}
                                >
                                    <Typography variant="body-2" color="inherit">
                                        {rangeOption.label}
                                    </Typography>
                                </DropdownListItem>
                            )
                        })}
                    </DropdownPanel>
                )
            }}
        </Dropdown>
    )
}

import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import { Toggle, Typography } from "@eyectrl/baseplate-design-system"
import React, { PropsWithChildren } from "react"
import {
    BuildingOffice2Icon,
    DocumentTextIcon,
    BuildingLibraryIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/outline"
import { Link } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { useApplicationStore } from "@/stores/applicationStore"
import { Button } from "@eyectrl/baseplate-design-system"

const AdminBlock: React.FC<PropsWithChildren> = ({ children }) => {
    return <div className="flex w-[600px]">{children}</div>
}

export const AdminDashboard: React.FC = () => {
    const riskStagingEnabled = useApplicationStore((state) => state.riskStagingEnabled)
    const setRiskStagingEnabled = useApplicationStore((state) => state.setRiskStagingEnabled)

    return (
        <PageLayout variant="detailPage">
            <PageHeader title={"Admin Dashboard"} />

            <section className="flex flex-col gap-8">
                <AdminBlock>
                    <Link to={getRouterLinks().admin.awarenessCSV()} className="w-full">
                        <div className="p-4 border rounded-md flex items-center gap-4 hover:bg-eye-lightblue-100">
                            <BuildingOffice2Icon width={32} />
                            <div>
                                <Typography variant="body-1-semibold">Awareness CSV Administration</Typography>
                                <Typography variant="body-1" color="text-secondary">
                                    Upload CSV files to update the awareness recipients for an organisation
                                </Typography>
                            </div>
                        </div>
                    </Link>
                </AdminBlock>

                <AdminBlock>
                    <Link to={getRouterLinks().admin.awarenessOrganisations()} className="w-full">
                        <div className="p-4 border rounded-md flex items-center gap-4 hover:bg-eye-lightblue-100">
                            <BuildingLibraryIcon width={32} />
                            <div>
                                <Typography variant="body-1-semibold">
                                    Awareness Organisations Administration
                                </Typography>
                                <Typography variant="body-1" color="text-secondary">
                                    Awareness settings per organisation
                                </Typography>
                            </div>
                        </div>
                    </Link>
                </AdminBlock>

                <AdminBlock>
                    <Link to={getRouterLinks().riskLocales()} className="w-full">
                        <div className="p-4 border rounded-md flex items-center gap-4 hover:bg-brand-eye-ice-100">
                            <DocumentTextIcon width={32} />
                            <div>
                                <Typography variant="body-1-semibold">Risk Locales</Typography>
                                <Typography variant="body-1" color="text-secondary">
                                    See how each risk locale is being rendered inside the portal.
                                </Typography>
                            </div>
                        </div>
                    </Link>
                </AdminBlock>

                <AdminBlock>
                    <div className="p-4 border rounded-md flex items-center gap-4 hover:bg-brand-eye-ice-100 w-full">
                        <DocumentTextIcon width={32} />

                        <div className="flex flex-1 justify-between">
                            <div>
                                <Typography variant="body-1-semibold">Risk Staging Environment</Typography>
                                <Typography variant="body-1" color="text-secondary">
                                    Changes output of the dashboard & recommendations endpoints.
                                </Typography>
                            </div>
                            <Toggle
                                checked={riskStagingEnabled}
                                onChange={() => {
                                    setRiskStagingEnabled(!riskStagingEnabled)
                                }}
                            />
                        </div>
                    </div>
                </AdminBlock>
                <AdminBlock>
                    <div className="p-4 border rounded-md flex items-center gap-4 hover:bg-brand-eye-ice-100 w-full">
                        <ExclamationTriangleIcon width={32} />

                        <div className="flex flex-1 justify-between">
                            <div>
                                <Typography variant="body-1-semibold">Create Sentry Test Error</Typography>
                                <Typography variant="body-1" color="text-secondary">
                                    Sends a testing error to the sentry environment
                                </Typography>
                            </div>
                            <Button
                                variant="secondary"
                                size="small"
                                onClick={() => {
                                    throw new Error("This is a sentry test error")
                                }}
                            >
                                Create Test Error
                            </Button>
                        </div>
                    </div>
                </AdminBlock>
            </section>
        </PageLayout>
    )
}

import classNames from "classnames"
import React from "react"

interface Props {
    icon: React.ReactNode
    color: "blue" | "orange" | "yellow" | "gray"
    size?: "small" | "large"
}

const getThemeColor = (color: Props["color"]): string => {
    switch (color) {
        case "blue":
            return "bg-blue-100 text-brand-eye-blue"
        case "gray":
            return "bg-neutral-100 text-neutral-500"
        case "orange":
            return "bg-orange-100 text-brand-eye-orange"
        case "yellow":
            return "bg-yellow-100 text-yellow-500"

        default:
            return ""
    }
}

export const ActionIcon: React.FC<Props> = ({ icon, color, size = "small" }) => {
    return (
        <div
            className={classNames([
                "rounded flex justify-center items-center shrink-0",
                getThemeColor(color),
                { "w-[28px] h-[28px]": size === "small" },
                { "w-[40px] h-[40px]": size === "large" },
            ])}
        >
            {icon}
        </div>
    )
}

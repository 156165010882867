import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { IManagementReportListItem } from "../reportTypes"
import { useAuth0 } from "@auth0/auth0-react"
import { getRequest } from "@/api/requests"
import { getManagementReportListEndpoint } from "@/api/endpoints"

export interface IManagementReportsListDataSource {
    data: IManagementReportListItem[] | null
}

export const useManagementReportsList = (eyed: string): UseQueryResult<IManagementReportsListDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const endpoint = getManagementReportListEndpoint(eyed)

    return useQuery<IManagementReportsListDataSource, Error>({
        queryKey: endpoint.queryKey,
        queryFn: () => getRequest(endpoint.url, getAccessTokenSilently),
    })
}

import { getCurrentMonthReportEndpoint, getScheduledManagementReportEndpoint } from "@/api/endpoints"
import { getRequest } from "@/api/requests"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { toastService } from "@/services/toastService"
import { useAuth0 } from "@auth0/auth0-react"
import { useMutation } from "@tanstack/react-query"
import { getCurrentYearMonth } from "@utils/dateUtils"
import { useTranslation } from "react-i18next"

type CurrentMontReportVariables = { eyed: string; language: string; organisationName: string | undefined }
type ScheduledReportVariables = { eyed: string; language: string; date: string; organisationName: string | undefined }

export const useDownloadManagementReport = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { t } = useTranslation()
    const { captureEvent } = useCapturePostHogEvent()

    const buildFilename = (variables: CurrentMontReportVariables | ScheduledReportVariables) => {
        const shouldDownloadScheduledReport = "date" in variables
        const orgName = variables.organisationName ?? variables.eyed

        // The Filename for the scheduled (pre generated ) report
        if (shouldDownloadScheduledReport) {
            return `Eye-Security-Monthly-Report-${variables.date}-${orgName}-${variables.language}.pdf`
        }

        // The Filename for the current month report
        return `Eye-Security-Report-${getCurrentYearMonth()}-${orgName}-${variables.language}.pdf`
    }

    return useMutation({
        mutationFn: async (variables: CurrentMontReportVariables | ScheduledReportVariables) => {
            const shouldDownloadScheduledReport = "date" in variables

            const managementReportingEndpoint = shouldDownloadScheduledReport
                ? getScheduledManagementReportEndpoint(variables.eyed, variables.language, variables.date)
                : getCurrentMonthReportEndpoint(variables.eyed, variables.language)

            const response = await getRequest<Blob>(managementReportingEndpoint.url, getAccessTokenSilently, {
                responseType: "blob",
                headers: {
                    Accept: "application/pdf",
                },
            })

            // Return the blob so the onSuccess handler can process the download itself.
            return response
        },
        onMutate: (variables) => {
            const shouldDownloadScheduledReport = "date" in variables

            if (shouldDownloadScheduledReport) {
                captureEvent(POSTHOG_CAPTURE_TYPES.MANAGEMENT_REPORTING.DOWNLOAD_MONTHLY_REPORT, {
                    language: variables.language,
                })
            } else {
                captureEvent(POSTHOG_CAPTURE_TYPES.MANAGEMENT_REPORTING.DOWNLOAD_CURRENT_REPORT, {
                    language: variables.language,
                })
            }
        },
        onSuccess: (data, variables) => {
            const url = window.URL.createObjectURL(data)
            // Create an anchor element to trigger the download
            const a = document.createElement("a")
            a.href = url
            a.download = buildFilename(variables)
            // Trigger the download
            a.click()
            // Clean up the temporary URL
            window.URL.revokeObjectURL(url)
            toastService.successMessage(t("toastMessages:managementReport.success"))
        },
        onError: () => {
            toastService.errorMessage(t("toastMessages:managementReport.error"))
        },
    })
}

import { Skeleton } from "@designSystem/feedback/skeletons/Skeleton"
import { Typography } from "@eyectrl/baseplate-design-system"
import React, { PropsWithChildren } from "react"

interface Props {
    showSkeleton?: boolean
    title: string
    statistic: {
        number: number
        text: string
        description?: string
    }
    icon: React.ReactNode
    errorMessage?: string
}

const StatisticBlockWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    return <div className="min-w-[325px] p-6 border rounded-md flex-1">{children}</div>
}

export const StatisticBlock: React.FC<Props> = ({ icon, title, statistic, errorMessage, showSkeleton = false }) => {
    if (showSkeleton) {
        return (
            <StatisticBlockWrapper>
                <Skeleton className="w-[30%] h-[15px]" />

                <div className="mt-4 flex ">
                    <Skeleton className="w-[75%] h-[35px]" />
                </div>
            </StatisticBlockWrapper>
        )
    }

    return (
        <StatisticBlockWrapper>
            <div className="flex gap-2">
                {icon}

                <Typography variant="body-1">{title}</Typography>
            </div>

            <div className="flex justify-between mt-4 items-baseline">
                {errorMessage ? (
                    <Typography color="text-secondary" variant="header-4">
                        {"Data not found"}
                    </Typography>
                ) : (
                    <>
                        <div className="flex items-baseline gap-2">
                            <Typography variant="header-1-light">{statistic.number}</Typography>
                            {statistic.text && <Typography variant="header-5">{statistic.text}</Typography>}
                        </div>
                        {statistic.description && (
                            <Typography color="text-secondary">{statistic.description}</Typography>
                        )}
                    </>
                )}
            </div>
        </StatisticBlockWrapper>
    )
}

import { UrlSyncableProperties, removeNullishValuesFromUrlState } from "@utils/urlUtils"
import { useSearchParams } from "react-router-dom"

export type SyncablePropertiesObject = Record<UrlSyncableProperties, string | undefined | null>

export const useSyncStateToUrl = (): ((
    state: Partial<Record<UrlSyncableProperties, string | undefined | null>>,
) => void) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const syncStateToUrl = (state: Partial<Record<UrlSyncableProperties, string | undefined | null>>): void => {
        // Get key value pairs of the search params
        const searchParamPairs = Array.from(searchParams.entries())

        // Build a key/value object out of the search params
        const previousSearchParamKeyValuePairs = searchParamPairs.reduce((previous, currentValue) => {
            return {
                ...previous,
                [currentValue[0]]: currentValue[1],
            }
        }, {})

        // Cleans off any nullish values from the state, we don't want these represented in the url
        const cleanedState = removeNullishValuesFromUrlState({ ...previousSearchParamKeyValuePairs, ...state })

        // Once the state is clean, we can add it to the url.
        setSearchParams(cleanedState)
    }

    return syncStateToUrl
}

import React from "react"
import { Skeleton } from "./Skeleton"

export const CardSkeleton: React.FC = () => {
    return (
        <div className="card p-4 w-full flex items-start gap-6">
            <div className="">
                <Skeleton className="w-[50px] h-[50px] rounded-full" />
            </div>
            <div className="flex-1 space-y-4">
                <Skeleton className="w-full h-[25px]" />
                <Skeleton className="w-full h-[25px]" />
                <Skeleton className="w-full h-[25px]" />
            </div>
        </div>
    )
}

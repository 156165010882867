import React, { ForwardRefExoticComponent } from "react"
import NavigationLink from "./NavigationLink"
import { Typography } from "@eyectrl/baseplate-design-system"
import { EyeCon } from "@designSystem/icons/Icons"

interface Props {
    label: string
    hasHoverClass?: boolean // if true, the active hover class will enabled the hover background ( usefull in dropout nav for example )
    onNavigate?: () => string
    onClick?: () => void // can be used instead of onNavigate
    iconLeft?: {
        component: ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref">> | EyeCon // very specific if you want to use a svg icon on the left side.
    }
    contentLeft?: React.ReactNode // Can be used with any react component
    contentRight?: React.ReactNode
}

const getClassName = (isActive: boolean): string => {
    return isActive ? "navigation-button active" : "navigation-button"
}

const NavigationButtonContent: React.FC<Props> = ({ label, iconLeft, contentRight, contentLeft }) => {
    return (
        <>
            <div className="flex items-center gap-3">
                {iconLeft && (
                    <div className="w-[20px] flex items-center">
                        <iconLeft.component className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                    </div>
                )}
                {contentLeft && <div className="w-[20px] flex items-center">{contentLeft}</div>}
                <Typography
                    variant="body-1-semibold"
                    color="inherit"
                    shouldPreventWrapping
                    textElipsis
                    className="max-w-[180px]"
                >
                    {label}
                </Typography>
            </div>

            {contentRight && <div className="ml-2">{contentRight}</div>}
        </>
    )
}

const NavigationButton: React.FC<Props> = (props) => {
    if (props.onNavigate) {
        return (
            <NavigationLink to={props.onNavigate()} getClassName={getClassName} onClick={props.onClick}>
                <NavigationButtonContent {...props} />
            </NavigationLink>
        )
    }

    return (
        <button
            className={`navigation-button w-full ${props.hasHoverClass ? "active-hover" : ""}`}
            onClick={props.onClick}
        >
            <NavigationButtonContent {...props} />
        </button>
    )
}

export default NavigationButton

import React from "react"
import { NavLink } from "react-router-dom"
import EyeLogo from "../../assets/logos/eyeLogo.svg?react"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { getRouterLinks } from "@/config/routes/routeLinksConfig"

const PortalLogo: React.FC = () => {
    const routerLinks = getRouterLinks()
    const activeEyed = useActiveEyed()

    return (
        <NavLink to={routerLinks.dashboard({ eyed: activeEyed })}>
            <EyeLogo />
        </NavLink>
    )
}

export default PortalLogo

import { Typography } from "@eyectrl/baseplate-design-system"

interface Props {
    number: number
    title: string
    text: string
    size?: "regular" | "large"
}

export const StepText: React.FC<Props> = ({ number, title, text, size = "regular" }) => {
    return (
        <div className="flex gap-4">
            <div className="w-8 h-8 rounded-full border border-brand-eye-ice relative">
                <Typography variant="header-5" className="absolute top-[7px] left-[10px]" color="text-brand-eye-ice">
                    {number}
                </Typography>
            </div>

            <div className="flex-1">
                <Typography variant={size === "regular" ? "body-2-semibold" : "body-1-semibold"}>{title}</Typography>
                <Typography variant={size === "regular" ? "body-3" : "body-2"} className="mt-2">
                    {text}
                </Typography>
            </div>
        </div>
    )
}

import React from "react"
import { Skeleton } from "./Skeleton"

export const PieChartSkeleton: React.FC = () => {
    return (
        <div className="w-full h-full flex items-center justify-center p-8">
            <Skeleton className=" w-[125px] h-[125px]" roundedClass="rounded-full" />
        </div>
    )
}

import { Button } from "@eyectrl/baseplate-design-system"
import Modal from "@designSystem/overlays/modal/Modal"
import { ModalFooter } from "@designSystem/overlays/modal/ModalFooter"
import { Fragment, useState } from "react"
import { CookieSelection } from "./CookieSelection"
import { CookieInformation } from "./CookieInformation"
import { motion, AnimatePresence } from "framer-motion"
import { useSetupPostHog } from "@/posthog/useSetupPostHog"
import { PREVENT_POSTHOG_CAPTURE_EVENTS_CLASS } from "@/posthog/postHogConfig"
import { useTranslation } from "react-i18next"

export const ConsentBanner: React.FC = () => {
    const { setupPostHogWithConsent, setupPostHogWithoutConsent, hasOptedIn, hasOptedOut } = useSetupPostHog()
    // Determines the initial value of the allow analytical cookies option. If the user has not opted in or out,
    // the default value should be TRUE. Otherwise it should follow the opted in / out values as determined previously.
    const initialAllowAnalyticalCookies = hasOptedIn ? true : hasOptedOut ? false : true
    const [allowAnalyticalCookies, setAllowAnalyticalCookies] = useState(initialAllowAnalyticalCookies)
    const [readMoreActive, setIsReadMoreActive] = useState(false)
    const { t } = useTranslation()

    const onConfirmSelection = () => {
        if (allowAnalyticalCookies) {
            setupPostHogWithConsent()
        } else {
            setupPostHogWithoutConsent()
        }
    }

    // !-- IMPORTANT -> DON'T REMOVE PREVENT_POSTHOG_CAPTURE_EVENTS_CLASS -> This prevents posthog from capturing any events while users all still giving consent.
    return (
        <Modal open={true} setOpen={() => {}} className={PREVENT_POSTHOG_CAPTURE_EVENTS_CLASS}>
            <div className="w-[540px] p-10 text-left">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={readMoreActive ? "cookie info" : "cookie selection"}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        {readMoreActive === false && (
                            <CookieSelection
                                allowAnalyticalCookies={allowAnalyticalCookies}
                                setAllowAnalyticalCookies={setAllowAnalyticalCookies}
                            />
                        )}
                        {readMoreActive && <CookieInformation />}
                    </motion.div>
                </AnimatePresence>
            </div>

            <ModalFooter>
                <div className="flex justify-end items-center space-x-4">
                    {readMoreActive === false && (
                        <Fragment>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setIsReadMoreActive(true)
                                }}
                            >
                                {t("cookiebanner:buttons.learnMore")}
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    onConfirmSelection()
                                }}
                            >
                                {t("cookiebanner:buttons.allowSelection")}
                            </Button>
                        </Fragment>
                    )}

                    {readMoreActive === true && (
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsReadMoreActive(false)
                            }}
                        >
                            {t("cookiebanner:buttons.return")}
                        </Button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    )
}

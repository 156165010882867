import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { getUserOrganisationsEndpoint } from "../../../api/endpoints"
import { useAuth0 } from "@auth0/auth0-react"
import { getRequest } from "@/api/requests"
import { IAwarenessLanguage } from "@features/awareness/awarenessTypes"

export interface UserOrganisationsDatasource {
    data: EyeOrganisation[] | null
}

export interface AwarenessState {
    onboarding_step: number
    onboarded_at: string | null
    last_event_received_at: string | null
    in_contract: boolean
    recipient_source: "csv" | "CSV" | "AzureAD" | "None" | null
    show_reporting: boolean
    default_language: IAwarenessLanguage | null
    active: boolean
}

export type EyeOffice = "Eye Netherlands" | "Eye Belgium" | "Eye Germany"

export type MSSentinelStatus =
    | "AZURE_SUBSCRIPTION_REQUESTED"
    | "OPERATIONS_ONBOARDING_IN_PROGRESS"
    | "ONBOARDING_COMPLETED"
    | ""
    | null
    | undefined

export interface IAzureTenant {
    id: string
    name: string
    domains?: string[]
}

export interface EyeOrganisation {
    name: string
    eyed: string
    insured: boolean
    awareness: AwarenessState | null
    awareness_in_contract: boolean
    eye_office: EyeOffice | null
    onboarded: boolean
    show_chargebee_widget?: boolean
    show_ms_sentinel_onboarding: boolean
    ms_sentinel_status: MSSentinelStatus
    azure_tenants?: IAzureTenant[]
    azure_tenants_updated_at?: string | null
}

export const useUserOrganisations = (): UseQueryResult<UserOrganisationsDatasource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const userOrganisationsEndpoint = getUserOrganisationsEndpoint()

    return useQuery<UserOrganisationsDatasource, Error>({
        queryKey: userOrganisationsEndpoint.queryKey,
        queryFn: () => getRequest(userOrganisationsEndpoint.url, getAccessTokenSilently),
    })
}

import { createRoot } from "react-dom/client"
import { App } from "./App"
import "./i18next"
import "react-day-picker/dist/style.css"
import "./styles/index.css"
import { getApiBaseUrl, setupAxios } from "./config/apiConfig"
import { initialiseSentry } from "./config/sentryConfig"
import { getEnv } from "./config/envConfig"

console.log("[PORTAL] 🐧🐧🐧🐧🐧 Initialising Eye Portal 🐧🐧🐧🐧🐧")
console.log(`[PORTAL] Running Environment: [${import.meta.env.VITE_ENVIRONMENT_ID}]`)

async function enableMSWMocking() {
    if (import.meta.env.VITE_ENVIRONMENT_ID !== "development") {
        return
    }

    const { worker } = await import("./mocks/browser")

    worker.start({
        // turn off MSW warnings for specific routes
        onUnhandledRequest(req, print) {
            // specify routes to exclude
            const excludedRoutes = ["/src/assets/", "https://login.eye.security/oauth/token"]

            // Check if the url is included in the exclusion list
            const isExcluded = excludedRoutes.some((route) => req.url.includes(route))

            if (isExcluded) {
                return
            }

            print.warning()
        },
    })
}

function setupSentry() {
    if (import.meta.env.VITE_ENVIRONMENT_ID === "development") {
        console.log("[SENTRY] Skipping Sentry initialise, running in development")
    } else {
        const environment = getEnv()
        const baseApiUrl = getApiBaseUrl()
        console.log("[SENTRY] Initialising Sentry")
        // Function that initialises the whole sentry instance.
        initialiseSentry(environment, baseApiUrl)
    }
}

// Sets the api axios base url
setupAxios()
setupSentry()

const container = document.getElementById("app")
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

// Conditionally enable the MSW mocking funcionality and then render the dom.
enableMSWMocking().then(() => {
    root.render(<App />)
})

import React from "react"
export type Ref = HTMLInputElement

interface Props {
    iconLeft?: React.ReactNode
    iconRight?: React.ReactNode
}

const IconWrapper: React.FC<{ location: "left" | "right"; icon: React.ReactNode }> = ({ icon, location }) => {
    return (
        <div
            className={` absolute inset-y-0 flex items-center pr-3 z-10 ${location === "left" ? "left-4" : "right-2"}`}
        >
            {icon}
        </div>
    )
}

export const TextInput = React.forwardRef<
    Ref,
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & Props
>((props, ref) => {
    const { iconLeft, iconRight, className, ...rest } = props
    const iconPaddingClass =
        iconLeft && iconRight ? "pl-12 pr-12" : iconLeft ? "pl-12" : iconRight === "end" ? "pr-12" : ""

    return (
        <div className={`${className ?? ""} relative rounded-md `}>
            {iconLeft && <IconWrapper location="left" icon={iconLeft} />}

            <input
                ref={ref}
                {...rest}
                type="text"
                className={`${iconPaddingClass} block rounded-md border-1 border-border-primary-light  focus:border-transparent py-2.5 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-brand-eye-orange sm:leading-6 w-full`}
            />

            {iconRight && <IconWrapper location="right" icon={iconRight} />}
        </div>
    )
})

TextInput.displayName = "TextInput"

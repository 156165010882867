import React from "react"
import { MetricBlock, MetricBlockFooter, MetricBlockWrapper } from "../metricBlock/MetricBlock"
import { useAwarenessMetrics } from "@features/awareness/hooks/useAwarenessMetrics"
import { useAwarenessState } from "@features/awareness/hooks/useAwarenessState"
import { HistoricalEventsGraph } from "@features/awareness/overview/HistoricalEventsGraph"
import { Typography } from "@eyectrl/baseplate-design-system"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { Button } from "@eyectrl/baseplate-design-system"
import { ArrowLongRightIcon } from "@heroicons/react/24/solid"
interface Props {
    eyed: string
    showEventsGraph: boolean
}

export const AwarenessWidget: React.FC<Props> = ({ eyed, showEventsGraph }) => {
    const navigate = useNavigate()
    const { showAwarenessWidget } = useAwarenessState()
    const { data: metricsData, isPending, isError } = useAwarenessMetrics(eyed)
    const { t } = useTranslation()

    if (showAwarenessWidget === false) {
        return null
    }

    const metricsDataToUse = metricsData?.data ?? []
    // We only want to show the last 4 months in the metric
    const metricsDataToShow = metricsDataToUse.slice(0, 4)
    const lastMonthMetric = metricsDataToUse[0]
    const routerLinks = getRouterLinks()

    // The loading skeleton is being handles by the historical events graph
    if (showEventsGraph) {
        return (
            <MetricBlockWrapper testId="awareness-widget">
                <div>
                    {showEventsGraph && (
                        <HistoricalEventsGraph
                            size="small"
                            data={metricsDataToShow}
                            isPending={isPending}
                            isError={isError}
                            title={
                                <Typography variant="body-1-semibold">
                                    {t("dashboard.metrics.awareness.heading_graph")}
                                </Typography>
                            }
                        />
                    )}
                </div>
                <MetricBlockFooter>
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => {
                            navigate(getRouterLinks().awareness.root({ eyed }))
                        }}
                        rightContent={<ArrowLongRightIcon width={16} height={16} />}
                    >
                        {t(`dashboard.metrics.awareness.link`)}
                    </Button>
                </MetricBlockFooter>
            </MetricBlockWrapper>
        )
    }

    if (lastMonthMetric) {
        return (
            <MetricBlock
                title={t("dashboard.metrics.awareness.heading")}
                testId="awareness-widget"
                metrics={[
                    {
                        value: `${lastMonthMetric.sent} ${t("dashboard.metrics.awareness.subtitles.sent")}`,
                        subtitle: (
                            <div className="flex divide-x">
                                <Typography variant="body-2-semibold" color="text-warning" className="px-2">
                                    {`${lastMonthMetric.phished} ${t("dashboard.metrics.awareness.subtitles.phished")}`}
                                </Typography>

                                <Typography variant="body-2-semibold" color="text-secondary" className="px-2">
                                    {`${lastMonthMetric.reported} ${t("dashboard.metrics.awareness.subtitles.reported")}`}
                                </Typography>
                            </div>
                        ),
                    },
                ]}
                link={{
                    value: t("dashboard.metrics.awareness.link"),
                    onNavigate: () => {
                        navigate(routerLinks.awareness.overview({ eyed }))
                    },
                }}
                topRightIndicator={
                    <Typography variant="body-2" color="text-secondary">
                        {t("dashboard.metrics.awareness.lastMonth")}
                    </Typography>
                }
            />
        )
    }

    return null
}

import { useApplicationStore } from "@/stores/applicationStore"
import { usePostHog } from "posthog-js/react"
import React from "react"
import { useLocation } from "react-router-dom"
import { useCapturePostHogEvent } from "./useCapturePostHogEvent"

// Hook that listens to the routers location and triggers posthog pageview events.
export const usePostHogPageviewCapture = () => {
    const posthog = usePostHog()
    const location = useLocation()
    const isPostHogUserIdentified = useApplicationStore((state) => state.isUserIdentified)
    const { captureEvent } = useCapturePostHogEvent()

    // Effect to capture pageviews, posthog's autocapture does not handle this correctly, so we integrate it with the router
    React.useEffect(() => {
        // User hasn't opted in, so we can skip any pageviews
        if (posthog.has_opted_in_capturing() === false) {
            return
        }

        if (isPostHogUserIdentified) {
            captureEvent("$pageview")
        } else {
            console.log("[PORTAL] Not tracking pageview, waiting for identification...")
        }
    }, [location, posthog, isPostHogUserIdentified, captureEvent])
}

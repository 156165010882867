import { patchUserProfileEndpoint } from "@/api/endpoints"
import { patchRequest } from "@/api/requests"
import { useMutation } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { IUserProfile } from "./types"

type IUserProfileUpdate = Partial<IUserProfile>

export const usePatchUserProfile = () => {
    const { getAccessTokenSilently } = useAuth0()

    return useMutation({
        mutationFn: (variables: { eyed: string; update: IUserProfileUpdate }) => {
            const endpoint = patchUserProfileEndpoint(variables.eyed)

            return patchRequest<IUserProfileUpdate, { data: boolean }>(
                endpoint.url,
                variables.update,
                getAccessTokenSilently,
            )
        },
    })
}

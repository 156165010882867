import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { IDetectionV2Statistics } from "../types"
import { useAuth0 } from "@auth0/auth0-react"
import { getDetectionsV2StatisticsEndpoint } from "@/api/endpoints"
import { getRequest } from "@/api/requests"

interface DetectionsStatisticsDataSource {
    data: IDetectionV2Statistics | null
    meta_data?: {
        last_key: string
        last_updated: string
    } | null
}

export const useDetectionsV2Statistics = (eyed: string): UseQueryResult<DetectionsStatisticsDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()

    const detectionsEndpoint = getDetectionsV2StatisticsEndpoint(eyed)

    return useQuery<DetectionsStatisticsDataSource, Error>({
        queryKey: detectionsEndpoint.queryKey,
        queryFn: () => getRequest(detectionsEndpoint.url, getAccessTokenSilently),
    })
}

import React, { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { AxiosError } from "axios"
import { ErrorPageMessage } from "@components/errorPageMessage/ErrorPageMessage"

interface Props {
    error: Error
}

const ErrorPageWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="bg-brand-eye-blue border-t border-blue-800 w-full h-full overflow-auto flex justify-center items-center flex-col">
            {children}
        </div>
    )
}

export const ErrorPage: React.FC<Props> = ({ error }) => {
    const { t } = useTranslation(["common", "navigation"])
    const isNetworkError = error instanceof AxiosError

    if (isNetworkError && error.response?.status === 403) {
        return (
            <ErrorPageWrapper>
                <ErrorPageMessage title={t("errorPage.title")} description={t("errorPage.403.message")} />
            </ErrorPageWrapper>
        )
    }

    return (
        <ErrorPageWrapper>
            <ErrorPageMessage title={t("errorPage.title")} description={t("errorPage.description")} />
        </ErrorPageWrapper>
    )
}

import { Pagination } from "@designSystem/pagination/Pagination"
import { PaginationBar } from "@designSystem/pagination/PaginationBar"
import { PAGE_OPTION_ALL, PAGE_OPTION_LARGE, PAGE_OPTION_SMALL } from "@hooks/usePaginationState"
import { Table } from "@tanstack/react-table"
import { PageOption, getPagination } from "@utils/paginationUtils"
import { PropsWithChildren } from "react"

interface Props<T> extends PropsWithChildren {
    table: Table<T>
    isRounded?: boolean
    data: Array<T>
    isVisible?: boolean
    hasBorder?: boolean
    className?: string
}

const getSelectedOption = (size: number): PageOption => {
    if (size === PAGE_OPTION_SMALL) {
        return PAGE_OPTION_SMALL
    }

    if (size === PAGE_OPTION_LARGE) {
        return PAGE_OPTION_LARGE
    }

    return PAGE_OPTION_ALL
}

export function TablePaginationWrapper<T>(props: Props<T>): JSX.Element {
    const { table, isRounded = true, children, data, isVisible = true, hasBorder = true, className = "" } = props

    if (!isVisible || data.length <= PAGE_OPTION_SMALL) {
        return (
            <div className={`${className} ${hasBorder ? "border" : ""} ${isRounded ? " rounded-lg" : ""}`}>
                {children}
            </div>
        )
    }

    return (
        <div className={`${className} ${hasBorder ? "border" : ""} ${isRounded ? " rounded-lg" : ""}`}>
            {children}
            <PaginationBar
                onSelectOption={(size) => {
                    if (size === "ALL") {
                        table.setPageSize(data.length)
                    } else {
                        table.setPageSize(size)
                    }
                }}
                options={[PAGE_OPTION_SMALL, PAGE_OPTION_LARGE, PAGE_OPTION_ALL]}
                selectedOption={getSelectedOption(table.getState().pagination.pageSize)}
            >
                {/* The pagination component itself is 1 indexed ( for usability purposes ) */}
                {/* The table pagination is 0 indexed, so we need to make some conversions here */}
                <Pagination
                    currentPage={table.getState().pagination.pageIndex + 1}
                    dataSize={data.length ?? 0}
                    goToNextPage={table.nextPage}
                    goToPreviousPage={table.previousPage}
                    goToSpecificPage={(page) => {
                        // Offset by -1 since the table pagination is 0 indexed
                        table.setPageIndex(page - 1)
                    }}
                    numberOfPages={table.getPageCount()}
                    pageSize={table.getState().pagination.pageSize}
                    pagination={getPagination(
                        data.length,
                        table.getState().pagination.pageSize,
                        table.getState().pagination.pageIndex + 1,
                    )}
                />
            </PaginationBar>
        </div>
    )
}

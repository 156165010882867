import Tippy from "@tippyjs/react"
import { buildLocalISODate, getDeconstructedISODate, isDateBeforeEyeBirthday, isDateInFuture } from "@utils/dateUtils"
import { isNullish } from "@utils/formatUtils"
import classNames from "classnames"
import { t } from "i18next"
import React, { useEffect, useRef, useState } from "react"

type DateSection = "day" | "month" | "year"
type DateMaskValue = number | ""
type DateMaskState = { day: DateMaskValue; month: DateMaskValue; year: DateMaskValue }

const MAX_DAYS = 31
const MAX_MONTHS = 12
const MAX_YEARS = 9999

const inputStyles = "border-none outline-none focus:bg-orange-200 focus:ring-0 p-2 rounded-md text-center"

const DateTooltip: React.FC<{ active: DateSection; validityMessage?: string }> = ({ active, validityMessage }) => {
    if (validityMessage) {
        return <div className="bg-brand-eye-blue py-1 px-2 rounded-md text-invert">{validityMessage}</div>
    }

    return (
        <div className="bg-brand-eye-blue py-1 px-2 rounded-md text-text-primary-dark">
            <div>
                <span className={classNames({ "text-invert": active === "day" })}>DD</span>
                <span>/</span>
                <span className={classNames({ "text-invert": active === "month" })}>MM</span>
                <span>/</span>
                <span className={classNames({ "text-invert": active === "year" })}>YYYY</span>
            </div>
        </div>
    )
}

const parseStateToDate = (state: DateMaskState) => {
    if (typeof state.day === "number" && typeof state.month === "number" && typeof state.year === "number") {
        return buildLocalISODate(state.year, state.month, state.day)
    }
}

interface Props {
    currentTimestamp: string | undefined
    onChange: (timestamp: string) => void
    withFocus?: boolean // If the dateinput mask is opened, this prop makes sure the first input will get focus.
}

export const DateInputMask: React.FC<Props> = ({ currentTimestamp, onChange, withFocus }) => {
    const initialDate = currentTimestamp ? getDeconstructedISODate(currentTimestamp) : null
    const dayFieldRef = useRef<HTMLInputElement>(null)

    // Effect which focusses the first input when the user opens the date range picker.
    // This will only focus if the withFocus prop is set to true
    useEffect(() => {
        if (dayFieldRef.current && withFocus) {
            dayFieldRef.current.focus()
        }
    }, [withFocus])

    const [dateSelection, setDateSelection] = useState<DateMaskState>({
        day: initialDate?.day ?? "",
        month: initialDate?.month ?? "",
        year: initialDate?.year ?? "",
    })

    // Validity Checks
    const parsedDateSelection = parseStateToDate(dateSelection)
    const isInFuture = parsedDateSelection ? isDateInFuture(parsedDateSelection) : false
    const isBeforeEyeBirthday = parsedDateSelection ? isDateBeforeEyeBirthday(parsedDateSelection) : false
    const isValid = !isNullish(parsedDateSelection) && isInFuture === false && isBeforeEyeBirthday === false
    const dateValidityMessage = isValid
        ? undefined
        : isInFuture
          ? t("common:dateRangePicker.dateInFuture")
          : isBeforeEyeBirthday
            ? t("common:dateRangePicker.dateInPast")
            : t("common:dateRangePicker.invalidDate")

    // Effect that updates the state when a new currentTimestamp is being provided
    // Happens when the date is altered by the user via the calendar input
    useEffect(() => {
        if (!isNullish(currentTimestamp)) {
            const deconstructedDate = getDeconstructedISODate(currentTimestamp)
            if (deconstructedDate.day && deconstructedDate.month && deconstructedDate.year) {
                setDateSelection({
                    day: deconstructedDate?.day ?? "",
                    month: deconstructedDate?.month ?? "",
                    year: deconstructedDate?.year ?? "",
                })
            }
        } else {
            setDateSelection({
                day: "",
                month: "",
                year: "",
            })
        }
    }, [currentTimestamp])

    // Onchange handler of each input field
    // Syncs the values to the state and triggers the update to the parent component
    const onChangeDateField = (event: React.ChangeEvent<HTMLInputElement>, field: DateSection) => {
        const number = Number(event.target.value)

        if (isNaN(number) || number === 0) {
            if (field === "day") setDateSelection({ ...dateSelection, day: "" })
            if (field === "month") setDateSelection({ ...dateSelection, month: "" })
            if (field === "year") setDateSelection({ ...dateSelection, year: "" })

            // prevent default, prevents the form input field from syncing incorrectly.
            event.preventDefault()
            return
        }

        if (field === "day" && number <= MAX_DAYS) {
            setDateSelection({ ...dateSelection, day: number })
            updateValidTimestamp({ ...dateSelection, day: number })
        }

        if (field === "month" && number <= MAX_MONTHS) {
            setDateSelection({ ...dateSelection, month: number })
            updateValidTimestamp({ ...dateSelection, month: number })
        }

        if (field === "year" && number <= MAX_YEARS) {
            setDateSelection({ ...dateSelection, year: number })
            updateValidTimestamp({ ...dateSelection, year: number })
        }
    }

    // Updates the parent component through the onChange method if the parsed date is present.
    const updateValidTimestamp = (state: DateMaskState) => {
        if (typeof state.day === "number" && typeof state.month === "number" && typeof state.year === "number") {
            const ISOTimestamp = buildLocalISODate(state.year, state.month, state.day)
            if (ISOTimestamp) {
                onChange(ISOTimestamp)
            }
        }
    }

    // If the user focusses the input field, makes sure the entire content is selected
    // This makes it easier to edit the selected content.
    const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => event.target.select()

    return (
        <div
            className={classNames(
                { "border border-red-500": isValid === false },
                { "border border-neutral-500": isValid === true },
                "p-1 bg-white flex items-center space-x-2 rounded-md ",
            )}
        >
            <Tippy
                content={<DateTooltip active="day" validityMessage={dateValidityMessage} />}
                placement={"bottom"}
                className="rangepicker-tippy"
            >
                <input
                    name="day"
                    type="text"
                    className={`w-8 h-8 ${inputStyles}`}
                    value={dateSelection.day}
                    onFocus={handleFocus}
                    autoComplete="off"
                    maxLength={2}
                    onChange={(event) => {
                        onChangeDateField(event, "day")
                    }}
                    ref={dayFieldRef}
                />
            </Tippy>

            <div>/</div>

            <Tippy
                content={<DateTooltip active="month" validityMessage={dateValidityMessage} />}
                placement={"bottom"}
                className="rangepicker-tippy"
            >
                <input
                    name="month"
                    type="text"
                    className={`w-8 h-8 ${inputStyles}`}
                    value={dateSelection.month}
                    onFocus={handleFocus}
                    autoComplete="off"
                    maxLength={2}
                    onChange={(event) => {
                        onChangeDateField(event, "month")
                    }}
                />
            </Tippy>

            <div>/</div>

            <Tippy
                content={<DateTooltip active="year" validityMessage={dateValidityMessage} />}
                placement={"bottom"}
                className="rangepicker-tippy"
            >
                <input
                    name="year"
                    type="text"
                    onFocus={handleFocus}
                    className={`w-12 h-8 ${inputStyles}`}
                    value={dateSelection.year}
                    autoComplete="off"
                    maxLength={4}
                    onChange={(event) => {
                        onChangeDateField(event, "year")
                    }}
                />
            </Tippy>
        </div>
    )
}

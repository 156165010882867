import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import { Typography } from "@eyectrl/baseplate-design-system"
import { IAwarenessMetric } from "@features/awareness/awarenessTypes"
import { useAwarenessMetrics } from "@features/awareness/hooks/useAwarenessMetrics"
import { useAwarenessState } from "@features/awareness/hooks/useAwarenessState"
import { EventsTableSection } from "@features/awareness/overview/EventsTableSection"
import { HistoricalEventsGraph } from "@features/awareness/overview/HistoricalEventsGraph"
import { MonthlyMetrics } from "@features/awareness/overview/MonthlyMetrics"
import { useActiveEyed } from "@hooks/useActiveEyed"
import React, { useEffect, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { AwarenessWaitingForResults } from "./AwarenessWaitingForResults"
import { Navigate, generatePath } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { TimeAgoDate } from "@components/dates/TimeAgoDate"

export const AwarenessOverviewPage: React.FC = () => {
    const { t } = useTranslation()
    const activeEyed = useActiveEyed()
    const { data: metricsData, isPending, isError } = useAwarenessMetrics(activeEyed)
    const { showAwarenessReporting, hasAwareness, hasFinishedAwarenessOnboarding } = useAwarenessState()
    const [eventsLastUpdated, setEventsLastUpdated] = useState<string>()
    const metricsLastUpdated = metricsData?.meta_data?.last_updated

    // Contains the selected month in year-month format (2023-12)
    const [selectedMonth, setSelectedMonth] = useState<string | undefined>()

    // Contains all the month months in the metricsData call.
    const months: string[] = useMemo(() => {
        return metricsData?.data ? metricsData.data.map((metric) => metric.period) : []
    }, [metricsData])

    const selectedMonthMetricsData: IAwarenessMetric | undefined = metricsData?.data
        ? metricsData.data.find((metric) => metric.period === selectedMonth)
        : undefined

    // Effect that sets the initial month once the months data has changed.
    useEffect(() => {
        if (months && selectedMonth === undefined) {
            setSelectedMonth(months[0])
        }
    }, [months, selectedMonth])

    // Only show the graph if the data is still loading OR we have more than 3 months worth of data.
    const showEventsGraph =
        isPending === true || isError === true || (metricsData && metricsData.data && metricsData.data.length >= 3)

    // Redirect the user back to the awareness landing page if they don't have access to the overview.
    if (hasAwareness === false || hasFinishedAwarenessOnboarding === false) {
        return <Navigate to={generatePath(getRouterLinks().awareness.root({ eyed: activeEyed }))} />
    }

    // Show the waiting for results page if the user has access to awareness, but it still waiting for data.
    if (showAwarenessReporting === false) {
        return <AwarenessWaitingForResults />
    }

    return (
        <PageLayout variant="detailPage">
            <PageHeader title={t("awareness.overview.title")} />

            <Typography>
                <Trans i18nKey="pages:awareness.overview.description" components={[]} />
            </Typography>

            <div className="flex items-center gap-2 mt-4">
                {metricsLastUpdated && (
                    <div className="flex items-center divide-x *:px-2 -ml-2">
                        <Typography>{t("pages:awareness.overview.metrics")}</Typography>
                        <TimeAgoDate
                            prefix={t("details.timestamp_updated")}
                            timestamp={metricsLastUpdated}
                            testId="awareness-events-updated-since"
                        />
                    </div>
                )}

                {eventsLastUpdated && (
                    <div className="flex items-center divide-x *:px-2">
                        <Typography>{t("pages:awareness.overview.mailEvents")}</Typography>
                        <TimeAgoDate
                            prefix={t("details.timestamp_updated")}
                            timestamp={eventsLastUpdated}
                            testId="awareness-events-updated-since"
                        />
                    </div>
                )}
            </div>

            <div className="space-y-12">
                {showEventsGraph && (
                    <section className="w-full border rounded-md mt-8">
                        <HistoricalEventsGraph data={metricsData?.data} isPending={isPending} isError={isError} />
                    </section>
                )}

                <section className="w-full mt-8" data-testid="awareness-metrics">
                    {/* Monthly metrics only show the data for the selected month ( dropdown ) */}
                    <MonthlyMetrics
                        months={months}
                        selectedMonth={selectedMonth}
                        data={selectedMonthMetricsData}
                        isPending={isPending}
                        setSelectedMonth={setSelectedMonth}
                    />
                </section>

                {selectedMonth && (
                    <section>
                        {/* Shows the events data, based on the selected month ( dropdown ) */}
                        <EventsTableSection
                            activeEyed={activeEyed}
                            selectedMonth={selectedMonth}
                            setEventsLastUpdated={setEventsLastUpdated}
                        />
                    </section>
                )}
            </div>
        </PageLayout>
    )
}

import { localStorageSettings } from "../localStorage/localStorage"
import { EyeOrganisation } from "../features/accountSwitcher/hooks/useUserOrganisations"

export type EyeOrganistionPlan = "Cyber Guard" | "Cyber Guard Plus" | "Cyber Guard Insured"

const getOrganisationPlan = (organisation: EyeOrganisation): EyeOrganistionPlan => {
    if (organisation.insured) {
        return "Cyber Guard Insured"
    }

    if (organisation.awareness) {
        return "Cyber Guard Plus"
    }

    return "Cyber Guard"
}

const checkIfEyedExistsInOrganisationsData = (
    eyed: string,
    organisationsData: EyeOrganisation[] | undefined,
): boolean => {
    return organisationsData?.some((organisation) => organisation.eyed === eyed) ? true : false
}

const determineInitialOrganisationEyed = (organisationsData: EyeOrganisation[] | undefined): string | null => {
    const lastActiveEyed = localStorageSettings.getActiveEyed()

    // If there's a lastActiveEyed ( from account switching )
    // AND the lastActiveEyed is present in the users organisations
    // Set this as the initial eyed.
    if (
        lastActiveEyed &&
        organisationsData &&
        organisationsData.some((organistion) => organistion.eyed === lastActiveEyed)
    ) {
        return lastActiveEyed
    }

    // If no activeEyed is present in localstorage we can set the initial active eyed as the first eyed in the organisations data
    if (organisationsData && organisationsData[0]) {
        return organisationsData[0].eyed
    }

    return null
}

export const organisationUtils = {
    determineInitialOrganisationEyed,
    getOrganisationPlan,
    checkIfEyedExistsInOrganisationsData,
}

import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { IThreatHuntWithAssets } from "../threatHuntingTypes"
import { useAuth0 } from "@auth0/auth0-react"
import { getOrganisationThreatHuntsEndpoint } from "@/api/endpoints"
import { getRequest } from "@/api/requests"

export interface ThreatHuntsDataSource {
    data: IThreatHuntWithAssets[]
}

export const useThreatHuntsWithAssets = (eyed: string): UseQueryResult<ThreatHuntsDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()

    const threatHuntsEndpoint = getOrganisationThreatHuntsEndpoint(eyed)

    return useQuery<ThreatHuntsDataSource, Error>({
        queryKey: threatHuntsEndpoint.queryKey,
        queryFn: () => getRequest(threatHuntsEndpoint.url, getAccessTokenSilently),
    })
}

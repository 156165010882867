import { PageLayout } from "../../../layout/PageLayout"
import { Skeleton } from "./Skeleton"

export const RecommendationDetailSkeleton: React.FunctionComponent = () => {
    return (
        <PageLayout variant="detailPage">
            <div className="flex gap-4 mb-6">
                <Skeleton className="w-[50px] h-[25px]" />
                <Skeleton className="w-[50px] h-[25px]" />
                <Skeleton className="w-[50px] h-[25px]" />
            </div>

            <div className="mb-8">
                <Skeleton className="w-[300px] h-[34px] " />
            </div>

            <div className="flex flex-col gap-6 ">
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
            </div>
        </PageLayout>
    )
}

export const isNullish = (value: unknown): value is undefined | null => {
    return value === undefined || value === null
}

export const formatPercentageValue = (
    value: number | undefined | null,
    fallbackValue: string | undefined = "",
): string => {
    if (isNullish(value)) {
        return fallbackValue
    }

    const roundedDownAndFloored = Math.floor(value * 10) / 10

    return `${roundedDownAndFloored}%`
}

export const formatNullishValue = (
    value: number | string | undefined | null,
    fallbackValue: string | undefined = "",
): string | number => {
    if (isNullish(value)) {
        return fallbackValue
    }

    return value
}

export const lowerCaseValue = (value: string | null | undefined): string => {
    if (isNullish(value)) {
        return ""
    }

    return value.toLowerCase()
}

// Checks the string contents, if it's empty or nullish we show the fallback value.
export const showWithFallbackValue = (
    value: string | undefined | boolean | number | null,
    fallbackValue: string = "-",
) => {
    if (isNullish(value)) {
        return fallbackValue
    }

    if (typeof value === "number") {
        return value.toString()
    }

    if (typeof value === "boolean") {
        return `${value}`
    }

    if (value.trim() === "") {
        return fallbackValue
    }

    return value
}

import React, { Fragment, FunctionComponent, PropsWithChildren } from "react"
import { FeedbackLabel } from "../feedback/FeedbackLabel"
import { Transition } from "@headlessui/react"
interface Props {
    className?: string
    children: React.ReactNode
    footer?: React.ReactNode
    variant: "detailPage" | "dashboard" | "emptyPage"
    background?: "white" | "dimmed"
}

export const PageLayout: FunctionComponent<Props> = ({
    children,
    className,
    variant,
    background = "white",
    footer,
}) => {
    const backgroundClass = background === "white" ? "bg-white" : "bg-background-page-secondary-light"

    if (variant === "dashboard") {
        return (
            <div className={`relative flex flex-col flex-1 items-center w-full ${className ?? ""}`}>
                <FeedbackLabel />

                {/* The pr ( padding right ) is in place to make some space for the feedback label */}
                <div className={`flex flex-col flex-1 pr-8 eye-page-width`}>{children}</div>
                {footer}
            </div>
        )
    }

    if (variant === "detailPage") {
        return (
            <Fragment>
                <div
                    className={`relative flex flex-col w-full overflow-x-hidden flex-1 items-center ${backgroundClass} ${
                        className ?? ""
                    }`}
                >
                    <FeedbackLabel />

                    {/* The pr ( padding right ) is in place to make some space for the feedback label */}
                    <div className={`detail-page-container`}>{children}</div>

                    {footer}
                </div>
            </Fragment>
        )
    }

    return (
        <div
            className={`relative h-full overflow-y-hidden overflow-x-hidden w-full ${backgroundClass}  ${className ?? ""}`}
        >
            <FeedbackLabel />

            {/* The pr ( padding right ) is in place to make some space for the feedback label */}
            <div className={`h-full ${backgroundClass}`}>{children}</div>
            {footer}
        </div>
    )
}

export const DetailPageLayout: React.FC<
    PropsWithChildren<{
        backgroundClass?: string
        className?: string
        footer?: React.ReactNode
        sidebarContent?: React.ReactNode
        sidebarContentActive?: boolean
        sideLabel?: React.ReactNode
    }>
> = ({ backgroundClass, className, children, footer, sidebarContent, sideLabel, sidebarContentActive = true }) => {
    return (
        <Fragment>
            <div
                className={`relative flex flex-col w-full overflow-x-hidden flex-1 items-center ${backgroundClass} ${
                    className ?? ""
                }`}
            >
                {sideLabel}

                <FeedbackLabel />

                {/* The pr ( padding right ) is in place to make some space for the feedback label */}
                <div className={`detail-page-container`}>{children}</div>

                {footer}
            </div>
            {sidebarContent && (
                <Transition show={sidebarContentActive}>
                    <div className="absolute top-0 right-0 bottom-0 2xl:relative w-[400px] z-50 bg-white shadow-lg overflow-auto">
                        {sidebarContent}
                    </div>
                </Transition>
            )}
        </Fragment>
    )
}

import React from "react"
import { CardSkeleton } from "./CardSkeleton"

export const CardListSkeleton: React.FC = () => {
    return (
        <div className="space-y-4">
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
        </div>
    )
}

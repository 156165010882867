import { PageLayout } from "@components/layout/PageLayout"
import { SentinelOnboardingChecklist } from "@features/sentinelOnboarding/SentinelOnboardingChecklist"
import { useOnboardingState } from "@hooks/useOnboardingState"
import { SentinelOnboardingInProgress } from "@features/sentinelOnboarding/SentinelOnboardingInProgress"
import React, { useState } from "react"
import { IOnboardingState } from "@utils/onboardingActionListUtils"
import { SentinelOnboardingCompleted } from "@features/sentinelOnboarding/SentinelOnboardingCompleted"
import { Navigate, generatePath } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { useActiveEyed } from "@hooks/useActiveEyed"

export const SentinelOnboardingPage: React.FC = () => {
    const { onboardingState } = useOnboardingState()
    const activeEyed = useActiveEyed()
    const [activeOnboardingStep, setActiveOnboardingState] = useState<IOnboardingState["sentinelOnboarding"]>(
        onboardingState.sentinelOnboarding,
    )

    if (onboardingState.sentinelOnboarding === "ONBOARDING_NOT_APPLICABLE") {
        return <Navigate to={generatePath(getRouterLinks().dashboard({ eyed: activeEyed }))} />
    }

    return (
        <PageLayout variant="detailPage">
            {activeOnboardingStep === "AZURE_SUBSCRIPTION_REQUESTED" && (
                <SentinelOnboardingChecklist setActiveOnboardingState={setActiveOnboardingState} />
            )}

            {activeOnboardingStep === "OPERATIONS_ONBOARDING_IN_PROGRESS" && <SentinelOnboardingInProgress />}

            {activeOnboardingStep === "ONBOARDING_COMPLETED" && <SentinelOnboardingCompleted />}
        </PageLayout>
    )
}

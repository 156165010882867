import React, { PropsWithChildren } from "react"

// Emoji's are available on:
// https://openmoji.org/

import RATING_1 from "@assets/rating/color/1F622.svg?react"
import RATING_2 from "@assets/rating/color/1F612.svg?react"
import RATING_3 from "@assets/rating/color/1F610.svg?react"
import RATING_4 from "@assets/rating/color/1F642.svg?react"
import RATING_5 from "@assets/rating/color/1F600.svg?react"

import RATING_1_GRAY from "@assets/rating/grayscale/1F622.svg?react"
import RATING_2_GRAY from "@assets/rating/grayscale/1F612.svg?react"
import RATING_3_GRAY from "@assets/rating/grayscale/1F610.svg?react"
import RATING_4_GRAY from "@assets/rating/grayscale/1F642.svg?react"
import RATING_5_GRAY from "@assets/rating/grayscale/1F600.svg?react"

export type AppRating = 1 | 2 | 3 | 4 | 5

interface Props {
    rating: AppRating
    setRating: (rating: AppRating) => void
}

interface RatingItemProps {
    isActive: boolean
}

const RatingItem: React.FC<PropsWithChildren<RatingItemProps>> = ({ isActive, children }) => {
    return (
        <div
            className={`h-[50px] w-[50px] rounded-full flex justify-center items-center hover:bg-yellow-100 ${
                isActive ? "bg-yellow-100" : ""
            }`}
        >
            {children}
        </div>
    )
}

export const RatingInput: React.FC<Props> = ({ rating, setRating }) => {
    const ratingClass = "h-[40px] cursor-pointer"
    return (
        <div className="flex w-full justify-between">
            <RatingItem isActive={rating === 1}>
                {rating === 1 ? (
                    <RATING_1 className={ratingClass} onClick={() => setRating(1)} />
                ) : (
                    <RATING_1_GRAY className={ratingClass} onClick={() => setRating(1)} />
                )}
            </RatingItem>

            <RatingItem isActive={rating === 2}>
                {rating === 2 ? (
                    <RATING_2 className={ratingClass} onClick={() => setRating(2)} />
                ) : (
                    <RATING_2_GRAY className={ratingClass} onClick={() => setRating(2)} />
                )}
            </RatingItem>

            <RatingItem isActive={rating === 3}>
                {rating === 3 ? (
                    <RATING_3 className={ratingClass} onClick={() => setRating(3)} />
                ) : (
                    <RATING_3_GRAY className={ratingClass} onClick={() => setRating(3)} />
                )}
            </RatingItem>

            <RatingItem isActive={rating === 4}>
                {rating === 4 ? (
                    <RATING_4 className={ratingClass} onClick={() => setRating(4)} />
                ) : (
                    <RATING_4_GRAY className={ratingClass} onClick={() => setRating(4)} />
                )}
            </RatingItem>

            <RatingItem isActive={rating === 5}>
                {rating === 5 ? (
                    <RATING_5 className={ratingClass} onClick={() => setRating(5)} />
                ) : (
                    <RATING_5_GRAY className={ratingClass} onClick={() => setRating(5)} />
                )}
            </RatingItem>
        </div>
    )
}

import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { getUserSettingsEndpoint } from "../../../api/endpoints"
import { useAuth0 } from "@auth0/auth0-react"
import { getRequest } from "@/api/requests"

export interface UserSettingsDatasource {
    data: {
        settings?: UserSetting[] | null
    }
}
export interface UserSetting {
    eyed: string
    notification_interval: "weekly" | "monthly" | "yearly" | "none"
}

export const useUserSettings = (): UseQueryResult<UserSettingsDatasource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const userSettingsEndpoint = getUserSettingsEndpoint()

    return useQuery<UserSettingsDatasource, Error>({
        queryKey: userSettingsEndpoint.queryKey,
        queryFn: () => getRequest(userSettingsEndpoint.url, getAccessTokenSilently),
    })
}

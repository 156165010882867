import { getUserOrganisationsEndpoint, postStartSentinelOnboarding } from "@/api/endpoints"
import { postRequest } from "@/api/requests"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"

export const usePostSentinelStatus = () => {
    const { getAccessTokenSilently } = useAuth0()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (variables: { eyed: string }) => {
            const endpoint = postStartSentinelOnboarding(variables.eyed)

            return postRequest<undefined, { data: string }>(endpoint.url, undefined, getAccessTokenSilently)
        },
        onSuccess: () => {
            // Makes the organisations endpoint refetch after updating the sentinel state
            queryClient.invalidateQueries({ queryKey: getUserOrganisationsEndpoint().queryKey })
        },
    })
}

import React from "react"
import { useSetupPostHog } from "./useSetupPostHog"
import {
    POSTHOG_STATUS_CONSENT,
    POSTHOG_STATUS_NON_CONSENT,
    POSTHOG_STATUS_REQUESTING_CONSENT,
    POSTHOG_STATUS_TAG,
} from "./postHogConfig"

// Inserts a hidden div with the current posthog status ( for debugging / tests )
export const PostHogStatus: React.FC = () => {
    const { hasOptedIn, hasOptedOut } = useSetupPostHog()

    const getPostHogStatus = () => {
        if (hasOptedIn) {
            return POSTHOG_STATUS_CONSENT
        }

        if (hasOptedOut) {
            return POSTHOG_STATUS_NON_CONSENT
        }

        return POSTHOG_STATUS_REQUESTING_CONSENT
    }

    // Renders a hidden component with the current posthog status
    return (
        <div className="hidden" data-testid={POSTHOG_STATUS_TAG}>
            {getPostHogStatus()}
        </div>
    )
}

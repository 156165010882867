import { Button } from "@eyectrl/baseplate-design-system"
import { Typography } from "@eyectrl/baseplate-design-system"
import React, { useCallback, useEffect } from "react"
import toast from "react-hot-toast"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "react-i18next"
import { toastService } from "@/services/toastService"

const VERSION_CHECK_INTERVAL = 1000 * 60 * 5 // 5 minute(s)
const META_TAG_SELECTOR = 'meta[name="frontend-version"]'

// Component that periodically checks if there's a new version of the FE available.
export const VersionChecker: React.FC = () => {
    const { t: translate } = useTranslation()

    const showNewVersionMessage = React.useCallback(() => {
        toastService.customMessage(
            (t) => {
                return (
                    <div className="flex items-center">
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-1">
                                <div className="flex justify-between">
                                    <Typography variant="body-1-semibold">
                                        {translate("versionChecker:title")}
                                    </Typography>
                                    <button
                                        onClick={() => {
                                            toast.dismiss(t.id)
                                        }}
                                    >
                                        <XMarkIcon className="h-5 w-5 text-text-inactive-light hover:text-text-inactive-dark" />
                                    </button>
                                </div>
                                <Typography variant="body-2">{translate("versionChecker:description")}</Typography>
                            </div>

                            <div className="flex gap-2">
                                <Button
                                    variant="primary"
                                    size="small"
                                    onClick={() => {
                                        toast.dismiss(t.id)
                                        window.location.reload()
                                    }}
                                >
                                    {translate("versionChecker:button")}
                                </Button>
                            </div>
                        </div>
                    </div>
                )
            },
            { id: "version-checker", duration: Infinity },
        )
    }, [translate])

    const checkVersion = useCallback(async () => {
        try {
            // Fetch the version.json file from the server
            const serverResponse = await fetch(`/version.json`)
            const serverVersion = await serverResponse.json()

            // Look up the current version which is set in the html meta tags.
            const metaVersionTag: HTMLMetaElement | null = document.querySelector(META_TAG_SELECTOR)
            const localVersion = metaVersionTag?.content

            // Compare versions
            if (localVersion !== serverVersion.version) {
                console.log(`[PORTAL] New version available: ${localVersion} -> ${serverVersion.version}`)
                showNewVersionMessage()
                return serverVersion.version
            } else {
                console.log(`[PORTAL] Using latest version: ${localVersion}`)
                return null
            }
        } catch (error) {
            console.error("Error checking app version:", error)
            return null
        }
    }, [showNewVersionMessage])

    // Effect that sets up a interval for checking the current version
    useEffect(() => {
        console.log("[PORTAL] Running version checker.")
        const fetchVersion = async () => {
            await checkVersion()
        }

        fetchVersion()

        const interval = setInterval(fetchVersion, VERSION_CHECK_INTERVAL)
        return () => clearInterval(interval)
    }, [checkVersion])

    return <></>
}

import { createColumnHelper } from "@tanstack/react-table"
import { Typography } from "@eyectrl/baseplate-design-system"
import { DATE_FORMATS, formatDate, formatToUTC, isDateValid } from "@utils/dateUtils"
import { isNullish } from "@utils/formatUtils"
import { WhiteListedAsset } from "@features/recommendations/recommendationTypes"

// Creates columns based on the asset columns that are passed.
// We don't know a pre-defined list of columns so everything works by a generic record type.
export const getRecommendationAssetTableColumns = (assetColumns: string[]) => {
    const columnHelper = createColumnHelper<Record<string, string> | null>()

    return assetColumns.map((assetColumn) => {
        return columnHelper.accessor(assetColumn, {
            size: 200,
            header: assetColumn,
            cell: (info) => {
                const TIME_FIELD: WhiteListedAsset = "Time (UTC)"
                const LAST_SCANNED_FIELD: WhiteListedAsset = "Last scanned"
                const DATE_FIELD: WhiteListedAsset = "Date"

                const isUTCTimestamp = assetColumn === LAST_SCANNED_FIELD || assetColumn === TIME_FIELD

                // Check if the value is a valid date
                // Just to be sure, we also check if the column includes the time to prevent date parsing in columns we don't want.
                // The value could be a number, which in turn would be a valid timestamp.
                // This UTC field is formatted and parsed as UTC to prevent confusion with the UTC in the header.S
                if (isUTCTimestamp && isDateValid(info.getValue())) {
                    return <Typography>{formatToUTC(info.getValue())}</Typography>
                }

                // The date field is parsed as a date and shown in the users local time
                if (assetColumn === DATE_FIELD && isDateValid(info.getValue())) {
                    return <Typography>{formatDate(info.getValue(), DATE_FORMATS.DATE_TIME_FORMAT)}</Typography>
                }

                const isEmptyValue = info.getValue() === "" || isNullish(info.getValue())

                return <Typography>{isEmptyValue ? "-" : info.getValue()}</Typography>
            },
            enableHiding: true,
        })
    })
}

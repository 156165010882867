import * as Sentry from "@sentry/react"
import { Env } from "./envConfig"
import React from "react"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"

export const initialiseSentry = (environment: Env, baseApiUrl: string) => {
    Sentry.init({
        environment: environment,
        dsn: "https://eeaa6459f9461286a28f2f17d4ea4ca1@o4507305848799232.ingest.de.sentry.io/4507418793017424",
        tunnel: "https://errorproxy.portal.eye.security/",
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
                // Custom worker as suggested by sentry to work around CSP self
                // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#using-a-custom-compression-worker
                workerUrl: "/sentry-worker.min.js",
            }),
        ],
        tracePropagationTargets: ["localhost", baseApiUrl],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        ignoreErrors: [
            // As suggested by the Sentry blog: https://blog.sentry.io/making-your-javascript-projects-less-noisy/
            /**
             * Thrown when firefox prevents an add-on from refrencing a DOM element that has been removed.
             * This can also be filtered by enabling the browser extension inbound filter
             */
            "TypeError: can't access dead object",
            /**
             * React internal error thrown when something outside react modifies the DOM
             * This is usually because of a browser extension or Chrome's built-in translate
             */
            "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
            "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
        ],
        denyUrls: [
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
        ],
    })
}

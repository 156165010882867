import React from "react"
import { useTranslation } from "react-i18next"
import AwaitingDataIllustration from "@assets/illustrations/awaitingDataIllustration.svg?react"
import DetectionDotsIllustration from "@assets/illustrations/detectionDotsIllustration.svg?react"
import { StepText } from "@components/stepText/StepText"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { Button, Typography } from "@eyectrl/baseplate-design-system"

interface Props {
    onCloseWalkthrough: () => void
}

export const DetectionsWalkthrough: React.FC<Props> = ({ onCloseWalkthrough }) => {
    const { t } = useTranslation()
    return (
        <div className="relative">
            <div>
                <DetectionDotsIllustration className="ml-4" />
            </div>
            <div className="p-8 space-y-8">
                <div className="absolute top-4 right-4">
                    <Button variant="text" onClick={onCloseWalkthrough} withPadding={false}>
                        <XMarkIcon width={20} className="text-text-secondary-light" />
                    </Button>
                </div>
                <div className="space-y-4">
                    <Typography variant="header-2">
                        {t("detections:walkthrough.title")}
                        <span className="text-brand-eye-orange">.</span>
                    </Typography>
                    <div className="h-[2px] bg-border-primary-light w-[80px]"></div>
                    <Typography variant="body-2">{t("detections:walkthrough.description")}</Typography>
                </div>

                <div className="flex items-center gap-4">
                    <div className="w-[125px] h-[125px]">
                        <AwaitingDataIllustration className="w-full h-full" />
                    </div>
                    <div className="flex-1 pr-4">
                        <Typography variant="header-5">{t("detections:walkthrough.whatSection.title")}</Typography>
                        <Typography variant="body-2" color="text-secondary" className="mt-2">
                            {t("detections:walkthrough.whatSection.description")}
                        </Typography>
                    </div>
                </div>

                <div>
                    <Typography variant="header-5">{t("detections:walkthrough.stepsSection.title")}</Typography>

                    <div className="space-y-4 mt-4">
                        <StepText
                            number={1}
                            title={t("detections:walkthrough.stepsSection.step1.title")}
                            text={t("detections:walkthrough.stepsSection.step1.text")}
                        />
                        <StepText
                            number={2}
                            title={t("detections:walkthrough.stepsSection.step2.title")}
                            text={t("detections:walkthrough.stepsSection.step2.text")}
                        />
                        <StepText
                            number={3}
                            title={t("detections:walkthrough.stepsSection.step3.title")}
                            text={t("detections:walkthrough.stepsSection.step3.text")}
                        />
                    </div>
                </div>

                <div>
                    <div className="h-[2px] bg-border-primary-light w-[80px]"></div>
                    <div className="mt-4">
                        <Typography variant="body-3">{t("detections:walkthrough.questions.title")}</Typography>
                        <a
                            href="https://kb.eye.security/resolved-detections"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:underline"
                        >
                            <Typography variant="body-3-semibold" color="text-link">
                                {t("detections:walkthrough.questions.helpCenter")}
                            </Typography>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

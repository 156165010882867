import { useState } from "react"
import { useSyncStateToUrl } from "@hooks/useSyncStateToUrl"
import { useGetAllSearchParams } from "@hooks/useGetAllSearchParams"
import { IDateRange, getValidDateRange, getInitialDateRange } from "@utils/dateRangeUtils"

interface DateRangePickerStateReturn {
    dateRange: IDateRange | undefined
    setSelectedDateRange: (range: IDateRange | undefined) => void
}

export const useDateRangePickerState = (
    initialDateRange?: IDateRange,
    onSelectRange?: (dateRange: IDateRange | undefined) => void,
): DateRangePickerStateReturn => {
    const [dateRange, setSelectedDateRange] = useState<IDateRange | undefined>(initialDateRange)

    return {
        dateRange,
        setSelectedDateRange: onSelectRange
            ? (range) => {
                  setSelectedDateRange(range)
                  onSelectRange(range)
              }
            : setSelectedDateRange,
    }
}
/**
 * Implements the useDateRangePickerState, but also syncs the state to the url.
 * In addition it also check the url when initially loading for a from/to pair to set as the initial state.
 * @returns
 */
export const useUrlSyncingDateRangePickerState = (): DateRangePickerStateReturn => {
    const syncStateToUrl = useSyncStateToUrl()
    const searchParams = useGetAllSearchParams()

    const { dateRange, setSelectedDateRange } = useDateRangePickerState(
        // Determines the initial date at which the datepicker is set.
        // Checks the url and sets this from & to time as its initial state, otherwise it is initiated with a fallback range.
        getValidDateRange(getInitialDateRange(searchParams.fromTime, searchParams.toTime)),
        (range) => {
            // Makes sure we are dealing with a valid range before syncing to the url.
            const validatedRange = getValidDateRange({ from: range?.from, to: range?.to })
            // When the date range changes, we update the url to reflect this date range for sharing purposes
            syncStateToUrl({ fromTime: validatedRange?.from, toTime: validatedRange?.to })
        },
    )

    return { dateRange, setSelectedDateRange }
}

import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { Button } from "@eyectrl/baseplate-design-system"
import { Typography } from "@eyectrl/baseplate-design-system"
import { Checkbox, Field, Label } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { useInsuranceUpsell } from "@hooks/useInsuranceUpsell"
import { useFeatureFlagVariantKey } from "posthog-js/react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

const QuizAnswer: React.FC<{ answerText: string; onClick: () => void }> = ({ answerText, onClick }) => {
    return (
        <div
            className="border border-background-brand-hover-light hover:bg-background-brand-hover-light p-3 dark rounded-md cursor-pointer"
            onClick={onClick}
        >
            <Field className="flex items-center gap-2">
                <Checkbox
                    checked={false}
                    onChange={() => {}}
                    className="group block size-4 rounded-full border bg-white "
                />
                <Label>
                    <Typography variant="span">{answerText}</Typography>
                </Label>
            </Field>
        </div>
    )
}

export const InsuranceUpsellQuiz: React.FC = () => {
    const { t } = useTranslation()
    const [activeStage, setActiveStage] = useState<"question" | "explanation">("question")
    const { showInsuranceUpsell, completeInsuranceUpsell } = useInsuranceUpsell()
    const [showQuiz, setShowQuiz] = useState(showInsuranceUpsell)
    const { captureEvent } = useCapturePostHogEvent()
    const featureFlagVariant = useFeatureFlagVariantKey(featureFlagConfig.insuranceUpsellExperiment)

    const onClickAnswer = () => {
        setActiveStage("explanation")
        completeInsuranceUpsell()
        captureEvent(POSTHOG_CAPTURE_TYPES.INSURANCE_UPSELL.QUIZ.ANSWERED)
    }

    const onCloseNotification = () => {
        setShowQuiz(false)
        completeInsuranceUpsell()
        captureEvent(POSTHOG_CAPTURE_TYPES.INSURANCE_UPSELL.QUIZ.SKIP)
    }

    const onRequestQuote = () => {
        setShowQuiz(false)
        completeInsuranceUpsell()
        captureEvent(POSTHOG_CAPTURE_TYPES.INSURANCE_UPSELL.REQUEST_QUOTE)
    }

    if (showQuiz === false) {
        return null
    }

    // The quiz group is currently set as the quiz variant of the A/B test
    // The featureflagvariant currently is "undefined" | "control" | "quiz"
    if (featureFlagVariant !== "quiz") {
        return null
    }

    return (
        <div className="fixed bottom-5 right-5 bg-brand-eye-blue w-[320px] sm:w-[400px] rounded-lg z-50">
            <div className="relative p-8">
                <Typography color="text-invert" variant="body-1" className="text-center">
                    {activeStage === "question"
                        ? t("dashboardMetrics:insuranceUpsell.quiz.quizTitle")
                        : t("dashboardMetrics:insuranceUpsell.quiz.answer")}
                </Typography>

                <button className="absolute top-4 right-4 hover:stroke-eye-gray-30" onClick={onCloseNotification}>
                    <XMarkIcon width={24} className="fill-white" />
                </button>

                {activeStage === "question" && (
                    <Typography variant="header-3" color="text-invert" className="mt-3 text-center">
                        {t("dashboardMetrics:insuranceUpsell.quiz.question")}
                    </Typography>
                )}

                {activeStage === "explanation" && (
                    <Typography variant="header-3" color="text-invert" className="mt-3 text-center">
                        {t("dashboardMetrics:insuranceUpsell.quiz.explanationTitle")}
                    </Typography>
                )}

                {activeStage === "question" && (
                    <div className="space-y-3 mt-4">
                        <QuizAnswer
                            answerText={t("dashboardMetrics:insuranceUpsell.quiz.optionA")}
                            onClick={onClickAnswer}
                        />
                        <QuizAnswer
                            answerText={t("dashboardMetrics:insuranceUpsell.quiz.optionB")}
                            onClick={onClickAnswer}
                        />
                    </div>
                )}

                {activeStage === "explanation" && (
                    <div className="space-y-4">
                        <Typography variant="body-1" color="text-invert" className="mt-3 text-center">
                            {t("dashboardMetrics:insuranceUpsell.quiz.explanation")}
                        </Typography>
                        <div className="dark flex justify-center">
                            <a href="https://app.eyeunderwriting.eu/" target="_blank" rel="noopener noreferrer">
                                <Button variant="primary" onClick={onRequestQuote}>
                                    {t("dashboardMetrics:insuranceUpsell.quiz.requestQuote")}
                                </Button>
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

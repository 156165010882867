import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { getUserInformationEndpoint } from "../../../api/endpoints"
import { useAuth0 } from "@auth0/auth0-react"
import { getRequest } from "@/api/requests"

export interface UserInformationDataSource {
    data?: {
        is_admin?: boolean
    }
}

export const useUserInformation = (): UseQueryResult<UserInformationDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const userInformationEndpoint = getUserInformationEndpoint()

    return useQuery<UserInformationDataSource, Error>({
        queryKey: userInformationEndpoint.queryKey,
        queryFn: () => getRequest(userInformationEndpoint.url, getAccessTokenSilently),
    })
}

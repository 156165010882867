import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { IDetectionV2 } from "../types"
import { getRequest } from "@/api/requests"
import { getDetectionByIdV2Endpoint } from "@/api/endpoints"

interface DetectionV2DataSource {
    data: IDetectionV2 | null
    meta_data?: {
        last_key: string
        last_updated: string
    } | null
}

export const useDetectionV2ById = (
    eyed: string,
    id?: string,
    enabled: boolean = true,
): UseQueryResult<DetectionV2DataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()

    const detectionsEndpoint = getDetectionByIdV2Endpoint(eyed, id)

    return useQuery<DetectionV2DataSource, Error>({
        queryKey: detectionsEndpoint.queryKey,
        queryFn: () => getRequest(detectionsEndpoint.url, getAccessTokenSilently),
        enabled: enabled,
    })
}

import { IOnboardingState } from "./onboardingActionListUtils"

export const getShouldShowOnboardingActionList = (
    onboardingState: IOnboardingState,
    showMsSentinelOnboarding: boolean,
) => {
    // Create mapping between onboarding tasks and if the widget should show for them.
    const showOnboardingTasks = {
        serviceOnboarding: onboardingState.serviceOnboarding === "ONBOARDING_IN_PROGRESS",
        awarenessOnboarding: onboardingState.awarenessOnboarding === "ONBOARDING_IN_PROGRESS",
        sentinelOnboarding:
            showMsSentinelOnboarding === true &&
            ["AZURE_SUBSCRIPTION_REQUESTED", "OPERATIONS_ONBOARDING_IN_PROGRESS", "ONBOARDING_COMPLETED"].includes(
                onboardingState.sentinelOnboarding,
            ),
    }

    // If any of the onboarding tasks is "true" we can say that the widget needs to be shown.
    return Object.values(showOnboardingTasks).some((value) => value === true)
}

import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@eyectrl/baseplate-design-system"
import { CheckBadgeIcon } from "@heroicons/react/24/solid"
import { useInsuranceUpsell } from "@hooks/useInsuranceUpsell"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { Typography } from "@eyectrl/baseplate-design-system"

export const InsuranceUpsellEDRBanner: React.FC = () => {
    const { t } = useTranslation()
    const { showInsuranceUpsell, completeInsuranceUpsell } = useInsuranceUpsell()
    const { captureEvent } = useCapturePostHogEvent()

    if (showInsuranceUpsell === false) {
        return null
    }

    const onRequestQuote = () => {
        completeInsuranceUpsell()
        captureEvent(POSTHOG_CAPTURE_TYPES.INSURANCE_UPSELL.REQUEST_QUOTE_EDR_PAGE)
    }

    return (
        <div className="bg-text-success-light/10 border-text-success-light border rounded-md p-3 mb-6">
            <div className="flex items-center gap-2">
                <div className="text-brand-eye-blue">
                    <CheckBadgeIcon width={20} />
                </div>
                <div className="flex items-center gap-2">
                    <Typography>{t("dashboardMetrics:insuranceUpsell.quoteBanner.title")}</Typography>
                    <a href="https://app.eyeunderwriting.eu/" target="_blank" rel="noopener noreferrer">
                        <Button variant="text" withPadding={false} onClick={onRequestQuote}>
                            {t("dashboardMetrics:insuranceUpsell.quoteBanner.link")}
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    )
}

import { getAwarenessUpsellMessageEndpoint } from "@/api/endpoints"
import { getRequest } from "@/api/requests"
import { useMutation } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { useTranslation } from "react-i18next"
import { toastService } from "@/services/toastService"

export interface IAwarenessStateUpdate {
    completed: boolean
    step: number
}

export const useAwarenessUpsellMessage = (onSuccess?: () => void) => {
    const { getAccessTokenSilently } = useAuth0()
    const { t } = useTranslation()

    return useMutation({
        mutationFn: (variables: { eyed: string; type: "meeting" | "call" }) => {
            const endpoint = getAwarenessUpsellMessageEndpoint(variables.eyed, variables.type)

            return getRequest<{ data: boolean }>(endpoint.url, getAccessTokenSilently)
        },
        onSuccess: (_, variables) => {
            // We only want to trigger the on success if the type is "call". Otherwise the success modal shows for the meeting type, which it should not.
            if (variables.type === "call") {
                onSuccess?.()
            }
        },
        onError: () => {
            toastService.errorMessage(t("awareness.upsell.messageError"))
        },
    })
}

import { TypographyColor } from "@eyectrl/baseplate-design-system"
import { MetricVerdict } from "@features/dashboard/dashboardTypes"

export const getMetricVerdictColor = (verdict: MetricVerdict): TypographyColor => {
    if (verdict === "Good") return "text-success"
    if (verdict === "Sufficient") return "text-warning"
    if (verdict === "Insufficient") return "text-critical"
    return "text-primary"
}

export const getDiskEncryptionVerdictRange = (verdict: MetricVerdict) => {
    if (verdict === "Good") return { from: "95%", to: "100%" }
    if (verdict === "Sufficient") return { from: "80%", to: "95%" }
    return { from: "0%", to: "80%" }
}

export const getMfaVerdictRange = (verdict: MetricVerdict) => {
    if (verdict === "Good") return { from: "95%", to: "100%" }
    if (verdict === "Sufficient") return { from: "80%", to: "95%" }
    return { from: "0%", to: "80%" }
}

// Breakpoints are set in tailwind.
// These are the default breakpoints.
export const MEDIA_QUERIES = {
    sm: "(min-width: 640px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 1024px)",
    xl: "(min-width: 1280px)",
    xxl: "(min-width: 1536px)",
    xxxl: "(min-width: 1750px)",
}

import { PageHeader } from "@components/header/PageHeader"
import { LinkBlock } from "@designSystem/buttons/LinkBlock"
import { Typography } from "@eyectrl/baseplate-design-system"
import React from "react"
import { BookOpenIcon } from "@heroicons/react/24/outline"
import { useTranslation } from "react-i18next"

export const ReportingButtonStep: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="">
            <PageHeader title={t("awareness.onboarding.steps.reportingButtonStep.title")} />

            <Typography>{t("awareness.onboarding.steps.reportingButtonStep.description")}</Typography>

            <section className="mt-8">
                <div className="max-w-md">
                    <a
                        className=""
                        rel="noopener noreferrer"
                        href={t("awareness.onboarding.steps.reportingButtonStep.linkTo")}
                        target="_blank"
                    >
                        <LinkBlock
                            title={t("awareness.onboarding.steps.reportingButtonStep.link")}
                            icon={<BookOpenIcon />}
                        />
                    </a>
                </div>
            </section>
        </div>
    )
}

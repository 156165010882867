import { useApplicationStore } from "@/stores/applicationStore"
import { Bars3Icon } from "@heroicons/react/24/outline"

export const MobileTopBar: React.FC = () => {
    const isSidebarOpen = useApplicationStore((state) => state.isSidebarOpen)
    const setIsSidebarOpen = useApplicationStore((state) => state.setIsSidebarOpen)

    return (
        <div className="w-full sm:hidden border-b p-4 bg-white flex">
            <button
                className="hover:text-button-secondary-text-dark flex items-center gap-2"
                onClick={() => {
                    setIsSidebarOpen(!isSidebarOpen)
                }}
            >
                <Bars3Icon width={24} height={24} /> Menu
            </button>
        </div>
    )
}

import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { getRequest } from "@/api/requests"
import { getUserProfileEndpoint } from "@/api/endpoints"
import { useActiveEyed } from "@hooks/useActiveEyed"

export interface UserInformationDataSource {
    data?: {
        // Hotspots
        has_closed_metrics_hotspot: boolean
        has_closed_management_reporting_hotspot: boolean
        // Insurance Upsell
        has_completed_insurance_upsell: boolean
        // Threat Hunting
        skip_threathunting_acknowledgement_confirmation: boolean
        has_completed_threat_hunting_introduction: boolean
        // Awareness
        has_visited_awareness: boolean
        // MS Tenants
        has_reviewed_ms_tenants: boolean
        // Detections v2
        active_detections_display: "cards" | "tables"
        has_visited_detections_v2: boolean
    }
}

export const useUserProfileData = (): UseQueryResult<UserInformationDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const activeEyed = useActiveEyed()
    const endpoint = getUserProfileEndpoint(activeEyed)

    return useQuery<UserInformationDataSource, Error>({
        queryKey: endpoint.queryKey,
        queryFn: () => getRequest(endpoint.url, getAccessTokenSilently),
        enabled: activeEyed !== "",
    })
}

import React, { PropsWithChildren } from "react"
import { PostHogProvider } from "posthog-js/react"
import { POSTHOG_CONFIGURATION } from "./postHogConfig"

// Component that just Provides the Posthog instance to the application.
export const PostHogContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    if (
        import.meta.env.VITE_PUBLIC_POSTHOG_KEY === undefined ||
        import.meta.env.VITE_PUBLIC_POSTHOG_HOST === undefined
    ) {
        console.warn("[PORTAL] Missing analytics configuration")
    }

    return (
        // Always initially sets up with the WITHOUT consent configuration.
        // This configuration will later be updated by the PostHogConsentHandler component. ( App.tsx )
        <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={POSTHOG_CONFIGURATION}>
            {children}
        </PostHogProvider>
    )
}

import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { IRecipient } from "../awarenessTypes"
import { getAwarenessRecipientsEndpoint } from "@/api/endpoints"
import { getRequest } from "@/api/requests"
import { useAuth0 } from "@auth0/auth0-react"

export interface IRecipientsDataSource {
    data: IRecipient[] | null
    meta_data?: {
        last_updated?: string | null
    }
}

export const useRecipients = (eyed: string): UseQueryResult<IRecipientsDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const recipientsEndpoint = getAwarenessRecipientsEndpoint(eyed)

    return useQuery<IRecipientsDataSource, Error>({
        queryKey: recipientsEndpoint.queryKey,
        queryFn: () => getRequest(recipientsEndpoint.url, getAccessTokenSilently),
    })
}

import Tippy from "@tippyjs/react"
import React from "react"
import { HotspotTooltip } from "./HotspotTooltip"
import { IHotspotProps } from "./hotspotTypes"

/**
 * Hotspot component that displays a tooltip with additional information when hovered over.
 *
 * @component
 * @param {IHotspotProps} props - The properties for the Hotspot component.
 * @param {string} props.title - The title to be displayed in the tooltip.
 * @param {string} props.description - The description to be displayed in the tooltip.
 * @param {string} props.buttonText - The text to be displayed on the button inside the tooltip.
 * @param {string} props.hotspotId - The unique identifier for the hotspot.
 * @param {() => void} [props.onHotspotButtonClick] - Optional callback function to be called when the button inside the tooltip is clicked.
 *
 * @returns {JSX.Element} The rendered Hotspot component.
 */
export const Hotspot: React.FC<IHotspotProps & { onHotspotButtonClick?: () => void }> = ({
    title,
    description,
    buttonText,
    hotspotId,
    onHotspotButtonClick,
}) => {
    return (
        <Tippy
            content={
                <HotspotTooltip title={title} description={description} hotspotId={hotspotId} buttonText={buttonText} />
            }
            interactive={true}
            trigger="mouseenter"
            hideOnClick
            onShow={(instance) => {
                // Add event listener to the button inside the tooltip
                // Also add a data-listener attribute so we can check if the event listener is already present.
                const buttons = instance.popper.querySelectorAll("button")
                buttons.forEach((button) => {
                    if (!button.hasAttribute("data-listener")) {
                        button.setAttribute("data-listener", "true")
                        button.addEventListener("click", () => {
                            instance.hide()
                            onHotspotButtonClick?.()
                        })
                    }
                })
            }}
        >
            <div className="rounded-full border-spacing-1 border-[1px] border-brand-eye-orange flex justify-center items-center animate-breathe w-[23px] h-[23px]">
                <div className="w-[13px] h-[13px] bg-brand-eye-orange rounded-full "></div>
            </div>
        </Tippy>
    )
}

import { WidgetSize } from "@features/dashboard/dashboardTypes"
import { MEDIA_QUERIES } from "@hooks/mediaQueries"
import useMediaQuery from "@hooks/useMediaQuery"
import { useEffect, useState } from "react"

const xlTable = {
    occurredAt: true,
    category: true,
    resolutionStatus: true,
    assetIdentifier: true,
    clientContacted: true,
    source: true,
    actions: true,
}

const largeTable = {
    occurredAt: true,
    category: true,
    resolutionStatus: true,
    assetIdentifier: false,
    clientContacted: false,
    source: false,
    actions: true,
}

const smallTable = {
    occurredAt: true,
    category: true,
    resolutionStatus: true,
    assetIdentifier: false,
    clientContacted: false,
    source: false,
    actions: false,
}

export const useDetectionsColumnVisibility = (
    size: WidgetSize = "full",
): { columnVisibility: Record<string, boolean> } => {
    const showExtraLargeTable: boolean = useMediaQuery(MEDIA_QUERIES.xxxl)
    const showLargeTable: boolean = useMediaQuery(MEDIA_QUERIES.xxl)

    useEffect(() => {
        if (showExtraLargeTable) {
            if (size === "full") {
                setColumnVisibility(xlTable)
            } else {
                setColumnVisibility(largeTable)
            }
        } else if (showLargeTable) {
            if (size === "full") {
                setColumnVisibility(largeTable)
            } else {
                setColumnVisibility(smallTable)
            }
        } else {
            setColumnVisibility(smallTable)
        }
    }, [showExtraLargeTable, showLargeTable, size])

    const [columnVisibility, setColumnVisibility] = useState(xlTable)

    return { columnVisibility }
}

import { useSetupPostHog } from "@/posthog/useSetupPostHog"
import { IUserProfile } from "./types"
import { useUserProfileData } from "./useUserProfileData"
import {
    LOCAL_STORAGE_ACTIVE_DETECTIONS_DISPLAY,
    LOCAL_STORAGE_COMPLETED_INSURANCE_UPSELL,
    LOCAL_STORAGE_HOTSPOT_PREFIX,
    LOCAL_STORAGE_REVIEWED_MS_TENANTS,
    LOCAL_STORAGE_SKIP_ACKNOWLEDGEMENT_CONFIRMATION,
    LOCAL_STORAGE_VISITED_DETECTIONS_KEY,
    LOCAL_STORAGE_VISITED_THREAT_HUNTING_INTRODUCTION,
    localStorageSettings,
} from "@/localStorage/localStorage"
import { HotspotConfig } from "@config/hotspotConfig"
import { useEffect, useState } from "react"
import { usePatchUserProfile } from "./usePatchUserProfile"
import React from "react"

export const DEFAULT_USER_PROFILE: IUserProfile = {
    // Hotspots
    has_closed_metrics_hotspot: false,
    has_closed_management_reporting_hotspot: false,
    // Insurance Upsell
    has_completed_insurance_upsell: false,
    // Threat Hunting
    skip_threathunting_acknowledgement_confirmation: false,
    has_completed_threat_hunting_introduction: false,
    // Awareness
    has_visited_awareness: false,
    // Onboarding
    has_reviewed_ms_tenants: false,
    // Detections v2
    active_detections_display: null,
    has_visited_detections_v2: false,
}

// Function to check if all the user profile properties exist in the local storage
const checkLocalStoragePropertiesExist = (): boolean => {
    const localStorageProperties = [
        localStorage.getItem(LOCAL_STORAGE_COMPLETED_INSURANCE_UPSELL),
        localStorage.getItem(LOCAL_STORAGE_SKIP_ACKNOWLEDGEMENT_CONFIRMATION),
        localStorage.getItem(LOCAL_STORAGE_VISITED_THREAT_HUNTING_INTRODUCTION),
        localStorage.getItem(LOCAL_STORAGE_REVIEWED_MS_TENANTS),
        localStorage.getItem(LOCAL_STORAGE_ACTIVE_DETECTIONS_DISPLAY),
        localStorage.getItem(LOCAL_STORAGE_VISITED_DETECTIONS_KEY),
        localStorage.getItem(LOCAL_STORAGE_HOTSPOT_PREFIX + HotspotConfig.METRICS_HOTSPOT),
        localStorage.getItem(LOCAL_STORAGE_HOTSPOT_PREFIX + HotspotConfig.MANGEMENT_REPORTING_HOTSPOT),
    ]

    // Check if all the properties exist in the local storage
    return localStorageProperties.some((property) => property !== null)
}

/**
 * Hook to manage the user profile properties.
 * This hook is responsible for syncing the user profile properties between the BE and the local storage.
 * @returns {IUserProfile} activeUserProfile - The active user profile properties.
 */
export const useUserProfile = (eyed: string) => {
    const { data, isLoading } = useUserProfileData()
    const { hasOptedIn } = useSetupPostHog()
    const { mutate: patchUserProfile } = usePatchUserProfile()
    const [activeUserProfile, setActiveUserProfile] = useState<IUserProfile>()
    const localStoragePropertiesExist = checkLocalStoragePropertiesExist()

    // Function to update the user profile properties
    // Sets the local active user profile and syncs to the BE.
    const updateUserProfileProperty = React.useCallback(
        (property: Partial<IUserProfile>) => {
            if (activeUserProfile === undefined) {
                return
            }

            const updatedUserProfile = { ...activeUserProfile, ...property }
            patchUserProfile({ eyed: eyed, update: property })
            setActiveUserProfile(updatedUserProfile)
        },
        [eyed, patchUserProfile, activeUserProfile],
    )

    useEffect(() => {
        // If BE Data is still loading, don't create any profiles just yet.
        // Also if the active eyed is not set, don't update yet.
        if (isLoading || eyed === "") {
            return
        }

        // If a profile was already set, we don't need to sync anything for now.
        if (activeUserProfile !== undefined) {
            return
        }

        // If the BE is loaded AND local storage userprofile exist, we should use the setting in local storage
        // Then we should sync the local storage userprofile to the BE and remove all the local storage keys for the next time.
        if (localStoragePropertiesExist) {
            console.log("[PORTAL] Local userprofile found, migrating to BE userprofile.")

            const localUserProfile: IUserProfile = {
                has_closed_metrics_hotspot: DEFAULT_USER_PROFILE.has_closed_metrics_hotspot, // Assuming default value as false
                has_closed_management_reporting_hotspot: DEFAULT_USER_PROFILE.has_closed_management_reporting_hotspot, // Assuming default value as false
                has_completed_insurance_upsell: localStorageSettings.getHasCompletedInsuranceUpsell(),
                skip_threathunting_acknowledgement_confirmation: localStorageSettings.getSkipAcknowledgeConfirmation(),
                has_completed_threat_hunting_introduction:
                    localStorageSettings.getHasVisitedThreatHuntingIntroduction(),
                has_visited_awareness: DEFAULT_USER_PROFILE.has_visited_awareness,
                has_reviewed_ms_tenants: localStorageSettings.getHasReviewedMSTenants(),
                active_detections_display: localStorageSettings.getActiveDetectionsDisplay() ?? "cards",
                has_visited_detections_v2: localStorageSettings.getHasVisitedDetections(),
            }
            // Clear the local storage user profile properties
            localStorageSettings.clearUserProfileKeys()
            // Sync userprofile from LocalStorage to BE
            patchUserProfile({ eyed: eyed, update: localUserProfile })
            // Set the active user profile to the local user profile
            setActiveUserProfile(localUserProfile)
        } else {
            // If the BE is loaded AND the local storage userprofile does not exist, we should use the setting from the BE.
            console.log("[PORTAL] No local userprofile found, using the BE userprofile.")

            const backendUserProfile: IUserProfile = {
                // Hotspots
                has_closed_metrics_hotspot:
                    data?.data?.has_closed_metrics_hotspot ?? DEFAULT_USER_PROFILE.has_closed_metrics_hotspot,
                has_closed_management_reporting_hotspot:
                    data?.data?.has_closed_management_reporting_hotspot ??
                    DEFAULT_USER_PROFILE.has_closed_management_reporting_hotspot,
                // Insurance Upsell
                has_completed_insurance_upsell:
                    data?.data?.has_completed_insurance_upsell ?? DEFAULT_USER_PROFILE.has_completed_insurance_upsell,
                // Threat Hunting
                skip_threathunting_acknowledgement_confirmation:
                    data?.data?.skip_threathunting_acknowledgement_confirmation ??
                    DEFAULT_USER_PROFILE.skip_threathunting_acknowledgement_confirmation,
                has_completed_threat_hunting_introduction:
                    data?.data?.has_completed_threat_hunting_introduction ??
                    DEFAULT_USER_PROFILE.has_completed_threat_hunting_introduction,
                // Awareness
                has_visited_awareness: data?.data?.has_visited_awareness ?? DEFAULT_USER_PROFILE.has_visited_awareness,
                // Onboarding
                has_reviewed_ms_tenants:
                    data?.data?.has_reviewed_ms_tenants ?? DEFAULT_USER_PROFILE.has_reviewed_ms_tenants,
                // Detections v2
                active_detections_display:
                    data?.data?.active_detections_display ?? DEFAULT_USER_PROFILE.active_detections_display,
                has_visited_detections_v2:
                    data?.data?.has_visited_detections_v2 ?? DEFAULT_USER_PROFILE.has_visited_detections_v2,
            }

            setActiveUserProfile(backendUserProfile)
        }
    }, [eyed, patchUserProfile, localStoragePropertiesExist, data, hasOptedIn, isLoading, activeUserProfile])

    return { activeUserProfile, updateUserProfileProperty }
}

import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { IAwarenessLanguage } from "../awarenessTypes"
import { getAwarenessLanguagesEndpoint } from "@/api/endpoints"
import { getRequest } from "@/api/requests"
import { useAuth0 } from "@auth0/auth0-react"

export interface IAwarenessLanguageDataSource {
    data: IAwarenessLanguage[] | null
}

export const useAwarenessLanguages = (): UseQueryResult<IAwarenessLanguageDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const endpoint = getAwarenessLanguagesEndpoint()

    return useQuery<IAwarenessLanguageDataSource, Error>({
        queryKey: endpoint.queryKey,
        queryFn: () => getRequest(endpoint.url, getAccessTokenSilently),
    })
}

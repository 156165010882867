import { PageHeader } from "@components/header/PageHeader"
import { Typography } from "@eyectrl/baseplate-design-system"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

export const DataProcessorStep: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="">
            <PageHeader title={t("awareness.onboarding.steps.dataProcessorStep.title")} />
            <div className="space-y-4">
                <Typography>
                    <Trans i18nKey="awareness.onboarding.steps.dataProcessorStep.description" />
                </Typography>
                <Typography>
                    <Trans i18nKey="awareness.onboarding.steps.dataProcessorStep.purpose.title" />
                </Typography>

                <ul className="list-disc pl-6">
                    <li>
                        <Trans i18nKey="awareness.onboarding.steps.dataProcessorStep.purpose.firstName" />
                    </li>
                    <li>
                        <Trans i18nKey="awareness.onboarding.steps.dataProcessorStep.purpose.lastName" />
                    </li>
                    <li>
                        <Trans i18nKey="awareness.onboarding.steps.dataProcessorStep.purpose.email" />
                    </li>
                    <li>
                        <Trans i18nKey="awareness.onboarding.steps.dataProcessorStep.purpose.preferredLanguage" />
                    </li>
                    <li>
                        <Trans i18nKey="awareness.onboarding.steps.dataProcessorStep.purpose.department" />
                    </li>
                    <li>
                        <Trans i18nKey="awareness.onboarding.steps.dataProcessorStep.purpose.companyRole" />
                    </li>
                </ul>

                <Typography spacingTop>
                    <Trans i18nKey="awareness.onboarding.steps.dataProcessorStep.phishedLocation" />
                </Typography>

                <Typography spacingTop>
                    <Trans
                        i18nKey="awareness.onboarding.steps.dataProcessorStep.moreInfo"
                        components={[
                            <a
                                href={t("awareness.onboarding.steps.dataProcessorStep.phishedLink")}
                                key="kb-link"
                                className="inline-block text-text-link-light hover:underline"
                                target="_blank"
                                rel="noopener noreferrer"
                            />,
                        ]}
                    />
                </Typography>

                <Typography spacingTop>
                    <Trans
                        i18nKey="awareness.onboarding.steps.dataProcessorStep.acceptanceText"
                        components={[
                            <a
                                href={t("awareness.onboarding.steps.dataProcessorStep.emailLink")}
                                key="kb-link"
                                className="inline-block text-text-link-light hover:underline"
                                target="_blank"
                                rel="noopener noreferrer"
                            />,
                        ]}
                    />
                </Typography>
            </div>
        </div>
    )
}

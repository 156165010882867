import { getChargebeeSessionEndpoint } from "@/api/endpoints"
import { getRequest } from "@/api/requests"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { AxiosError } from "axios"

export interface IChargebeeSession {
    access_url: string
    created_at: number
    customer_id: string
    expires_at: number
    id: string
    linked_customers: [
        {
            customer_id: string
            email: string
            has_active_subscription: boolean
            has_billing_address: boolean
            has_payment_method: boolean
            object: string
        },
    ]
    login_at: number
    login_ipaddress: string
    logout_at: number
    logout_ipaddress: string
    object: string
    redirect_url: string
    status: "created" | "logged_in" | "logged_out" | "not_yet_activated" | "activated"
    token: string
}

export interface ChargebeeDatasource {
    data: IChargebeeSession[]
    meta_data: {
        chargebeeTenant?: string
    }
}

export const useChargebeeSession = (eyed: string): UseQueryResult<ChargebeeDatasource, AxiosError> => {
    const { getAccessTokenSilently } = useAuth0()

    const endpoint = getChargebeeSessionEndpoint(eyed)

    return useQuery<ChargebeeDatasource, AxiosError>({
        queryKey: endpoint.queryKey,
        enabled: false,
        refetchInterval: 60000 * 55, // Refetch the token every 55 minutes ( expires in 1 hour )
        queryFn: () => getRequest(endpoint.url, getAccessTokenSilently),
    })
}

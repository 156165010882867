import { UserContext } from "@/providers/UserContext"
import { isNullish } from "@utils/formatUtils"
import { IOnboardingState } from "@utils/onboardingActionListUtils"
import { getShouldShowOnboardingActionList } from "@utils/onboardingStateUtils"
import { useContext } from "react"

export const useOnboardingState = () => {
    const { activeOrganisation } = useContext(UserContext)
    // The Sentinel onboarding state can contain quite a few "empty" values like undefined/null/"" which we all want to cast down to "HIDDEN"
    // If none of those apply, we can simply use the state as supplied by the BE.
    const sentinelOnboardingState =
        isNullish(activeOrganisation?.ms_sentinel_status) || activeOrganisation.ms_sentinel_status === ""
            ? "ONBOARDING_NOT_APPLICABLE"
            : activeOrganisation.ms_sentinel_status

    const onboardingState: IOnboardingState = {
        awarenessOnboarding: isNullish(activeOrganisation?.awareness)
            ? "ONBOARDING_NOT_APPLICABLE"
            : !isNullish(activeOrganisation?.awareness?.onboarded_at)
              ? "ONBOARDING_COMPLETED"
              : "ONBOARDING_IN_PROGRESS",
        sentinelOnboarding:
            activeOrganisation?.show_ms_sentinel_onboarding === false
                ? "ONBOARDING_NOT_APPLICABLE"
                : sentinelOnboardingState,
        serviceOnboarding: activeOrganisation?.onboarded === true ? "ONBOARDING_COMPLETED" : "ONBOARDING_IN_PROGRESS",
    }

    const showOnboardingActionList = getShouldShowOnboardingActionList(
        onboardingState,
        activeOrganisation?.show_ms_sentinel_onboarding ?? false,
    )

    return { onboardingState, showOnboardingActionList }
}

import React from "react"
import { DATE_FORMATS, formatDate } from "@utils/dateUtils"
import Tippy from "@tippyjs/react"
import { isNullish } from "@utils/formatUtils"
import { Typography } from "@eyectrl/baseplate-design-system"

interface Props {
    timestamp: string | null | undefined
    format?: string
    tooltipFormat?: string
}

/**
 * Shows a timestamp with a detailed date format on hover.
 * @param param0
 * @returns
 */
export const HoverableDate: React.FC<Props> = ({
    timestamp,
    format = DATE_FORMATS.DATE_TIME_FORMAT,
    tooltipFormat = DATE_FORMATS.DATE_TIME_FORMAT_ZONED,
}) => {
    if (isNullish(timestamp)) {
        return null
    }

    const displayValue = formatDate(timestamp, format)
    const tooltipValue = formatDate(timestamp, tooltipFormat)

    if (!displayValue || !tooltipFormat) {
        return null
    }

    return (
        <div className="relative group inline-block">
            <Tippy content={tooltipValue} placement={"top"}>
                <Typography color="text-secondary" variant="body-2">
                    {displayValue}
                </Typography>
            </Tippy>
        </div>
    )
}

import React from "react"
import { IDetection } from "../detectionsTypes"
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getPaginationRowModel,
} from "@tanstack/react-table"
import { TablePaginationWrapper } from "@designSystem/layout/table/TablePaginationWrapper"
import { useDetectionsColumnVisibility } from "../hooks/useDetectionsColumnVisibility"
import { getDetectionColumns } from "../detectionColumns"
import { WidgetSize } from "@features/dashboard/dashboardTypes"
import {
    SortableTableHeader,
    Table,
    TableBody,
    TableCell,
    TableColumnHeaderCell,
    TableHeader,
    TableRow,
} from "@eyectrl/baseplate-design-system"

/**
 * The columns that will be used in the table
 */
const columns = getDetectionColumns()

interface Props {
    data: IDetection[]
    onClickRow?: (id: string) => void
    selectedDetectionId?: string
    isRounded?: boolean
    hasBorder?: boolean
    size?: WidgetSize
}

export const DetectionsTable: React.FC<Props> = ({
    data,
    onClickRow,
    selectedDetectionId,
    isRounded = true,
    hasBorder = true,
    size = "full",
}) => {
    const [sorting, setSorting] = React.useState<SortingState>([])
    const { columnVisibility } = useDetectionsColumnVisibility(size)

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnVisibility,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    return (
        <TablePaginationWrapper data={data} table={table} isRounded={isRounded} hasBorder={hasBorder}>
            <Table isRounded={isRounded} hasBorder={hasBorder}>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                if (header.column.getCanSort() === false) {
                                    return (
                                        <TableColumnHeaderCell key={header.id} width={header.getSize()}>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </TableColumnHeaderCell>
                                    )
                                }

                                return (
                                    <TableColumnHeaderCell key={header.id} width={header.getSize()}>
                                        <div
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer select-none"
                                        >
                                            <SortableTableHeader
                                                getIsSorted={() => {
                                                    return header.getContext().header.column.getIsSorted()
                                                }}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </SortableTableHeader>
                                        </div>
                                    </TableColumnHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow
                                key={row.id}
                                dataTestId="detections-widget-row"
                                isSelected={selectedDetectionId === row.original.id}
                                onClick={
                                    onClickRow
                                        ? () => {
                                              onClickRow(row.original.id)
                                          }
                                        : undefined
                                }
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id} width={cell.column.getSize()}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TablePaginationWrapper>
    )
}

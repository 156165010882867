import { useState } from "react"

const xlTable = {
    label: true,
    severity: true,
    effort: true,
    last_scanned: true,
}

export const useRecommendationsColumnVisibility = (): { columnVisibility: Record<string, boolean> } => {
    const [columnVisibility] = useState(xlTable)

    return { columnVisibility }
}

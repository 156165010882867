import { TablePaginationWrapper } from "@designSystem/layout/table/TablePaginationWrapper"
import {
    Row,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import i18n from "@/i18next"
import {
    SortableTableHeader,
    Table,
    TableBody,
    TableCell,
    TableColumnHeaderCell,
    TableHeader,
    TableRow,
    Typography,
} from "@eyectrl/baseplate-design-system"
import { IRecipient } from "../awarenessTypes"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import { useTranslation } from "react-i18next"
import SearchIllustration from "@assets/illustrations/searchIllustration.svg?react"
import { RecipientToggle } from "./RecipientToggle"
import { showWithFallbackValue } from "@utils/formatUtils"
import { DisplayLanguage } from "@components/displayLanguage/DisplayLanguage"
import React from "react"

const columnHelper = createColumnHelper<IRecipient>()

interface Props {
    recipients: IRecipient[]
    searchValue: string
}

export const RecipientsTable: React.FC<Props> = ({ recipients, searchValue }) => {
    const { t } = useTranslation()
    // Ref that tracks if the table state should update when any of it's data changes.
    // The table state should not reset if the recipient data change is triggered by the active toggle.
    const skipPageResetRef = React.useRef<boolean>(false)

    const setSkipPageReset = () => {
        skipPageResetRef.current = true
    }

    React.useEffect(() => {
        // Reset the skipPageResetRef after the recipients have been updated.
        skipPageResetRef.current = false
    }, [recipients])

    const tableColumns = [
        // Combines the firstname & lastname columns into one, computed column.
        columnHelper.accessor((row) => `${row.firstname} ${row.lastname}`, {
            id: "fullName",
            header: i18n.t("pages:awareness.recipientInfo.table.recipient"),
            cell: (info) => (
                <Typography textElipsis shouldPreventWrapping>
                    {showWithFallbackValue(info.getValue())}
                </Typography>
            ),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor("email", {
            header: i18n.t("pages:awareness.recipientInfo.table.email"),
            cell: (info) => (
                <Typography textElipsis shouldPreventWrapping>
                    {info.getValue()}
                </Typography>
            ),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor("language", {
            header: i18n.t("pages:awareness.recipientInfo.table.language"),
            cell: (info) => <DisplayLanguage value={info.getValue()} textElipsis shouldPreventWrapping />,
            enableGlobalFilter: true,
        }),
        columnHelper.accessor("isActive", {
            header: i18n.t("pages:awareness.recipientInfo.table.active"),
            cell: (info) => <RecipientToggle recipient={info.row.original} onToggle={setSkipPageReset} />,
            enableGlobalFilter: true,
        }),
    ]

    const filterRecipients = (row: Row<IRecipient>, columnId: string, filterValue: string) => {
        const value = row.getValue(columnId)
        if (typeof value === "string" && value.toLowerCase().includes(filterValue.toLowerCase())) {
            return true
        }

        return false
    }

    const table = useReactTable({
        data: recipients,
        columns: tableColumns,
        state: {
            globalFilter: searchValue,
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: filterRecipients,
        autoResetPageIndex: !skipPageResetRef.current,
    })

    return (
        <TablePaginationWrapper data={recipients} table={table} isRounded isVisible>
            <Table isRounded>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableColumnHeaderCell key={header.id} width={header.getSize()}>
                                        <div
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer select-none"
                                        >
                                            <SortableTableHeader
                                                getIsSorted={() => {
                                                    return header.getContext().header.column.getIsSorted()
                                                }}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </SortableTableHeader>
                                        </div>
                                    </TableColumnHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>

                <TableBody>
                    {table.getRowModel().rows.length === 0 && (
                        <TableRow>
                            <td colSpan={table.getAllColumns().length} className="p-12">
                                <NoDataMessage
                                    title={t("awareness.recipientInfo.table.notFoundMessage.title")}
                                    subtitle={t("awareness.recipientInfo.table.notFoundMessage.description")}
                                    variant="row"
                                    image={<SearchIllustration className="w-[250px]" />}
                                />
                            </td>
                        </TableRow>
                    )}

                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id} width={cell.column.getSize()}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TablePaginationWrapper>
    )
}

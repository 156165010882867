import { Typography } from "@eyectrl/baseplate-design-system"
import EyeLogo from "@/assets/logos/eyeLogo.svg?react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Button } from "@eyectrl/baseplate-design-system"
import { SupportButton } from "@components/supportButton/SupportButton"
import { getRouterLinks } from "@/config/routes/routeLinksConfig"

interface Props {
    title: string
    description: string
}

export const ErrorPageMessage: React.FC<Props> = ({ title, description }) => {
    const { t } = useTranslation(["common", "navigation"])
    const navigate = useNavigate()
    const routerLinks = getRouterLinks()

    return (
        <div className="max-w-[800px] flex flex-col justify-center items-center p-4">
            <div className="flex items-center mt-12 flex-col sm:flex-row">
                <EyeLogo className="mt-3 mr-2 w-[65px] h-[30px]" />

                <Typography variant="display-2" color="text-invert" className="text-center">
                    {title}
                </Typography>
            </div>

            <Typography color="text-invert" className="mt-4 text-center">
                {description}
            </Typography>

            <div className="mt-4 flex items-center gap-4">
                <SupportButton variant="primary" />

                <Button
                    variant="secondary"
                    onClick={() => {
                        navigate(routerLinks.logout())
                    }}
                >
                    {t("Logout", { ns: "navigation" })}
                </Button>
            </div>
        </div>
    )
}

// import { OnboardingFlow } from "@features/awareness/onboarding/OnboardingFlow"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { OnboardingFlow } from "@features/awareness/onboarding/OnboardingFlow"
import { UpsellFlow } from "@features/awareness/onboarding/UpsellFlow"
import { useActiveEyed } from "@hooks/useActiveEyed"
import React from "react"
import { Navigate, generatePath } from "react-router"
import { useAwarenessState } from "@features/awareness/hooks/useAwarenessState"

export const AwarenessLanding: React.FC = () => {
    const { hasAwareness, hasFinishedAwarenessOnboarding } = useAwarenessState()
    const activeEyed = useActiveEyed()

    // States to handle:
    // User doesnt have awareness -> show upsell page
    if (hasAwareness === false) {
        return <UpsellFlow />
    }

    // User does have awareness, onboarding is not finished -> show onboarding flow
    if (hasAwareness === true && hasFinishedAwarenessOnboarding === false) {
        return <OnboardingFlow />
    }

    // User does have awareness, onboarding is finished, and data is available -> redirect to the overview.
    return <Navigate to={generatePath(getRouterLinks().awareness.overview({ eyed: activeEyed }))} />
}

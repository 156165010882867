export const LOCAL_STORAGE_ACTIVE_EYED_KEY = "ACTIVE_EYED"
export const LOCAL_STORAGE_VISITED_AWARENESS_KEY = "VISITED_AWARENESS" // x
export const LOCAL_STORAGE_CLOSED_EAST_NOTIFICATION = "CLOSED_EAST_NOTIFICATION" // x
export const LOCAL_STORAGE_HOTSPOT_PREFIX = "HOTSPOT_VISITED_" // x
export const LOCAL_STORAGE_SKIP_ACKNOWLEDGEMENT_CONFIRMATION = "SKIP_ACKNOWLEDGEMENT_CONFIRMATION" // x
export const LOCAL_STORAGE_VISITED_THREAT_HUNTING_INTRODUCTION = "VISITED_THREAT_HUNTING_INTRODUCTION" // x
export const LOCAL_STORAGE_COMPLETED_INSURANCE_UPSELL = "COMPLETED_INSURANCE_UPSELL" // x
export const LOCAL_STORAGE_REVIEWED_MS_TENANTS = "REVIEWED_MS_TENANTS" //
export const LOCAL_STORAGE_ACTIVE_DETECTIONS_DISPLAY = "ACTIVE_DETECTIONS_DISPLAY"
export const LOCAL_STORAGE_VISITED_DETECTIONS_KEY = "VISITED_DETECTIONS_V2"

const clearUserProfileKeys = () => {
    localStorage.removeItem(LOCAL_STORAGE_VISITED_AWARENESS_KEY)
    localStorage.removeItem(LOCAL_STORAGE_CLOSED_EAST_NOTIFICATION)
    localStorage.removeItem(LOCAL_STORAGE_SKIP_ACKNOWLEDGEMENT_CONFIRMATION)
    localStorage.removeItem(LOCAL_STORAGE_VISITED_THREAT_HUNTING_INTRODUCTION)
    localStorage.removeItem(LOCAL_STORAGE_COMPLETED_INSURANCE_UPSELL)
    localStorage.removeItem(LOCAL_STORAGE_REVIEWED_MS_TENANTS)
    localStorage.removeItem(LOCAL_STORAGE_ACTIVE_DETECTIONS_DISPLAY)
    localStorage.removeItem(LOCAL_STORAGE_VISITED_DETECTIONS_KEY)

    // Remove all hotspot keys
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key && key.startsWith(LOCAL_STORAGE_HOTSPOT_PREFIX)) {
            localStorage.removeItem(key)
        }
    }
}

const setActiveEyed = (eyed: string) => {
    localStorage.setItem(LOCAL_STORAGE_ACTIVE_EYED_KEY, eyed)
}

const getActiveEyed = () => {
    return localStorage.getItem(LOCAL_STORAGE_ACTIVE_EYED_KEY)
}

const removeActiveEyed = () => {
    localStorage.removeItem(LOCAL_STORAGE_ACTIVE_EYED_KEY)
}

const getHotspotVisited = (hotspotId: string): boolean => {
    const hotspotVisited = localStorage.getItem(LOCAL_STORAGE_HOTSPOT_PREFIX + hotspotId)

    return hotspotVisited === "true"
}

const getSkipAcknowledgeConfirmation = (): boolean => {
    const skipAcknowledgeConfirmation = localStorage.getItem(LOCAL_STORAGE_SKIP_ACKNOWLEDGEMENT_CONFIRMATION)

    return skipAcknowledgeConfirmation === "true"
}

const getHasVisitedThreatHuntingIntroduction = (): boolean => {
    const visitedIntroduction = localStorage.getItem(LOCAL_STORAGE_VISITED_THREAT_HUNTING_INTRODUCTION)

    return visitedIntroduction === "true"
}

const getHasCompletedInsuranceUpsell = (): boolean => {
    const visitedIntroduction = localStorage.getItem(LOCAL_STORAGE_COMPLETED_INSURANCE_UPSELL)

    return visitedIntroduction === "true"
}

const getHasReviewedMSTenants = (): boolean => {
    const reviewedTenants = localStorage.getItem(LOCAL_STORAGE_REVIEWED_MS_TENANTS)

    return reviewedTenants === "true"
}

const getActiveDetectionsDisplay = (): "cards" | "tables" | null => {
    const activeDisplayType = localStorage.getItem(LOCAL_STORAGE_ACTIVE_DETECTIONS_DISPLAY)

    if (activeDisplayType === "cards" || activeDisplayType === "tables") {
        return activeDisplayType
    }

    return null
}

const getHasVisitedDetections = (): boolean => {
    const hasVisited = localStorage.getItem(LOCAL_STORAGE_VISITED_DETECTIONS_KEY)

    if (hasVisited === "true") {
        return true
    }

    return false
}
export const localStorageSettings = {
    setActiveEyed,
    getActiveEyed,
    removeActiveEyed,
    getHasVisitedThreatHuntingIntroduction,
    getSkipAcknowledgeConfirmation,
    getHotspotVisited,
    getHasCompletedInsuranceUpsell,
    getHasReviewedMSTenants,
    getActiveDetectionsDisplay,
    getHasVisitedDetections,
    clearUserProfileKeys,
}

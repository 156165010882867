import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { IRecommendation } from "../recommendationTypes"
import { getRecommendationsEndpoint } from "@/api/endpoints"
import { useAuth0 } from "@auth0/auth0-react"
import { getRequest } from "@/api/requests"
import { useApplicationStore } from "@/stores/applicationStore"
import { experimentalRiskRecommendations } from "@utils/recommendationUtils"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"

export interface RecommendationsDataSource {
    data: IRecommendation[]
    meta_data: {
        last_updated: string
    }
}

const useRecommendationsQuery = (eyed: string, language: string): UseQueryResult<RecommendationsDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const riskStagingEnabled = useApplicationStore((state) => state.riskStagingEnabled)
    const recommendationsEndpoint = getRecommendationsEndpoint(eyed, language, riskStagingEnabled)

    return useQuery<RecommendationsDataSource, Error>({
        queryKey: recommendationsEndpoint.queryKey,
        select: (data) => {
            // Adds a priority of "Informative" to experimental risk recommendations.
            const updatedRecommendations: IRecommendation[] = data.data.map((recommendation) => {
                if (experimentalRiskRecommendations.includes(recommendation.id)) {
                    const updatedRecommendation: IRecommendation = {
                        ...recommendation,
                        priority: "Informative",
                    }

                    return updatedRecommendation
                }

                return recommendation
            })

            return {
                ...data,
                data: updatedRecommendations,
            }
        },
        queryFn: () => getRequest(recommendationsEndpoint.url, getAccessTokenSilently),
    })
}

/**
 * Takes in the queryResults from react-query and updates the data attribute with filtered recommendations.
 * Filters out experimental risk recommendations from the data.
 * @param queryResults
 * @returns
 */
const buildQueryResultWithFilteredRecommendations = (
    queryResults: UseQueryResult<RecommendationsDataSource, Error>,
) => {
    const recommendationsData = queryResults.data?.data

    // If the experimental recommendations are not enabled for this user, we need to filter them out after fetching them from the BE.
    // The experimental recommendations are included in a experimentalRecommendations array.
    const filteredRecommendations = recommendationsData?.filter((recommendation) => {
        const isExperimentalRecommendation = experimentalRiskRecommendations.includes(recommendation.id)

        return isExperimentalRecommendation ? false : true
    })

    // Return the old queryresult with updated data from the filter.
    return {
        ...queryResults,
        data: {
            ...queryResults.data,
            data: filteredRecommendations ?? [],
        },
    } as UseQueryResult<RecommendationsDataSource, Error>
}

export const useRecommendations = (
    eyed: string,
    language: string,
): UseQueryResult<RecommendationsDataSource, Error> => {
    const queryResults = useRecommendationsQuery(eyed, language)
    const experimentalRecommendationsEnabled = useFeatureFlagEnabled(featureFlagConfig.riskExperimentalRecommendations)

    // If the experimental recommendations are enabled, we don't need to filter them out and can safely return ALL the recommendations.
    if (experimentalRecommendationsEnabled) {
        return queryResults
    }

    // Filter out the experimental recommendations in this case before returning the query results.
    return buildQueryResultWithFilteredRecommendations(queryResults)
}

export const useSingleRecommendation = (
    eyed: string,
    language: string,
    id: string,
): UseQueryResult<RecommendationsDataSource, Error> & { selectedRecommendation: IRecommendation | undefined } => {
    const recommendationsQuery = useRecommendations(eyed, language)
    const allRecommendations = recommendationsQuery.data?.data ?? []

    const selectedRecommendation = allRecommendations.find((recommendation) => recommendation.id === id)

    return { ...recommendationsQuery, selectedRecommendation }
}

import { getAdminRiskLocalesEndpoint } from "@/api/endpoints"
import { getRequest } from "@/api/requests"
import { useAuth0 } from "@auth0/auth0-react"
import { UseQueryResult, useQuery } from "@tanstack/react-query"

interface RiskLocale {
    id: string
    title: string
    finding_description: string
    recommendation_description: string
    type: string
}

interface RiskLocalesDataSource {
    data: RiskLocale[]
    meta_data: unknown
}

export const useRiskLocales = (): UseQueryResult<RiskLocalesDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const riskLocalesEndpoint = getAdminRiskLocalesEndpoint()

    return useQuery<RiskLocalesDataSource, Error>({
        queryKey: riskLocalesEndpoint.queryKey,
        queryFn: () => getRequest(riskLocalesEndpoint.url, getAccessTokenSilently),
    })
}

import { UserContext } from "@/providers/UserContext"
import { PageLayout } from "@components/layout/PageLayout"
import { Typography } from "@eyectrl/baseplate-design-system"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"

export const Logout: React.FC = () => {
    const { t } = useTranslation()
    const { logoutUser } = useContext(UserContext)

    useEffect(() => {
        logoutUser()
    }, [logoutUser])

    return (
        <PageLayout variant="detailPage">
            <Typography>{t("logoutPage.progressMessage")}</Typography>
        </PageLayout>
    )
}

/**
 * Creates a temporary url for the provided blob and attaches it to a hidden "a" link element. Then triggers the download through this element.
 * @param blobToDownload
 * @param fileName
 */
export const downloadFile = (blobToDownload: Blob, fileName: string) => {
    // Url for the download which can be triggered by a new a element.
    const url = URL.createObjectURL(blobToDownload)

    // Create a link to download it
    const tempLink = document.createElement("a")
    tempLink.href = url
    tempLink.setAttribute("download", fileName)
    tempLink.click()

    // Clean up the temporary URL
    window.URL.revokeObjectURL(url)
}

import React from "react"
import errorIllustration from "../assets/illustrations/errorIllustration.svg"
import EyeLogo from "@/assets/logos/eyeLogo.svg?react"
import { Typography } from "@eyectrl/baseplate-design-system"
import { Button } from "@eyectrl/baseplate-design-system"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const NotFound: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation(["pages"])

    return (
        <div className="bg-brand-eye-blue/90 w-full h-full overflow-auto flex justify-center items-center flex-col">
            <div className="max-w-[800px] flex flex-col justify-center items-center p-4">
                <div className="w-full max-w-[80%]">
                    <img src={errorIllustration} alt="page not found illustration" />
                </div>

                <div className="flex items-center mt-12 flex-col sm:flex-row">
                    <EyeLogo className="mt-3 mr-2 w-[65px] h-[30px]" />

                    <Typography variant="display-2" color="text-invert" className="text-center">
                        {t("pages:notFound.heading")}
                    </Typography>
                </div>

                <Typography color="text-invert" className="mt-4 text-center">
                    {t("pages:notFound.description")}
                </Typography>

                <Button className="mt-12" variant="primary" onClick={() => navigate("/")}>
                    {t("pages:notFound.button")}
                </Button>
            </div>
        </div>
    )
}

import { Typography } from "@eyectrl/baseplate-design-system"
import Tippy from "@tippyjs/react"
import React, { PropsWithChildren } from "react"

export const FilterButton: React.FC<
    PropsWithChildren<{ className?: string; withBorder?: boolean; tooltip?: string }>
> = ({ children, className = "", withBorder = true, tooltip }) => {
    const button = (
        <button
            className={`h-[46px] bg-white ${withBorder ? "border" : ""} rounded-lg p-3 items-center flex gap-2 ${className}`}
        >
            {children}
        </button>
    )

    if (tooltip)
        return (
            <Tippy
                content={
                    <Typography variant="body-2" color="text-invert">
                        {tooltip}
                    </Typography>
                }
            >
                {button}
            </Tippy>
        )

    return button
}

import { UserContext } from "@/providers/UserContext"
import { TextInput } from "@designSystem/inputs/TextInput"
import { Typography } from "@eyectrl/baseplate-design-system"
import { EyeOrganisation } from "@features/accountSwitcher/hooks/useUserOrganisations"
import classNames from "classnames"
import React, { useContext, useMemo, useState } from "react"

interface Props {
    selectedOrganisation?: EyeOrganisation
    setSelectedOrganisation: (organisation: EyeOrganisation) => void
}

export const OrganisationSelect: React.FC<Props> = ({ setSelectedOrganisation, selectedOrganisation }) => {
    const [searchValue, setSearchValue] = useState("")
    const { userOrganisations } = useContext(UserContext)

    /**
     * Filters the organisations based on the searchValue.
     * If the result contains the active organisation, this is always return as the first.
     */
    const filteredOrganisations = useMemo(() => {
        const filtered = userOrganisations
            // Checks if awareness is enabled ( OR IS THIS NOT THE CASE YET? )
            .filter((org) => org.awareness !== null)
            .filter((org) => org.awareness?.recipient_source?.toLowerCase() === "csv")
            .filter((organistion) => {
                return organistion.name.toLowerCase().includes(searchValue.toLowerCase())
            })

        return filtered
    }, [searchValue, userOrganisations])

    return (
        <div>
            <TextInput
                type="text"
                placeholder={"Search..."}
                value={searchValue}
                onChange={(event) => {
                    setSearchValue(event.target.value)
                }}
                autoFocus
                className="w-full"
            />

            <div className="relative mt-4 rounded-md text-neutral-100 border max-h-[300px] overflow-auto">
                {filteredOrganisations.map((organisation) => {
                    const isSelected = selectedOrganisation?.eyed === organisation.eyed
                    return (
                        <div
                            key={`awareness-org-${organisation.eyed}`}
                            onClick={() => setSelectedOrganisation(organisation)}
                            className={classNames([{ "bg-orange-300": isSelected }, "py-2 px-4 cursor-pointer"])}
                        >
                            <Typography variant={isSelected ? "body-1-semibold" : "body-1"}>
                                {organisation.name}
                            </Typography>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

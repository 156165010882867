import { createColumnHelper } from "@tanstack/react-table"
import i18n from "@/i18next"

import { UserMFAInfo } from "@features/recommendations/recommendationTypes"
import { Typography } from "@eyectrl/baseplate-design-system"

const columnHelper = createColumnHelper<UserMFAInfo>()

const BASE_COLUMNS = [
    columnHelper.accessor("account_type", {
        header: i18n.t("pages:recommendations.mfaUserInfoTable.accountType"),
        cell: (info) => <Typography>{info.getValue()}</Typography>,
        enableHiding: true,
    }),
    columnHelper.accessor("status", {
        header: i18n.t("pages:recommendations.mfaUserInfoTable.status"),
        cell: (info) => <Typography>{info.getValue()}</Typography>,
        enableHiding: true,
    }),
    columnHelper.accessor("username", {
        header: i18n.t("pages:recommendations.mfaUserInfoTable.username"),
        cell: (info) => <Typography>{info.getValue()}</Typography>,
        enableHiding: true,
    }),
]

export const getMfaUserInfoBaseColumns = () => {
    return [...BASE_COLUMNS]
}

export const getMfaUserInfoExtendedColumns = () => {
    return [
        columnHelper.accessor("tenant", {
            header: i18n.t("pages:recommendations.mfaUserInfoTable.tenant"),
            cell: (info) => <Typography>{info.getValue()}</Typography>,
            enableHiding: true,
        }),
        ...BASE_COLUMNS,
    ]
}

import { isNullish } from "./formatUtils"

export type UrlStateValue = string | undefined | null
export type CleanUrlStateValue = string
export type UrlSyncableProperties = "fromTime" | "toTime" | "detectionId"
export const syncableProperties: Array<UrlSyncableProperties> = ["fromTime", "toTime", "detectionId"]

/**
 * Removes nullish values from the url state and builds an object with key, value pairs that are non nullish.
 * @param objectToClean
 * @returns
 */
export const removeNullishValuesFromUrlState = (
    objectToClean: Record<string, UrlStateValue>,
): Record<string, CleanUrlStateValue> => {
    return Object.entries(objectToClean).reduce((previous, current) => {
        if (isNullish(current[1])) {
            return { ...previous }
        }

        return { ...previous, [current[0]]: current[1] }
    }, {})
}

/**
 * Builds an object from the searchParams with only properties from the whitelisted syncableProperties.
 * Each value in the object is a valid string, nullish values are filtered out.
 * @param searchParams
 * @returns
 */
export const getAllAllowedSearchParams = (
    searchParams: URLSearchParams,
): Partial<Record<UrlSyncableProperties, string>> => {
    const availableSearchParamKeys = Array.from(searchParams.keys())

    const allSearchParams: Partial<Record<UrlSyncableProperties, string>> = syncableProperties.reduce(
        (previous, current) => {
            if (availableSearchParamKeys.includes(current) && !isNullish(searchParams.get(current))) {
                return { ...previous, [current]: searchParams.get(current) }
            }

            return previous
        },
        {},
    )

    return allSearchParams
}

import { PRIVACY_POLICY_LINK } from "@/config/linkConfig"
import { Button } from "@eyectrl/baseplate-design-system"
import { Typography } from "@eyectrl/baseplate-design-system"
import React from "react"
import { useTranslation } from "react-i18next"

export const CookieInformation: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div>
                <Typography variant="header-3" spacingBottom>
                    {t("cookiebanner:cookieInformation.title")}
                </Typography>
                <Typography variant="body-1">{t("cookiebanner:cookieInformation.description")}</Typography>
            </div>

            <section className="mt-4 flex space-y-5 flex-col">
                <div className="p-4 border bg-neutral-100 rounded-md">
                    <Typography variant="body-1-semibold">
                        {t("cookiebanner:cookieInformation.auth0Cookie.title")}
                    </Typography>
                    <Typography variant="body-1">
                        {t("cookiebanner:cookieInformation.auth0Cookie.description")}
                    </Typography>
                </div>

                <div className="p-4 border bg-neutral-100 rounded-md">
                    <Typography variant="body-1-semibold">
                        {t("cookiebanner:cookieInformation.posthogCookie.title")}
                    </Typography>
                    <Typography variant="body-1">
                        {t("cookiebanner:cookieInformation.posthogCookie.description")}
                    </Typography>
                </div>

                <div className="bg-brand-eye-ice-100 p-4 border rounded-md">
                    <Typography variant="body-1-semibold">
                        {t("cookiebanner:cookieInformation.privacyPolicy")}
                    </Typography>
                    <a rel="noopener noreferrer" href={PRIVACY_POLICY_LINK} target="_blank" className="hover:underline">
                        <Button variant="text" withPadding={false}>
                            {t("common:userMenu.account.privacyPolicy")}
                        </Button>
                    </a>
                </div>
            </section>
        </div>
    )
}

import { Skeleton } from "./Skeleton"

export const TableSkeleton = () => {
    return (
        <div className="card p-8 flex flex-col space-y-4">
            {[...Array(10)].map((_, i) => (
                <div className="flex gap-4" key={`table-skeleton-row-${i}`}>
                    <Skeleton className="w-[25px] h-[25px] rounded-full" />

                    <div className="flex-1 space-y-4">
                        <Skeleton className="w-full h-[25px]" />
                    </div>
                    <div className="flex-1 space-y-4">
                        <Skeleton className="w-full h-[25px]" />
                    </div>
                    <div className="flex-1 space-y-4">
                        <Skeleton className="w-full h-[25px]" />
                    </div>
                    <div className="flex-1 space-y-4">
                        <Skeleton className="w-full h-[25px]" />
                    </div>
                    <div className="flex-1 space-y-4">
                        <Skeleton className="w-full h-[25px]" />
                    </div>
                </div>
            ))}
        </div>
    )
}

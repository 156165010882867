import axios, { AxiosRequestConfig } from "axios"
import { GetTokenSilentlyOptions } from "@auth0/auth0-react"

export const setAxiosAuthToken = async (
    getAccessToken: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
) => {
    // Before each api call, we get the access token from auth0.
    // This ensures we always have a valid token, or the new token is collected from auth0
    const accessToken = await getAccessToken()

    // Sets the axios auth header for all requests
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
}

export const clearAxiosAuthToken = () => {
    // Sets the axios auth header for all requests
    axios.defaults.headers.common["Authorization"] = undefined
}

export const getRequest = async <T>(
    endpoint: string,
    getAccessToken: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
    config?: AxiosRequestConfig,
): Promise<T> => {
    await setAxiosAuthToken(getAccessToken)
    const resp = await axios.get(endpoint, config)
    return resp.data
}

export const postRequest = async <TBodyData, TReturnData>(
    endpoint: string,
    body: TBodyData,
    getAccessToken: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
): Promise<TReturnData> => {
    await setAxiosAuthToken(getAccessToken)

    const resp = await axios.post(endpoint, body)
    return resp.data
}

export const patchRequest = async <TBodyData, TReturnData>(
    endpoint: string,
    body: TBodyData,
    getAccessToken: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
): Promise<TReturnData> => {
    await setAxiosAuthToken(getAccessToken)

    const resp = await axios.patch(endpoint, body)
    return resp.data
}

import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import { LocalesOverview } from "@features/riskLocales/LocalesOverview"

export const RiskLocales: React.FC = () => {
    return (
        <PageLayout variant="detailPage">
            <PageHeader title="Risk Locales" />
            <LocalesOverview />
        </PageLayout>
    )
}

import { useContext, useEffect, useState } from "react"
import { PageHeader } from "@components/header/PageHeader"
import { DetailPageLayout } from "@components/layout/PageLayout"
import { useTranslation } from "react-i18next"
import { useDetectionsV2 } from "@features/detectionsv2/hooks/useDetectionsV2"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { useDetectionsV2Statistics } from "@features/detectionsv2/hooks/useDetectionsV2Statistics"
import { DetectionsOverviewWidget } from "@features/detectionsv2/components/DetectionsOverviewWidget"
import { HistoricalDetectionsWidget } from "@features/detections/components/HistoricalDetectionsWidget"
import { DetectionsWalkthrough } from "@features/detectionsv2/components/DetectionsWalkthrough"
import { DetectionsV2Table } from "@features/detectionsv2/components/DetectionsV2Table"
import { useDetectionsFilters } from "@features/detectionsv2/hooks/useDetectionsFilters"
import { IDetectionV2 } from "@features/detectionsv2/types"
import { DetectionDetails } from "@features/detectionsv2/components/DetectionDetails"
import { isNullish } from "@utils/formatUtils"
import { useNavigate } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { Transition } from "@headlessui/react"
import { useGetAllSearchParams } from "@hooks/useGetAllSearchParams"
import { CardListSkeleton } from "@designSystem/feedback/skeletons/CardListSkeleton"
import { TableSkeleton } from "@designSystem/feedback/skeletons/TableSkeleton"
import React from "react"
import { NoDetectionsWalkthrough } from "@features/detectionsv2/components/NoDetectionsWalkthrough"
import { DetectionCardsList } from "@features/detectionsv2/components/DetectionCardsList"
import { useFeatureFlagVariantKey } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { DetectionsFilterBar } from "@features/detectionsv2/components/DetectionsFilterBar"
import { useDetectionV2ById } from "@features/detectionsv2/hooks/useDetectionV2ById"
import { useDebounce } from "@hooks/useDebounce"
import { SidebarSkeleton } from "@designSystem/feedback/skeletons/SidebarSkeleton"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { DetectionsIntroModal } from "@features/detectionsv2/components/DetectionsIntroModal"
import { Typography } from "@eyectrl/baseplate-design-system"
import { UserContext } from "@/providers/UserContext"

export const DetectionsV2Page: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const activeEyed = useActiveEyed()
    const { userProfile, updateUserProfileProperty } = useContext(UserContext)
    const { captureEvent } = useCapturePostHogEvent()
    const savedDisplayModus = userProfile.active_detections_display
    const defaultView = // Use the default view as determined by the feature flag ( a/b test )
        useFeatureFlagVariantKey(featureFlagConfig.detectionsCardsTables) === "tables" ? "tables" : "cards"
    // If the saved savedDisplayModus is present, we use that, otherwise we use the view assigned by posthog
    const [activeDisplay, setActiveDisplayState] = useState<"cards" | "tables">(savedDisplayModus ?? defaultView)
    const [selectedDetection, setSelectedDetection] = useState<IDetectionV2>()
    const [walkthroughOpen, setWalkthroughOpen] = useState(true)
    const { detectionId: initialUrlDetectionId } = useGetAllSearchParams()
    const {
        dateRange,
        hasCustomDateRange,
        filterSettings,
        searchValue,
        setFiltersetting,
        setSearchValue,
        setSelectedDateRange,
        setSortBy,
        sortBy,
        validatedRange,
        sortDirection,
        setSortDirection,
    } = useDetectionsFilters()
    // Debounces the searchValue so backend calls don't trigger on every keystroke.
    const debouncedSearchValue = useDebounce(searchValue, 500)

    // Function to set the active display, and save it in the user profile.
    const setActiveDisplay = (display: "cards" | "tables") => {
        setActiveDisplayState(display)
        updateUserProfileProperty({ active_detections_display: display })
    }

    // Fetch all the required data from the statistics, list and getbyId endpoints
    const { data: statisticsData, isPending: statisticsDataPending } = useDetectionsV2Statistics(activeEyed)
    const { data: detectionEventsData, isPending: eventsDataPending } = useDetectionsV2(
        activeEyed,
        validatedRange,
        debouncedSearchValue,
        sortBy,
        sortDirection,
        filterSettings,
    )
    const { data: selectedDetectionData, isLoading: isLoadingSingleDetectionData } = useDetectionV2ById(
        activeEyed,
        initialUrlDetectionId,
        // If there's already a selected detection this query doesn't need to execute, same goes for if there is no id
        isNullish(selectedDetection) && !isNullish(initialUrlDetectionId),
    )

    // Display properties, check if there's detections in the endpoint
    const hasDetections =
        statisticsData?.data?.count_of_all_detections !== undefined && statisticsData?.data?.count_of_all_detections > 0

    // We only want to show sidebar content if there's a selected detection, the "single detection endpoint" is fetching data ( skeleton )
    // OR if the walkthrough itself is active
    const sidebarContentActive =
        !isNullish(selectedDetection) || walkthroughOpen === true || isLoadingSingleDetectionData

    // Handle selecting of the detection, syncs with the url and sets the selected detection in the state
    const onSelectDetection = React.useCallback(
        (detectionToSelect: IDetectionV2) => {
            captureEvent(POSTHOG_CAPTURE_TYPES.DETECTIONS.OPENED_DETAILS)
            setSelectedDetection(detectionToSelect)
            navigate({
                pathname: getRouterLinks().detections.root({ eyed: activeEyed }),
                search: new URLSearchParams({ detectionId: detectionToSelect.id }).toString(),
            })
        },
        [activeEyed, navigate, captureEvent],
    )

    // Makes sure the detection is properly reset from the state and this is also reflected in the url.
    const onCloseDetection = () => {
        setSelectedDetection(undefined)
        navigate({
            pathname: getRouterLinks().detections.root({ eyed: activeEyed }),
        })
    }

    // De-selects the detection, turns on the walkthrough and syncs the url correctly.
    const onClickAboutDetections = () => {
        setSelectedDetection(undefined)
        setWalkthroughOpen(!walkthroughOpen)
        navigate({
            pathname: getRouterLinks().detections.root({ eyed: activeEyed }),
        })
    }

    // UseEffect which sets the initial selected detection ( opens it in the sidebar ) if it's supplied in the url.
    // Specifically looks for the detection in the useDetectionV2ById hook.
    useEffect(() => {
        // If there's no detection found from the api, or no detection in the url we don't need to sync this.
        const selectedDetectionFromApi = selectedDetectionData?.data
        if (selectedDetection === undefined && initialUrlDetectionId !== undefined && selectedDetectionFromApi) {
            onSelectDetection(selectedDetectionFromApi)
        }
    }, [initialUrlDetectionId, selectedDetection, onSelectDetection, selectedDetectionData])

    // Displays the specific screen which explains the whole detections process. Only shows if there are no detections at all.
    // ( checks the statistic endpoint for the total count of detections )
    if (statisticsDataPending === false && hasDetections === false) {
        return <NoDetectionsWalkthrough />
    }

    return (
        <DetailPageLayout
            sideLabel={
                <div
                    onClick={onClickAboutDetections}
                    style={{ transformOrigin: "50% 51%" }}
                    className="hidden fixed right-[-59px] top-[210px] bg-background-page-secondary-light border border-text-secondary-light h-[35px] w-[150px] -rotate-90 md:flex justify-center items-center rounded-t-lg cursor-pointer z-30 hover:bg-white"
                >
                    <Typography variant="body-2-semibold" color="text-secondary">
                        {t("detections:aboutDetections")}
                    </Typography>
                </div>
            }
            sidebarContentActive={sidebarContentActive}
            sidebarContent={
                <div>
                    <Transition
                        show={selectedDetection !== undefined || isLoadingSingleDetectionData}
                        as="div"
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        {isLoadingSingleDetectionData && <SidebarSkeleton />}

                        {selectedDetection !== undefined && (
                            <DetectionDetails detection={selectedDetection} clearSelectedDetection={onCloseDetection} />
                        )}
                    </Transition>

                    {walkthroughOpen && isNullish(selectedDetection) && isLoadingSingleDetectionData === false && (
                        <DetectionsWalkthrough
                            onCloseWalkthrough={() => {
                                setWalkthroughOpen(false)
                            }}
                        />
                    )}
                </div>
            }
        >
            <PageHeader title={t("detections.title")} withBreadcrumbs />

            <DetectionsIntroModal />

            <div className="container mx-auto">
                <section className="flex items-stretch gap-4 w-full flex-wrap">
                    {/* Pie chart detections category widget */}
                    <DetectionsOverviewWidget
                        isLoading={statisticsDataPending}
                        detectionsByResolutionStatus={
                            statisticsData?.data?.count_detections_by_resolution_status_last_30_days
                        }
                        detectionsLast30Days={statisticsData?.data?.count_detections_last_30_days ?? 0}
                    />

                    {/* Bar Chart widget with detections per month */}
                    <HistoricalDetectionsWidget isLoading={statisticsDataPending} />
                </section>

                <section>
                    {/* Bar with all the filters and sorting buttons */}
                    <DetectionsFilterBar
                        activeDisplay={activeDisplay}
                        setActiveDisplay={setActiveDisplay}
                        dateRange={dateRange}
                        setSelectedDateRange={setSelectedDateRange}
                        hasCustomDateRange={hasCustomDateRange}
                        filterSettings={filterSettings}
                        setFiltersetting={setFiltersetting}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setSortBy={setSortBy}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                        setSortDirection={setSortDirection}
                    />

                    {/* Displays the cards or table view depending on the state */}
                    <div>
                        {activeDisplay === "cards" && (
                            <div className="space-y-3">
                                {eventsDataPending && <CardListSkeleton />}

                                {!eventsDataPending && (
                                    <DetectionCardsList
                                        detections={detectionEventsData?.data ?? []}
                                        onSelectDetection={onSelectDetection}
                                        selectedDetection={selectedDetection}
                                    />
                                )}
                            </div>
                        )}

                        {activeDisplay === "tables" && (
                            <div>
                                {eventsDataPending ? (
                                    <TableSkeleton />
                                ) : (
                                    <DetectionsV2Table
                                        selectedDetection={selectedDetection}
                                        data={detectionEventsData?.data ?? []}
                                        onClickRow={onSelectDetection}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </DetailPageLayout>
    )
}

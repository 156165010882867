import { Typography } from "@eyectrl/baseplate-design-system"
import classNames from "classnames"
import React from "react"

interface Props {
    hasLine?: boolean
    icon: React.ReactNode
    iconBackground: string
    content: string
    date?: string
    className?: string
}

export const TimelineItem: React.FC<Props> = ({ hasLine, icon, iconBackground, date, content, className }) => {
    return (
        <li>
            <div className={classNames(["relative", { "pb-8": hasLine }, className])}>
                {hasLine && (
                    <span
                        aria-hidden="true"
                        className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-border-primary-light"
                    />
                )}
                <div className="relative flex space-x-3 items-center">
                    <div>
                        <span
                            className={classNames([
                                iconBackground,
                                "flex h-8 w-8 items-center justify-center rounded-full",
                            ])}
                        >
                            {icon}
                        </span>
                    </div>
                    <div className="flex min-w-0 flex-1 flex-col bg-background-page-secondary-light/50 py-2 px-3 rounded-md">
                        <div>
                            <Typography variant="body-3" color="text-secondary">
                                {date}
                            </Typography>
                        </div>
                        <div className="text-sm text-gray-500">
                            <Typography variant="body-2">{content}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { getAwarenessMetricsEndpoint } from "@/api/endpoints"
import { getRequest } from "@/api/requests"
import { useAuth0 } from "@auth0/auth0-react"
import { IAwarenessMetric } from "../awarenessTypes"

export interface IAwarenessMetricsDataSource {
    data: IAwarenessMetric[]
    meta_data?: {
        last_updated?: string | null
    }
}

export const useAwarenessMetrics = (eyed: string): UseQueryResult<IAwarenessMetricsDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const awarenessMetricsEndpoint = getAwarenessMetricsEndpoint(eyed)

    return useQuery<IAwarenessMetricsDataSource, Error>({
        queryKey: awarenessMetricsEndpoint.queryKey,
        queryFn: () => getRequest(awarenessMetricsEndpoint.url, getAccessTokenSilently),
    })
}

import {
    NestedRouteRecord,
    RouteRecord,
    RouteRecordCollection,
    getAbstractRoutes,
    isRouteRecord,
} from "../config/routes/routeConfig"
import { matchPath, Location } from "react-router-dom"

export interface Breadcrumb {
    label: string
    to: string
}

const buildBreadCrumbArray = (
    record: RouteRecord | NestedRouteRecord,
    routeSections: string[],
    currentLocation: Location,
): Array<{ label: string; to: string } | undefined> => {
    if (isRouteRecord(record)) {
        // Takes the current pathname: for example: /recommendations/48a
        // and then matches it to the path pattern defined in the route: /recommendations/:id
        const pathMatch = matchPath(record.path, currentLocation.pathname)

        // If the current location.pathname matches with the route pattern, we can take that routes breadcrumbs to build our breadcrumb array.
        if (pathMatch !== null) {
            return record.breadcrumbs.map((breadcrumb, index) => {
                // We combine the breadcrumb defined in the route object with the actual path to create links to each breadcrumb.
                // +1 to compensate for the empty "" section, +1 for the /eyed section = +2
                const crumbs = routeSections.slice(0, index + 2)

                // We also add the eyed to the end of every url, this is not reflected in the breadcrumbs itself, but is reflected in the to path.
                return { label: breadcrumb, to: crumbs.join("/") }
            })
        }

        return [undefined]
    }

    return Object.values(record).flatMap((nestedRecord) => {
        return buildBreadCrumbArray(nestedRecord, routeSections, currentLocation)
    })
}

// Function that maps through all the routes and builds the breadcrumb array out of each route sections breadcrumb section
// Recursively goes through the entire routes structure to find all the nested breadcrumbs.
export const getBreadcrumbs = (currentLocation: Location, eyed: string): Breadcrumb[] => {
    // This gets the abstract representation of the routes, this is easier to work with on a generic level rather than the exact paths.
    const routes: RouteRecordCollection = getAbstractRoutes()
    // We go through all the object routing values and create breadcrumbs from them.
    const routeSections = currentLocation.pathname.split("/")

    return (
        Object.values(routes as RouteRecordCollection)
            .flatMap((value: RouteRecord | NestedRouteRecord) => {
                return buildBreadCrumbArray(value, routeSections, currentLocation)
            })
            // typescript does not recognise that undefined is filtered out.
            .filter((value) => value !== undefined)
            .map((value) => {
                // Go through all the breadcrumbs. If we encounter a url with only an eyed we return it to the dashboard
                if (value && value.to === `/${eyed}`) {
                    return {
                        ...value,
                        to: `/${eyed}/dashboard`,
                    }
                }

                return value
                // cast it to a Breadcrumb array. Typescript does not recognise that the filter takes out any undefined values
                // If we didn't cast it typescript still thinks this array can contain undefined values
            }) as Breadcrumb[]
    )
}

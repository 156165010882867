import { Disclosure, Transition, DisclosureButton, DisclosurePanel } from "@headlessui/react"
import React, { PropsWithChildren } from "react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"

interface Props extends PropsWithChildren {
    title: React.ReactNode
    buttonClassName?: string
    defaultOpen?: boolean
}

export const Collapse: React.FC<Props> = ({ title, children, buttonClassName = "", defaultOpen = false }) => {
    return (
        <Disclosure defaultOpen={defaultOpen}>
            {({ open }) => (
                <>
                    <DisclosureButton className={`${buttonClassName}`}>
                        {title}
                        <ChevronDownIcon className={`${open ? "rotate-180 transform" : ""} h-5 w-5 `} />
                    </DisclosureButton>
                    <Transition
                        as="div"
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <DisclosurePanel className="text-sm text-gray-500">{children}</DisclosurePanel>
                    </Transition>
                </>
            )}
        </Disclosure>
    )
}

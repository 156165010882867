import { UserContext } from "@/providers/UserContext"
import React, { Fragment, PropsWithChildren, useContext } from "react"
import { Navigate, generatePath, useParams } from "react-router-dom"

interface Props {
    path: string
}

/**
 * This component checks if an eyed is present in the current routes parameters
 * If this is not the case, it adds the initialEyed ( first eyed from the organisations call to the url eyed param )
 * @param param0
 * @returns
 */
export const EyedRoute: React.FC<PropsWithChildren<Props>> = ({ path, children }) => {
    const { eyed: paramsEyed, ...rest } = useParams()
    const { initialEyed } = useContext(UserContext)

    // if a eyed is already present in the url, we can safely render the component
    if (paramsEyed) {
        return <Fragment>{children}</Fragment>
    }

    // otherwise we enhance the same url, with the initial eyed ( which is the first org in the org endpoint data )
    return <Navigate to={generatePath(path, { eyed: initialEyed, ...rest })} />
}

import React, { useState } from "react"
import { IDetectionV2 } from "../types"
import { Pagination } from "@designSystem/pagination/Pagination"
import { getPagination } from "@utils/paginationUtils"
import { DetectionCard } from "./DetectionCard"
import useMediaQuery from "@hooks/useMediaQuery"
import { MEDIA_QUERIES } from "@hooks/mediaQueries"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import SearchIllustration from "@assets/illustrations/searchIllustration.svg?react"
import { useTranslation } from "react-i18next"

interface Props {
    detections: IDetectionV2[]
    onSelectDetection: (detectionToSelect: IDetectionV2) => void
    selectedDetection?: IDetectionV2
}

export const DetectionCardsList: React.FC<Props> = ({ detections, onSelectDetection, selectedDetection }) => {
    const [currentPage, setCurrentPage] = useState(0)
    const showColumns: boolean = useMediaQuery(MEDIA_QUERIES.xxxl)
    const { t } = useTranslation()

    // Page settings
    const pageSize = showColumns ? 8 : 4
    const numberOfPages = Math.ceil(detections.length / pageSize)
    const currentPageStart = currentPage * pageSize
    const currentPageEnd = currentPageStart + pageSize

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1)
    }

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1)
    }

    // Slices the detections into a slice of PAGE SIZE length with the start and end of the page set.
    const detectionsDataSlice = detections.slice(currentPageStart, currentPageEnd)

    // If the data fetching is still pending, the table component should not load. So we can safely show this warning.
    if (detections.length === 0) {
        return (
            <div className="bg-white border p-12 rounded-md">
                <NoDataMessage
                    title={t("detections:dataMessages.couldNotFindTitle")}
                    subtitle={t("detections:dataMessages.couldNotFindDescription")}
                    variant="row"
                    image={<SearchIllustration className="w-[250px]" />}
                />
            </div>
        )
    }

    return (
        <div>
            <div className="grid grid-cols-1 3xl:grid-cols-2 gap-4">
                {detectionsDataSlice.map((detection) => {
                    return (
                        <DetectionCard
                            key={`detection-card-${detection.id}`}
                            detection={detection}
                            isExpanded
                            onViewDetails={onSelectDetection}
                            isSelected={selectedDetection?.id === detection.id}
                            isCardClickable={true}
                        />
                    )
                })}
            </div>

            <div className="w-full flex justify-end mt-4">
                <Pagination
                    currentPage={currentPage + 1}
                    dataSize={detections.length ?? 0}
                    goToNextPage={goToNextPage}
                    goToPreviousPage={goToPreviousPage}
                    goToSpecificPage={(page) => {
                        // Offset by -1 since the table pagination is 0 indexed
                        setCurrentPage(page - 1)
                    }}
                    numberOfPages={numberOfPages}
                    pageSize={pageSize}
                    pagination={getPagination(detections.length, pageSize, currentPage + 1)}
                    showPageText={true}
                />
            </div>
        </div>
    )
}

import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { IDashboardMetrics } from "../dashboardTypes"
import { getDashboardMetricsEndpoint } from "@/api/endpoints"
import { useAuth0 } from "@auth0/auth0-react"
import { getRequest } from "@/api/requests"
import { useApplicationStore } from "@/stores/applicationStore"

export interface DashboardMetricDataSource {
    data: IDashboardMetrics
}

export const useDashboardMetrics = (eyed: string): UseQueryResult<DashboardMetricDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const riskStagingEnabled = useApplicationStore((state) => state.riskStagingEnabled)
    const dashboardEndpoint = getDashboardMetricsEndpoint(eyed, riskStagingEnabled)

    return useQuery<DashboardMetricDataSource, Error>({
        queryKey: dashboardEndpoint.queryKey,
        queryFn: () => getRequest(dashboardEndpoint.url, getAccessTokenSilently),
    })
}

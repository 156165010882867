import { postAwarenessRecipientsEndpoint } from "@/api/endpoints"
import { postRequest } from "@/api/requests"
import { useMutation } from "@tanstack/react-query"
import { IRecipient, RecipientRegistration } from "../awarenessTypes"
import { useAuth0 } from "@auth0/auth0-react"
import { useTranslation } from "react-i18next"
import { toastService } from "@/services/toastService"

export const usePostRecipients = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { t } = useTranslation()

    return useMutation({
        mutationFn: (variables: { eyed: string; recipients: RecipientRegistration[] }) => {
            const postRecipientsEndpoint = postAwarenessRecipientsEndpoint(variables.eyed)

            return postRequest<{ recipients: RecipientRegistration[] }, { data: IRecipient }>(
                postRecipientsEndpoint.url,
                variables,
                getAccessTokenSilently,
            )
        },
        onSuccess: () => {
            toastService.successMessage(t("toastMessages:recipients.success"))
        },
        onError: () => {
            toastService.errorMessage(t("toastMessages:recipients.error"))
        },
    })
}

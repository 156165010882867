import { Skeleton } from "./Skeleton"

export const MetricBlockSkeleton: React.FC = () => {
    return (
        <div className="flex-1 drop-shadow-sm bg-white rounded-lg">
            <div className=" border-b px-6 py-4 flex">
                <Skeleton className="h-[28px] w-[50%] bg-neutral-100" />
            </div>

            <div className="flex p-6 space-y-4 flex-col w-[100%]">
                <div className="flex space-x-6">
                    <Skeleton className="h-[56px] w-[100%] bg-neutral-100" />
                    <Skeleton className="h-[56px] w-[100%] bg-neutral-100" />
                </div>
            </div>
        </div>
    )
}

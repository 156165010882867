import { Toggle, Typography } from "@eyectrl/baseplate-design-system"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { UserSetting } from "./hooks/useUserSettings"
import { EyeOrganisation } from "@features/accountSwitcher/hooks/useUserOrganisations"
import { useAuth0 } from "@auth0/auth0-react"
import { useUserOrganistionSettingsMutation } from "./hooks/useUserOrganisationSettingsMutation"

interface Props {
    organisation: EyeOrganisation
    userSetting: UserSetting
}

export const NotificationPreference: React.FC<Props> = ({ organisation, userSetting }) => {
    const [notificationSetting, setNotificationSetting] = useState(userSetting.notification_interval)
    const { user } = useAuth0()
    const { t } = useTranslation(["common"])
    const isEnabled = notificationSetting !== "none"
    const userOrganisationSettingsMutation = useUserOrganistionSettingsMutation(organisation.eyed)

    const onChangeNotificationSetting = (setting: UserSetting["notification_interval"]): void => {
        setNotificationSetting(setting)

        userOrganisationSettingsMutation.mutate({
            notification_interval: setting,
            eyed: organisation.eyed,
            email: user?.email ?? "",
        })
    }

    return (
        <div className="p-6 bg-white-100">
            <Typography variant="header-3" spacingBottom textElipsis shouldPreventWrapping>
                {organisation.name}
            </Typography>

            <div className="mt-3">
                <Toggle
                    testId="preference-toggle"
                    checked={isEnabled}
                    onChange={() => {
                        if (isEnabled) {
                            onChangeNotificationSetting("none")
                        } else {
                            onChangeNotificationSetting("monthly")
                        }
                    }}
                    label={t("common:userMenu.notifications.byMail")}
                />
            </div>

            {/* TODO: When the backend is updated to handle WEEKLY/MONTHLY MAILS THIS CAN BE RE-ENABLED AGAIN */}
            {/* <Transition
                show={notificationSetting !== "none"}
                className="transition-all duration-500 overflow-hidden ease-in-out"
                enterFrom="transform max-h-0"
                enterTo="transform max-h-96"
                leaveFrom="transform max-h-96"
                leaveTo="transform  max-h-0"
            >
                <div className="mt-4 ml-16">
                    <Typography variant="header-5">Frequency</Typography>
                    <RadioInput
                        name={radioGroupName}
                        id={`weekly-${organisation.eyed}`}
                        label={t("common:userMenu.notifications.weekly")}
                        isChecked={notificationSetting === "weekly"}
                        onChange={() => {
                            onChangeNotificationSetting("weekly")
                        }}
                    />

                    <RadioInput
                        name={radioGroupName}
                        id={`monthly-${organisation.eyed}`}
                        label={t("common:userMenu.notifications.monthly")}
                        isChecked={notificationSetting === "monthly"}
                        onChange={() => {
                            onChangeNotificationSetting("monthly")
                        }}
                    />

                    <RadioInput
                        name={radioGroupName}
                        id={`yearly-${organisation.eyed}`}
                        label={t("common:userMenu.notifications.yearly")}
                        isChecked={notificationSetting === "yearly"}
                        onChange={() => {
                            onChangeNotificationSetting("yearly")
                        }}
                    />
                </div>
            </Transition> */}
        </div>
    )
}

import React from "react"
import { MetricBlock } from "../metricBlock/MetricBlock"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "react-i18next"
import { MetricBlockSkeleton } from "@designSystem/feedback/skeletons/MetricBlockSkeleton"
import { useThreatHuntsWithAssets } from "@features/threatHunting/hooks/useThreatHuntsWithAssets"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { useNavigate } from "react-router-dom"
import { MetricBlockWithMessage } from "../metricBlock/MetricBlockWithMessage"

interface Props {
    eyed: string
}

export const ThreatHuntingWidget: React.FC<Props> = ({ eyed }) => {
    const { t } = useTranslation()
    const { data, isPending, isError } = useThreatHuntsWithAssets(eyed)
    const threatHuntsCount = data?.data.filter((threatHunt) => threatHunt.acknowledged !== true).length
    const navigate = useNavigate()

    if (isPending) {
        return <MetricBlockSkeleton />
    }

    if (isError) {
        return (
            <MetricBlockWithMessage
                dataType="threatHunt"
                title={t(`dashboard.metrics.threatHunting.heading`)}
                variant="ERROR"
            />
        )
    }

    if (threatHuntsCount === 0) {
        return (
            <MetricBlockWithMessage
                dataType="threatHunt"
                title={t(`dashboard.metrics.threatHunting.heading`)}
                variant="COMPLETED"
            />
        )
    }

    return (
        <MetricBlock
            title={t(`dashboard.metrics.threatHunting.heading`)}
            testId="threat-hunt-widget"
            metrics={[
                {
                    value: `${threatHuntsCount}`,
                    color: "text-warning",
                    subtitle: t("dashboard.metrics.threatHunting.subtitle"),
                    infoIcon: (
                        <div>
                            <ExclamationTriangleIcon width={16} height={16} />
                        </div>
                    ),
                },
            ]}
            link={{
                value: t("dashboard.metrics.threatHunting.link"),
                onNavigate: () => {
                    navigate(getRouterLinks().threatHunting.root({ eyed }))
                },
            }}
        />
    )
}

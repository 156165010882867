import { Skeleton } from "./Skeleton"

export const ThreatHuntingSkeleton: React.FunctionComponent = () => {
    return (
        <div>
            <div className="flex flex-col gap-6 ">
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
            </div>
        </div>
    )
}

import { flatten, range } from "lodash"

export type PageOption = "ALL" | number

const START_INDEX = 1
const START_PADDING = 5
const END_PADDING = 4
export const DOTS = "..."

//  To visualise the below, the pagination is build up as follows:

// Let's say the number 5 is the active page
//               ↓
// [1, '...', 4, 5, 6, '...', 10]

// The pagination always shows the start and end. ( 1 & 10 in this case )
//  ↓                         ↓
// [1, '...', 4, 5, 6, '...', 10]

// If the current page is further away from the start / end than the padding it's given,
// it will have "friends" (4 & 6) assigned. These are the -1 and +1 indexes of the current page.
//            ↓     ↓
// [1, '...', 4, 5, 6, '...', 10]

// Then finally the dots will be placed, these are placed if there's a gap left between the "friends" and the
// start or end of the pagination.
//       ↓               ↓
// [1, '...', 4, 5, 6, '...', 10]

/**
 * Function that builds an array of numbers ( pages ) and dots that can be rendered as pagination buttons
 * Returns an array that looks like this: [1, '...', 4, 5, 6, '...', 10] based on the collection size, page size and the current page
 * @param collectionSize
 * @param pageSize
 * @param currentPageIndex
 * @returns
 */
export const getPagination = (
    collectionSize: number,
    pageSize: number,
    currentPageIndex: number,
): Array<number | string> => {
    const totalPageCount = Math.ceil(collectionSize / pageSize)

    // If the number of pages is lower than 7, we can simply show 7 buttons
    if (totalPageCount <= 7) {
        return range(START_INDEX, totalPageCount + 1)
    }

    // Adds a additional number ( friend ) next to the curently selected index.
    // Only show a left / right friend if the index is more than 2 away from the start/end
    const shouldGetLeftFriend = currentPageIndex >= 2
    const shouldGetRightFriend = currentPageIndex <= totalPageCount - 2

    // If the current selected page gets a friend, calculate it's index.
    const leftFriendIndex = shouldGetLeftFriend ? currentPageIndex - 1 : null
    const rightFriendIndex = shouldGetRightFriend ? currentPageIndex + 1 : null

    // The left friend's index is more than 2 steps away from the start
    const shouldGetLeftDots = leftFriendIndex !== null && leftFriendIndex >= 2
    // Index if the right friend is 2 items smaller than the total page count ( end of pagination )
    const shouldGetRightDots = rightFriendIndex !== null && rightFriendIndex <= totalPageCount - 2

    const baseCollection = flatten([
        START_INDEX, // Always show the first page number
        shouldGetLeftDots ? DOTS : range(START_INDEX, START_INDEX + START_PADDING, 1), // Shows the initial numbers if the dots don't need to be displayed.
        shouldGetLeftFriend ? leftFriendIndex : null, // Shows the left friend of the current index
        currentPageIndex, // shows the current index
        shouldGetRightFriend ? rightFriendIndex : null, // Shows the friend to the right of the current index
        shouldGetRightDots ? DOTS : range(totalPageCount - END_PADDING, totalPageCount, 1), // Shows the end padded numbers if the dots don't need to be displayed.
        totalPageCount, // Always show the last page number
    ])
        .filter((item) => item !== null) // Filters out any null values
        .sort((a, b) => {
            // Sorts the numbers in ascending order, while ignoring the strings ( DOTS )
            if (typeof a === "number" && typeof b === "number") {
                return a - b
            }

            return 0 // return 0 to not sort when we are dealing with strings. This way the dots stay in place and the numbers are sorted in asc order.
        }) as Array<number | string>

    // Go over the collection, throws out duplicate numbers, but keeps the string values ( DOTS ).
    const collectionWithUniqueNumbers = baseCollection.reduce(
        (previous, current) => {
            if (previous.includes(current) && typeof current === "number") {
                return [...previous]
            }
            return [...previous, current]
        },
        [] as Array<number | string>,
    )

    return collectionWithUniqueNumbers
}

/**
 * Function that slices the collection based on it's pageSize and the current page.
 * This function takes into account that pages are 1 indexed rather than 0 indexed.
 * @param collection
 * @param pageSize
 * @param currentPage
 * @returns
 */
export const getCollectionPage = <T>(collection: Array<T>, pageSize: PageOption, currentPage: number): Array<T> => {
    // pages are 1 index, when slicing we need to make it 0 indexed again
    const page = currentPage - 1
    const nextPage = page + 1

    if (pageSize === "ALL") {
        return collection
    }

    return collection.slice(page * pageSize, nextPage * pageSize)
}

import { isNullish } from "./formatUtils"

export interface IAnnouncement {
    title: string
    description: string
    announcementBarText: string
    date: string
    time: string
    moreInfoLink?: string
}

// Type guard to check the full contents of the json payload coming from the PostHog Feature flag.
// Checks if all the properties are present in the payload, otherwise it's not a valid announcement.
export const isAnnouncement = (payload: unknown): payload is IAnnouncement => {
    if (isNullish(payload)) {
        return false
    }

    return (
        payload !== undefined &&
        payload !== null &&
        typeof payload === "object" &&
        "title" in payload &&
        "description" in payload &&
        "announcementBarText" in payload &&
        "date" in payload &&
        "time" in payload
    )
}

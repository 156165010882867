import { useApplicationStore } from "@/stores/applicationStore"
import { useAuth0 } from "@auth0/auth0-react"
import { usePostHog } from "posthog-js/react"
import React, { PropsWithChildren, useEffect } from "react"
import * as Sentry from "@sentry/browser"

/**
 * Handles identification of users with external services (posthog/sentry), this is required for feature flagging purposes & error tracking.
 * We can only assign feature flags to specific users if they are identified with the posthog library.
 * Triggers once auth0 has finished logging in and provides a user object.
 */
export const UserIdentifier: React.FC<PropsWithChildren> = ({ children }) => {
    const { user } = useAuth0()
    const posthog = usePostHog()
    const isUserIdentified = useApplicationStore((state) => state.isUserIdentified)
    const setIsUserIdentified = useApplicationStore((state) => state.setIsUserIdentified)

    const identifyUserWithExternalServices = React.useCallback(() => {
        if (user) {
            console.log(`[PORTAL] Identified user ${user.email}`)
            // Identify user with Posthog
            posthog.identify(user.email, {
                email: user.email,
            })

            // Identify User with sentry
            Sentry.setUser({ email: user.email })

            setIsUserIdentified(true)
        }
    }, [user, posthog, setIsUserIdentified])

    // Handles identifying users with posthog once the component renders ( after auth0 handles it's logging in. )
    useEffect(() => {
        if (isUserIdentified === false) {
            identifyUserWithExternalServices()
        }
    }, [identifyUserWithExternalServices, isUserIdentified])

    return children
}

import React from "react"
import { getResolutionStatusText } from "@utils/detectionUtils"
import { isNullish } from "@utils/formatUtils"
import classNames from "classnames"
import { ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/react/24/outline"

import Tippy from "@tippyjs/react"

interface Props {
    status: string | null
}

const statusIconClass = "p-1.5 items-center justify-center rounded-md inline-block"

export const ResolutionStatusIcon: React.FC<Props> = ({ status }) => {
    if (isNullish(status)) {
        return (
            <div className={`bg-background-grey-light text-text-secondary-light ${statusIconClass}`}>
                <InformationCircleIcon width={22} />
            </div>
        )
    }

    const resulutionStatusDescription = getResolutionStatusText(status)

    return (
        <Tippy content={resulutionStatusDescription.description}>
            <div
                className={classNames(
                    {
                        "bg-background-grey-light text-text-secondary-light":
                            resulutionStatusDescription.label === "Normal behaviour",
                    },
                    {
                        "bg-background-warning-light text-text-warning-light":
                            resulutionStatusDescription.label === "Abnormal behaviour",
                    },
                    {
                        "bg-background-critical-light text-text-critical-light":
                            resulutionStatusDescription.label === "Malicious behaviour",
                    },
                    statusIconClass,
                )}
            >
                {resulutionStatusDescription.label === "Malicious behaviour" ? (
                    <ExclamationTriangleIcon width={22} />
                ) : (
                    <InformationCircleIcon width={22} />
                )}
            </div>
        </Tippy>
    )
}

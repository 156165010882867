import { PageLayout } from "@components/layout/PageLayout"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import React from "react"
import WaitingResultsIllustration from "@assets/illustrations/waitingResultsIllustration.svg?react"
import { useTranslation } from "react-i18next"

export const AwarenessWaitingForResults: React.FC = () => {
    const { t } = useTranslation()

    return (
        <PageLayout variant="detailPage">
            <NoDataMessage
                title={t("pages:awareness.waitingForResults.title")}
                subtitle={t("pages:awareness.waitingForResults.subtitle")}
                variant="column"
                image={<WaitingResultsIllustration className="w-[250px]" />}
            />
        </PageLayout>
    )
}

import React from "react"
import { IDashboardMetrics } from "../../dashboardTypes"
import { MetricBlock } from "../metricBlock/MetricBlock"
import { isNullish } from "@utils/formatUtils"
import { InformationCircleIcon } from "@heroicons/react/24/solid"
import Tippy from "@tippyjs/react"
import { Trans, useTranslation } from "react-i18next"
import { Typography } from "@eyectrl/baseplate-design-system"
import { calculatePercentage } from "@utils/calculationUtils"
import { getDiskEncryptionVerdictRange, getMetricVerdictColor } from "@utils/dashboardUtils"
import { MetricBlockSkeleton } from "@designSystem/feedback/skeletons/MetricBlockSkeleton"
import { MetricBlockWithMessage } from "../metricBlock/MetricBlockWithMessage"

interface Props {
    metric?: IDashboardMetrics["disk_encryption"]
    isError?: boolean
    showSkeleton?: boolean
    getRecommendationLink: (
        recommendationId: string | undefined | null,
        linkText: string,
    ) => { value: string; onNavigate?: () => void }
    renderIntroductionHotspot?: () => JSX.Element
}

export const DiskEncryptionWidget: React.FC<Props> = ({
    metric,
    getRecommendationLink,
    showSkeleton = false,
    renderIntroductionHotspot,
    isError = false,
}) => {
    const { t } = useTranslation()

    if (showSkeleton) {
        return <MetricBlockSkeleton />
    }

    if (isNullish(metric) || metric.verdict === "Unknown") {
        return <MetricBlockWithMessage title={t(`dashboard.metrics.disk_encryption.heading`)} variant="NO_DATA" />
    }

    if (isError) {
        return <MetricBlockWithMessage title={t(`dashboard.metrics.disk_encryption.heading`)} variant="ERROR" />
    }

    const encryptionRange = getDiskEncryptionVerdictRange(metric.verdict)
    const verdictColor = getMetricVerdictColor(metric.verdict)
    const verdictPercentage = `${calculatePercentage(metric.covered, metric.total)}%`

    return (
        <MetricBlock
            testId="disk-encryption-widget"
            title={t(`dashboard.metrics.disk_encryption.heading`)}
            renderIntroductionHotspot={renderIntroductionHotspot}
            metrics={[
                {
                    value: metric.verdict,
                    color: verdictColor,
                    subtitle: `${metric.covered} / ${metric.total} ${t("dashboard.metrics.disk_encryption.portableDevices")}`,
                    infoIcon: (
                        <div>
                            <Tippy
                                interactive
                                content={
                                    <div className="p-2 w-[325px]">
                                        <Typography color="text-invert" variant="body-2-semibold">
                                            {t("dashboard.metrics.disk_encryption.tooltip.title", {
                                                amount: verdictPercentage,
                                            })}
                                        </Typography>
                                        <Typography color="text-invert" variant="body-2-semibold">
                                            <Trans
                                                values={{
                                                    amount_1: encryptionRange.from,
                                                    amount_2: encryptionRange.to,
                                                    verdict: metric.verdict.toLowerCase(),
                                                }}
                                                i18nKey="dashboard.metrics.disk_encryption.tooltip.description"
                                                components={[
                                                    <a
                                                        href="https://kb.eye.security/how-eye-recommendations-are-generated"
                                                        key="kb-link"
                                                        className="inline-block underline"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    />,
                                                ]}
                                            />
                                        </Typography>
                                    </div>
                                }
                                placement={"auto"}
                            >
                                <InformationCircleIcon width={16} height={16} />
                            </Tippy>
                        </div>
                    ),
                },
            ]}
            link={getRecommendationLink(metric.recommendation_id, t("dashboard.metrics.disk_encryption.link"))}
        />
    )
}

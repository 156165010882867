import { withAuthenticationRequired } from "@auth0/auth0-react"

interface ProtectedRouteProps {
    component: React.FunctionComponent
}

export const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({ component }) => {
    const Component = withAuthenticationRequired(component)
    return <Component />
}

import React from "react"
import { Skeleton } from "./Skeleton"

export const BarChartSkeleton: React.FC = () => {
    return (
        <div className="w-full h-full flex justify-between items-end">
            <Skeleton className="h-[95%] w-[25px] rounded-b-none" />
            <Skeleton className="h-[60%] w-[25px] rounded-b-none" />
            <Skeleton className="h-[50%] w-[25px] rounded-b-none" />
            <Skeleton className="h-[80%] w-[25px] rounded-b-none" />
            <Skeleton className="h-[40%] w-[25px] rounded-b-none" />
            <Skeleton className="h-[70%] w-[25px] rounded-b-none" />
            <Skeleton className="h-[79%] w-[25px] rounded-b-none hidden lg:block" />
            <Skeleton className="h-[85%] w-[25px] rounded-b-none hidden lg:block" />
            <Skeleton className="h-[25%] w-[25px] rounded-b-none hidden lg:block" />
            <Skeleton className="h-[95%] w-[25px] rounded-b-none hidden lg:block" />
            <Skeleton className="h-[60%] w-[25px] rounded-b-none hidden lg:block" />
            <Skeleton className="h-[50%] w-[25px] rounded-b-none hidden lg:block" />
            <Skeleton className="h-[80%] w-[25px] rounded-b-none hidden xl:block" />
            <Skeleton className="h-[40%] w-[25px] rounded-b-none hidden xl:block" />
            <Skeleton className="h-[70%] w-[25px] rounded-b-none hidden xl:block" />
            <Skeleton className="h-[79%] w-[25px] rounded-b-none hidden xl:block" />
            <Skeleton className="h-[85%] w-[25px] rounded-b-none hidden xl:block" />
            <Skeleton className="h-[25%] w-[25px] rounded-b-none hidden xl:block" />
        </div>
    )
}

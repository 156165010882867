import React, { PropsWithChildren } from "react"
import { NavLink } from "react-router-dom"

interface Props {
    to: string
    getClassName: (isActive: boolean) => string
    onClick?: () => void
}

const getIsExternalLink = (link: string): boolean => {
    return link.startsWith("https://")
}

const NavigationLink: React.FC<PropsWithChildren<Props>> = ({ children, to, getClassName, onClick }) => {
    const isExternalLink: boolean = getIsExternalLink(to)

    if (isExternalLink) {
        return (
            <a href={to} className={getClassName(false)} target="_blank" rel="noreferrer">
                {children}
            </a>
        )
    }

    return (
        <NavLink to={to} className={({ isActive }) => getClassName(isActive)} onClick={onClick}>
            {children}
        </NavLink>
    )
}

export default NavigationLink

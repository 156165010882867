import React from "react"

export type Ref = HTMLInputElement

interface Props {
    icon?: React.ReactNode
}

export const TextArea = React.forwardRef<
    Ref,
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & Props
>((props, ref) => {
    return (
        <div className="relative rounded-md shadow-sm" ref={ref}>
            <textarea
                {...props}
                rows={4}
                name="comment"
                id="comment"
                className={`${
                    props.className ?? ""
                } block w-full rounded-md border-0 py-2.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-brand-eye-orange text-base sm:leading-6`}
            />
        </div>
    )
})

TextArea.displayName = "TextArea"

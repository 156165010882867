import { IThreatHunt, IThreatHuntWithAssets } from "../threatHuntingTypes"
import { useThreatHunts } from "./useThreatHunts"
import { useThreatHuntsWithAssets } from "./useThreatHuntsWithAssets"

/**
 * Fetches both threat hunt data sources and searches for the selected threat hunt by id.
 * @param eyed
 * @param id
 * @returns
 */
export const useSingleThreatHunt = (eyed: string, id: string) => {
    const threatHuntsQuery = useThreatHunts(eyed)
    const threatHuntsWithAssets = useThreatHuntsWithAssets(eyed)

    const allThreatHunts = threatHuntsQuery.data?.data ?? []
    const allThreatHuntsWithAssets = threatHuntsWithAssets.data?.data ?? []
    const combinedThreatHunts: Array<IThreatHunt | IThreatHuntWithAssets> = [
        ...allThreatHunts,
        ...allThreatHuntsWithAssets,
    ]
    const selectedThreatHunt = combinedThreatHunts.find((threatHunt) => threatHunt.uuid === id)

    const isLoading = threatHuntsQuery.isLoading || threatHuntsWithAssets.isLoading

    return { isLoading, selectedThreatHunt }
}

import { useAuth0 } from "@auth0/auth0-react"
import { Button } from "@eyectrl/baseplate-design-system"
import { Typography } from "@eyectrl/baseplate-design-system"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import EyeServersIllustration from "@assets/illustrations/eyeServersIllustration.svg?react"
import { useMutation } from "@tanstack/react-query"
import { getFeedbackEndpoint } from "@/api/endpoints"
import { postRequest } from "@/api/requests"
import Modal from "@designSystem/overlays/modal/Modal"
import SuccessIllustration from "@/assets/illustrations/successIllustration.svg?react"
import { toastService } from "@/services/toastService"

interface Props {
    logoutUser: () => void
}

export const LoginErrorMessage: React.FC<Props> = ({ logoutUser }) => {
    const { t } = useTranslation()
    const [isSuccessModalActive, setIsSuccessModalActive] = useState(false)

    // State handling
    const { user, getAccessTokenSilently } = useAuth0()

    const { mutate, isPending } = useMutation({
        mutationFn: () => {
            const information = {
                name: user?.name ?? "",
                email: user?.email ?? "",
                message: `${user?.name} is requesting access to the Portal`,
                score: 5,
                url: window.location.href ?? "",
            }

            const feedbackEndpoint = getFeedbackEndpoint()
            return postRequest(feedbackEndpoint.url, information, getAccessTokenSilently)
        },
        onSuccess: () => {
            setIsSuccessModalActive(true)
        },
        onError: () => {
            toastService.errorMessage(t("loginPage:requestAccessModal.error"))
        },
    })

    return (
        <div className="flex items-center p-12 md:p-20 xl:p-32">
            <Modal open={isSuccessModalActive} setOpen={setIsSuccessModalActive} className="p-10" withCloseButton>
                <div className="w-[400px]">
                    <div className="flex justify-center mb-4">
                        <SuccessIllustration />
                    </div>

                    <Typography variant="header-2">{t("loginPage:requestAccessModal.title")}</Typography>
                    <Typography className="mt-4">{t("loginPage:requestAccessModal.description")}</Typography>
                </div>
            </Modal>

            <div className="max-w-[500px]">
                <Typography variant="display-2">{t("loginPage:title")}</Typography>
                <div className="mt-4">
                    <Typography className="mb-4">{t("loginPage:description_start")}</Typography>
                    <Trans
                        i18nKey={"loginPage:description_end"}
                        components={[
                            <a
                                key="mail-link"
                                className="inline-block underline text-text-link-light"
                                href="mailto:support@eye.security"
                            ></a>,
                            <a
                                key="phone-link"
                                className="inline-block underline text-text-link-light"
                                href="tel:+31886444888"
                            ></a>,
                        ]}
                    />
                </div>
                <div className="flex mt-5 items-center gap-4">
                    <Button
                        variant="primary"
                        onClick={() => {
                            logoutUser()
                        }}
                    >
                        {t("loginPage:button")}
                    </Button>

                    <Button variant="secondary" isLoading={isPending} onClick={() => mutate()}>
                        {t("loginPage:requestAccess")}
                    </Button>
                </div>
            </div>

            <EyeServersIllustration className="hidden ml-20 lg:block lg:w-[300px] xl:w-[500px]" />
        </div>
    )
}

import React from "react"

interface Props {
    className?: string
    roundedClass?: string
}

export const Skeleton: React.FC<React.PropsWithChildren<Props>> = ({
    className,
    roundedClass = "rounded-md",
    children,
}) => {
    return <div className={`skeleton-shimmer bg-neutral-100 ${roundedClass}  ${className}`}>{children}</div>
}

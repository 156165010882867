import { Typography } from "@eyectrl/baseplate-design-system"
import React from "react"

interface Props {
    name: string
    size?: "sm" | "md" | "lg"
    testid?: string
    className?: string
    onClick?: () => void
}

const sizeClassMapping = {
    sm: "h-7 w-7",
    md: "h-10 w-10",
    lg: "h-[80px] w-[80px]",
}

const Avatar: React.FC<Props> = ({ name, onClick, size = "sm", testid, className = "" }) => {
    const getInitials = (value: string): string => {
        if (name === "") {
            return name
        }

        const containsDots = value.includes(".")
        const containsSpaces = value.includes(" ")
        const splitter = containsDots ? "." : containsSpaces ? " " : ""

        const sections = value.split(splitter)

        if (sections.length > 1) {
            // returns first character of first section + first character of second section
            return `${sections[0][0]}${sections[1][0]}`
        }

        // otherwise return the first char of the supplied value.
        return value[0]
    }

    const sizeClass = sizeClassMapping[size]

    return (
        <span
            data-testid={testid}
            className={`inline-flex items-center justify-center rounded-full bg-brand-eye-orange cursor-pointer relative ${sizeClass} ${className}`}
            onClick={onClick}
        >
            <Typography variant="body-2" color="text-invert">
                {getInitials(name)}
            </Typography>
        </span>
    )
}

export default Avatar

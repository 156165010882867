import { SelectMenu } from "@designSystem/overlays/SelectMenu"
import { Typography } from "@eyectrl/baseplate-design-system"
import { PageOption } from "@utils/paginationUtils"
import React, { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"

interface Props {
    options: Array<string | number>
    selectedOption: string | number
    hideBar?: boolean
    onSelectOption: (size: PageOption) => void
}

export const PaginationBar: React.FC<PropsWithChildren<Props>> = ({
    children,
    options,
    selectedOption,
    onSelectOption,
    hideBar = false,
}) => {
    const { t } = useTranslation(["common"])
    if (hideBar) {
        return null
    }

    return (
        <div className="flex justify-between p-4 space-x-4  border-t-[1px]">
            <div className="flex items-center space-x-2">
                <div>
                    <Typography color="text-secondary">{t("common:pagination.view")}</Typography>
                </div>
                <SelectMenu
                    options={options}
                    selectedOption={selectedOption}
                    onSelectOption={(option) => {
                        if (option === "ALL" || typeof option === "number") {
                            onSelectOption(option)
                        }
                    }}
                />
                <div>
                    <Typography color="text-secondary" className="hidden lg:block">
                        {t("common:pagination.rowsPerPage")}
                    </Typography>
                </div>
            </div>
            {children}
        </div>
    )
}

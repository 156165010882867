import React, { PropsWithChildren } from "react"

export const TimelineWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="flow-root">
            <ul role="list" className="mb-8">
                {children}
            </ul>
        </div>
    )
}

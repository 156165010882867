export enum Env {
    production = "production",
    test = "test",
    development = "development",
}

export function getEnv(): Env {
    const url = window.location.origin

    if (
        url.includes("portal.eye.security") ||
        url.includes(".dwdh1713fy1zc.amplifyapp.com") ||
        url.includes("portal.prd.eyedra.io") ||
        url.includes(".dsskqhi65zreg.amplifyapp.com")
    ) {
        return Env.production
    }

    if (
        url.includes("portal.tst.eye.security") ||
        url.includes(".d2d7aajnx44tqe.amplifyapp.com") ||
        url.includes("portal.tst.eyedra.io") ||
        url.includes(".d31s0bnpnx93o2.amplifyapp.com")
    ) {
        return Env.test
    }

    return Env.development
}

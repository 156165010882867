import React, { useContext, useState } from "react"
import { Hotspot } from "./Hotspot"
import { IHotspotProps } from "./hotspotTypes"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { UserContext } from "@/providers/UserContext"

// This is a special kind of Hotspot component
// It checks a feature flag to determine if it should be showed, and also saves a viewed state in the local storage.
export const IntroductionHotspot: React.FC<IHotspotProps> = ({ buttonText, description, hotspotId, title }) => {
    const featureFlagEnabled = useFeatureFlagEnabled(featureFlagConfig.dashboardHotspotTour)
    const { userProfile, updateUserProfileProperty } = useContext(UserContext)
    const hotspotAlreadyVisited =
        hotspotId === "METRICS_HOTSPOT"
            ? userProfile?.has_closed_metrics_hotspot
            : userProfile?.has_closed_management_reporting_hotspot

    // Check the local storage to see if the hotspot has been visited before.
    const [hotspotVisited, setHotspotVisited] = useState<boolean>(hotspotAlreadyVisited)

    const onHotspotButtonClick = React.useCallback(() => {
        if (hotspotVisited) {
            return
        }

        if (hotspotId === "METRICS_HOTSPOT") {
            updateUserProfileProperty({ has_closed_metrics_hotspot: true })
        }

        if (hotspotId === "MANGEMENT_REPORTING_HOTSPOT") {
            updateUserProfileProperty({ has_closed_management_reporting_hotspot: true })
        }

        setHotspotVisited(true)
    }, [hotspotVisited, updateUserProfileProperty, hotspotId])

    // Checks if the hotspot has been visited before
    // Checks if the feature flag is disabled
    // If either case applies, we don't show the hotspot.
    if (hotspotVisited === true || featureFlagEnabled !== true) {
        return null
    }

    return (
        <Hotspot
            buttonText={buttonText}
            description={description}
            hotspotId={hotspotId}
            title={title}
            onHotspotButtonClick={onHotspotButtonClick}
        />
    )
}

import i18n from "@/i18next"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { useActiveEyed } from "@hooks/useActiveEyed"
import Tippy from "@tippyjs/react"
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline"
import copy from "copy-to-clipboard"

export const CopyHuntLinkTableAction: React.FC<{ uuid: string }> = ({ uuid }) => {
    const routerLinks = getRouterLinks()
    const activeEyed = useActiveEyed()

    return (
        <div className="flex justify-end">
            <Tippy content={i18n.t("pages:detections.table.copyReference")}>
                <div
                    className="cursor-pointer text-text-primary-light hover:text-brand-eye-blue flex justify-center"
                    onClick={(event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        // Gets the routerlink formatted as "EyeD-Prd-ABC-1234567890/threat-hunting"
                        const detailPagePath = routerLinks.threatHunting.detail({ eyed: activeEyed, id: uuid })
                        // Build the full link by adding the window origin "http://localhost:1234" + the routerlink to get the full path.
                        const fullLink = `${window.location.origin}${detailPagePath}`
                        copy(fullLink)
                    }}
                >
                    <DocumentDuplicateIcon width={18} height={18} />
                </div>
            </Tippy>
        </div>
    )
}

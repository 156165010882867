import { PageHeader } from "@components/header/PageHeader"
import { Dropdown, DropdownListItem, DropdownPanel, Typography } from "@eyectrl/baseplate-design-system"
import React from "react"
import { useTranslation } from "react-i18next"
import { useAwarenessLanguages } from "../hooks/useAwarenessLanguages"
import { IAwarenessLanguage } from "../awarenessTypes"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { usePatchAwarenessState } from "../hooks/usePatchAwarenessState"
import { useActiveEyed } from "@hooks/useActiveEyed"

interface Props {
    selectedLanguage: IAwarenessLanguage | undefined
    setSelectedLanguage: (language: IAwarenessLanguage) => void
}

export const LanguageSettingsStep: React.FC<Props> = ({ selectedLanguage, setSelectedLanguage }) => {
    const { t } = useTranslation()
    const { data } = useAwarenessLanguages()
    const languages = data?.data ?? []
    const { mutate } = usePatchAwarenessState()
    const activeEyed = useActiveEyed()

    return (
        <div className="">
            <PageHeader title={t("awareness.onboarding.steps.languageSelectionStep.title")} />
            <Typography>{t("awareness.onboarding.steps.languageSelectionStep.description")}</Typography>

            <div className="relative mt-8">
                <Dropdown
                    button={
                        <div className="h-[36px] w-[400px] border rounded-md px-4 items-center flex gap-2 justify-between">
                            <div className="flex gap-2 items-center">
                                {selectedLanguage
                                    ? selectedLanguage.Name
                                    : t("awareness.onboarding.steps.languageSelectionStep.chooseLanguage")}
                            </div>
                            <ChevronDownIcon height={16} width={16} className="text-brand-eye-blue" />
                        </div>
                    }
                >
                    {(onClosePanel) => {
                        return (
                            <DropdownPanel className="max-h-[300px] overflow-x-auto">
                                {languages.map((language) => {
                                    return (
                                        <DropdownListItem
                                            size="large"
                                            // className="px-2 py-1 rounded-md hover:bg-brand-eye-orange cursor-pointer hover:text-invert"
                                            key={language.Name}
                                            onClick={() => {
                                                setSelectedLanguage(language)
                                                mutate({ eyed: activeEyed, update: { default_language: language } })
                                                onClosePanel()
                                            }}
                                        >
                                            {language.Name}
                                        </DropdownListItem>
                                    )
                                })}
                            </DropdownPanel>
                        )
                    }}
                </Dropdown>
            </div>
        </div>
    )
}

import { Typography } from "@eyectrl/baseplate-design-system"
import React from "react"
import { useTranslation } from "react-i18next"
import { ActionListItem } from "./ActionListItem"
import { Button } from "@eyectrl/baseplate-design-system"
import CheckListIcon from "@assets/icons/checkListIcon.svg?react"
import { ArrowRightIcon } from "@heroicons/react/24/solid"
import { getOnboardingProgress, IOnboardingState } from "@utils/onboardingActionListUtils"
import { useNavigate } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { useActiveEyed } from "@hooks/useActiveEyed"

interface Props {
    onboardingState: IOnboardingState
}

const WidgetTitle: React.FC<{ completedPercentage: number }> = ({ completedPercentage }) => {
    const { t } = useTranslation()

    if (completedPercentage === 100) {
        return <Typography variant="header-5">{t("dashboardMetrics:actionList.titles.completed")}</Typography>
    }

    if (completedPercentage >= 50) {
        return <Typography variant="header-5">{t("dashboardMetrics:actionList.titles.almostThere")}</Typography>
    }

    return <Typography variant="header-5">{t("dashboardMetrics:actionList.titles.timeToStart")}</Typography>
}

export const ActionListWidget: React.FC<Props> = ({ onboardingState }) => {
    const { t } = useTranslation()
    const progress = getOnboardingProgress(onboardingState)
    const navigate = useNavigate()
    const activeEyed = useActiveEyed()

    return (
        <div className="bg-background-brand-tertiary-light p-6 rounded-md flex-1">
            <div className="flex items-center gap-4">
                <div>
                    <CheckListIcon />
                </div>
                <div>
                    <WidgetTitle completedPercentage={progress.percentage} />

                    <Typography variant="body-2">
                        {t("dashboardMetrics:actionList.subtitle", { amount: progress.label })}
                    </Typography>
                </div>
            </div>

            <div className="mt-4 mb-4" aria-hidden="true">
                <div className="overflow-hidden rounded-full bg-white">
                    <div
                        className="h-2 rounded-full bg-background-brand-secondary-light"
                        style={{ width: `${progress.percentage.toFixed()}%` }}
                    />
                </div>
            </div>

            <div>
                <Typography variant="body-2-semibold">{t("dashboardMetrics:actionList.actionRequired")}</Typography>

                <div className="space-y-4 mt-4">
                    <ActionListItem
                        status={onboardingState.serviceOnboarding === "ONBOARDING_COMPLETED" ? "COMPLETED" : "TODO"}
                        title={t("dashboardMetrics:actionList.actions.serviceOnboarding.title")}
                        subtitle={t("dashboardMetrics:actionList.actions.serviceOnboarding.subtitle")}
                        renderActionLink={() => (
                            <a target="_blank" rel="noopener noreferrer" href="https://onboarding.eye.security/">
                                <Button
                                    variant="text"
                                    size="small"
                                    rightContent={<ArrowRightIcon width={16} />}
                                    disabled={onboardingState.serviceOnboarding === "ONBOARDING_COMPLETED"}
                                >
                                    {t("dashboardMetrics:actionList.options.continue")}
                                </Button>
                            </a>
                        )}
                    />

                    {onboardingState.awarenessOnboarding !== "ONBOARDING_NOT_APPLICABLE" && (
                        <ActionListItem
                            status={
                                onboardingState.awarenessOnboarding === "ONBOARDING_COMPLETED" ? "COMPLETED" : "TODO"
                            }
                            title={t("dashboardMetrics:actionList.actions.awareness.title")}
                            subtitle={t("dashboardMetrics:actionList.actions.awareness.subtitle")}
                            renderActionLink={() => (
                                <Button
                                    variant="text"
                                    size="small"
                                    disabled={onboardingState.awarenessOnboarding === "ONBOARDING_COMPLETED"}
                                    rightContent={<ArrowRightIcon width={16} />}
                                    onClick={() => {
                                        navigate(getRouterLinks().awareness.root({ eyed: activeEyed }))
                                    }}
                                >
                                    {t("dashboardMetrics:actionList.options.continue")}
                                </Button>
                            )}
                        />
                    )}

                    {onboardingState.sentinelOnboarding !== "ONBOARDING_NOT_APPLICABLE" && (
                        <ActionListItem
                            status={
                                onboardingState.sentinelOnboarding === "ONBOARDING_COMPLETED"
                                    ? "COMPLETED"
                                    : onboardingState.sentinelOnboarding === "OPERATIONS_ONBOARDING_IN_PROGRESS"
                                      ? "IN_PROGRESS"
                                      : "TODO"
                            }
                            title={t("dashboardMetrics:actionList.actions.sentinel.title")}
                            subtitle={t("dashboardMetrics:actionList.actions.sentinel.subtitle")}
                            renderActionLink={() => (
                                <Button
                                    variant="text"
                                    size="small"
                                    rightContent={<ArrowRightIcon width={16} />}
                                    onClick={() => {
                                        navigate(getRouterLinks().sentinelOnboarding({ eyed: activeEyed }))
                                    }}
                                >
                                    {onboardingState.sentinelOnboarding !== "AZURE_SUBSCRIPTION_REQUESTED"
                                        ? t("dashboardMetrics:actionList.options.view")
                                        : t("dashboardMetrics:actionList.options.continue")}
                                </Button>
                            )}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

import { UrlSyncableProperties, getAllAllowedSearchParams } from "@utils/urlUtils"
import { useSearchParams } from "react-router-dom"

/**
 * Wrapper hook around the getAllAllowedSearchParams utility function which feeds it the react-router search params.
 * @returns
 */
export const useGetAllSearchParams = (): Partial<Record<UrlSyncableProperties, string>> => {
    const [searchParams] = useSearchParams()

    // Takes the search params, filters out any invalid params and nullish values and then returns the leftover properties.
    return getAllAllowedSearchParams(searchParams)
}

import React from "react"
import { usePostHog } from "posthog-js/react"
import { useApplicationStore } from "@/stores/applicationStore"

export const useSetupPostHog = () => {
    const posthog = usePostHog()
    const setPostHogConfigured = useApplicationStore((state) => state.setPostHogConfigured)
    const setShowConsentBanner = useApplicationStore((state) => state.setShowConsentBanner)
    const hasOptedIn = posthog.has_opted_in_capturing()
    const hasOptedOut = posthog.has_opted_out_capturing()

    // Sets up posthog with analytics consent ( so autocapture will be turned on and the consent config will be loaded)
    const setupPostHogWithConsent = React.useCallback(() => {
        // If the user is already opted in, we don't need to send this event again.
        if (hasOptedIn === false) {
            // This in turn enables the auto capturing of events
            posthog.opt_in_capturing()
        }

        console.log(`[PORTAL] Capturing: ${posthog.has_opted_in_capturing()}`)
        setShowConsentBanner(false)
        setPostHogConfigured(true)
    }, [posthog, setPostHogConfigured, setShowConsentBanner, hasOptedIn])

    // Sets up posthog without analytics consent ( so autocapture will be turned off and the non-consent config will be loaded)
    const setupPostHogWithoutConsent = React.useCallback(() => {
        posthog.opt_out_capturing()
        console.log(`[PORTAL] Capturing: ${posthog.has_opted_in_capturing()}`)
        setShowConsentBanner(false)
        setPostHogConfigured(true)
    }, [posthog, setPostHogConfigured, setShowConsentBanner])

    return {
        setupPostHogWithConsent,
        setupPostHogWithoutConsent,
        hasOptedIn,
        hasOptedOut,
    }
}

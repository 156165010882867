import React, { useEffect, useState } from "react"
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react"
import { useAwarenessEvents } from "../hooks/useAwarenessEvents"
import { TabButton } from "@designSystem/layout/tabs/TabButton"
import { EventsTable } from "./EventsTable"
import { useTranslation } from "react-i18next"
import { IAwarenessEvent } from "../awarenessTypes"

interface Props {
    activeEyed: string
    selectedMonth: string
    setEventsLastUpdated: (lastUpdated: string) => void
}

const translateIndexToEventType = (index: number): IAwarenessEvent["type"] => {
    if (index === 0) {
        return "sent"
    }

    if (index === 1) {
        return "reported"
    }

    return "phished"
}

export const EventsTableSection: React.FC<Props> = ({ activeEyed, selectedMonth, setEventsLastUpdated }) => {
    const { t } = useTranslation()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const selectedEventType = translateIndexToEventType(selectedIndex)

    const {
        data: paginatedEventsData,
        hasNextPage,
        fetchNextPage,
        isPending,
        isFetching,
    } = useAwarenessEvents(activeEyed, selectedMonth, selectedEventType)

    // Reports the last_updated timestamp to the parent component when data changes.
    useEffect(() => {
        const lastUpdatedTimestamp = paginatedEventsData?.pages[0]?.meta_data?.last_updated
        if (lastUpdatedTimestamp) {
            setEventsLastUpdated(lastUpdatedTimestamp)
        }
    }, [paginatedEventsData, setEventsLastUpdated])

    // Flattens all the pages data into a single array of events
    const eventsData: IAwarenessEvent[] = React.useMemo(() => {
        return (
            paginatedEventsData?.pages.flatMap((pageData) => {
                return pageData.data
            }) ?? []
        ).filter((event) => event !== null) as IAwarenessEvent[] // filter out potential null values ( as the backend might return null instead of an empty array )
    }, [paginatedEventsData])

    return (
        <div>
            <TabGroup selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <TabList className="border-b space-x-6">
                    <Tab>
                        {({ selected }) => (
                            <TabButton isActive={selected}>{t("awareness.overview.eventsGraph.legend.sent")}</TabButton>
                        )}
                    </Tab>
                    <Tab>
                        {({ selected }) => (
                            <TabButton isActive={selected}>
                                {t("awareness.overview.eventsGraph.legend.reported")}
                            </TabButton>
                        )}
                    </Tab>
                    <Tab>
                        {({ selected }) => (
                            <TabButton isActive={selected}>
                                {t("awareness.overview.eventsGraph.legend.phished")}
                            </TabButton>
                        )}
                    </Tab>
                </TabList>
                <TabPanels className="mt-8">
                    <TabPanel>
                        <EventsTable
                            eventsType="sent"
                            isPending={isPending}
                            isFetching={isFetching}
                            fetchNextPage={fetchNextPage}
                            hasNextPage={hasNextPage}
                            awarenessEvents={eventsData.filter((event) => event.type === "sent")}
                        />
                    </TabPanel>
                    <TabPanel>
                        <EventsTable
                            eventsType="reported"
                            isPending={isPending}
                            isFetching={isFetching}
                            fetchNextPage={fetchNextPage}
                            hasNextPage={hasNextPage}
                            awarenessEvents={eventsData.filter((event) => event.type === "reported")}
                        />
                    </TabPanel>
                    <TabPanel>
                        <EventsTable
                            eventsType="phished"
                            isPending={isPending}
                            isFetching={isFetching}
                            fetchNextPage={fetchNextPage}
                            hasNextPage={hasNextPage}
                            awarenessEvents={eventsData.filter((event) => event.type === "phished")}
                        />
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div>
    )
}

import { IDetectionV2 } from "../types"
import { ResolutionStatusIcon } from "./ResolutionStatusIcon"
import { DATE_FORMATS, formatDate } from "@utils/dateUtils"
import { XMarkIcon, DocumentDuplicateIcon, InformationCircleIcon } from "@heroicons/react/24/outline"
import { Trans, useTranslation } from "react-i18next"
import Tippy from "@tippyjs/react"
import { showWithFallbackValue } from "@utils/formatUtils"
import copy from "copy-to-clipboard"
import { toastService } from "@/services/toastService"
import EyeIconLogo from "@assets/logos/eye-e-logo.svg?react"
import { ReadMoreSection } from "@designSystem/typography/ReadMoreSection"
import { ConclusionBadge } from "@features/detections/components/ConclusionBadge"
import { DetectionTimeline } from "./DetectionTimeline"
import { Button, Typography } from "@eyectrl/baseplate-design-system"

interface Props {
    detection: IDetectionV2
    clearSelectedDetection: () => void
}

export const DetectionDetails: React.FC<Props> = ({ detection, clearSelectedDetection }) => {
    const { t } = useTranslation()
    const categoryDisplay = detection.category.replaceAll("_", " ")

    const copyDetectionToClipboard = () => {
        copy(window.location.href)
        toastService.successMessage(t("detections:detailsView.copiedToClipboard"), { position: "top-center" })
    }

    // Check if any of the metadata items is filled
    const hasDetails = detection.metadata && Object.values(detection.metadata).some((value) => value !== null)

    return (
        <div className="p-8">
            <section className="flex border-b pb-4 w-full justify-between">
                <div className="flex items-center flex-1 gap-4">
                    <ResolutionStatusIcon status={detection.resolution_status} />

                    <div className="w-[240px] overflow-hidden">
                        <Tippy content={categoryDisplay}>
                            <Typography
                                variant="body-1-semibold"
                                className="capitalize w-full"
                                textElipsis
                                shouldPreventWrapping
                            >
                                {categoryDisplay}
                            </Typography>
                        </Tippy>

                        <Tippy content={detection.asset_identifier}>
                            <Typography
                                variant="body-3"
                                className="capitalize w-full"
                                textElipsis
                                shouldPreventWrapping
                            >
                                {detection.asset_identifier}
                            </Typography>
                        </Tippy>
                    </div>
                </div>

                <div className="flex self-start gap-1">
                    <Button variant="text" onClick={copyDetectionToClipboard} withPadding={false}>
                        <DocumentDuplicateIcon width={20} className="text-text-secondary-light" />
                    </Button>
                    <Button variant="text" onClick={clearSelectedDetection} withPadding={false}>
                        <XMarkIcon width={20} className="text-text-secondary-light" />
                    </Button>
                </div>
            </section>

            <section className="space-y-2 border-b py-4">
                <div className="flex items-center gap-2">
                    <Typography variant="body-2" color="text-secondary" className="w-[125px]">
                        {t("detections:detailsView.conclusion")}
                    </Typography>

                    <ConclusionBadge value={detection.resolution_status ?? ""} />
                </div>

                {detection.triggered_at && (
                    <div className="flex items-center gap-2">
                        <Typography variant="body-2" color="text-secondary" className="w-[125px]">
                            {t("detections:detailsView.date")}
                        </Typography>
                        <Typography variant="body-2">
                            {formatDate(detection.triggered_at, DATE_FORMATS.DATE_TIME_FORMAT)}
                        </Typography>
                    </div>
                )}

                <div className="flex items-center gap-2">
                    <Typography variant="body-2" color="text-secondary" className="w-[125px]">
                        {t("detections:detailsView.source")}
                    </Typography>
                    <Typography variant="body-2">{detection.service}</Typography>
                </div>
                <div className="flex items-center gap-2 ">
                    <div className="flex items-center gap-1 w-[125px]">
                        <Typography variant="body-2" color="text-secondary">
                            {t("detections:detailsView.contacted")}
                        </Typography>
                        <Tippy
                            interactive
                            className="w-[280px]"
                            content={
                                <Trans
                                    i18nKey="detections:detailsView.contactedTooltip"
                                    components={[
                                        <a
                                            key="kb-link"
                                            className="inline-block underline font-semibold"
                                            href="https://kb.eye.security/resolved-detections"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        />,
                                    ]}
                                />
                            }
                        >
                            <InformationCircleIcon width={12} className="text-text-secondary-light" />
                        </Tippy>
                    </div>
                    <Typography variant="body-2">
                        {detection.contacted ? t("detections:detailsView.yes") : t("detections:detailsView.no")}
                    </Typography>
                </div>
            </section>

            {/* Renders the public notes if available */}
            {detection.public_notes && (
                <section className="space-y-1 p-4 bg-brand-eye-ice/20 border border-stepper-background-done-light rounded-md mt-4">
                    <div className="flex items-center gap-3 mb-3">
                        <EyeIconLogo width={30} height={30} />
                        <Typography variant="body-2-semibold">{t("detections:detailsView.notes.title")}</Typography>
                    </div>

                    <ReadMoreSection
                        text={detection.public_notes}
                        typographyProps={{
                            variant: "body-2",
                            shouldPreserveNewLines: true,
                            className: "first-letter:capitalize",
                        }}
                        className="mb-8"
                        collapseAfter={50}
                        collapseThreshold={10}
                    />
                </section>
            )}

            <DetectionTimeline detection={detection} />

            <section className="space-y-1 py-4">
                <Typography variant="body-2-semibold">{t("detections:detailsView.details")}</Typography>

                {!hasDetails && (
                    <Typography variant="body-2" color="text-secondary">
                        {t("detections:detailsView.noDetailsProvided")}
                    </Typography>
                )}

                {detection.metadata?.file_path !== null && (
                    <div className="flex items-center gap-2">
                        <Typography variant="body-2" color="text-secondary" className="w-[125px] shrink-0">
                            {t("detections:detailsView.metaData.filePath")}
                        </Typography>

                        <Typography variant="body-2" className="break-all">
                            {showWithFallbackValue(detection.metadata?.file_path)}
                        </Typography>
                    </div>
                )}

                {detection.metadata?.os_platform !== null && (
                    <div className="flex items-center gap-2">
                        <Typography variant="body-2" color="text-secondary" className="w-[125px] shrink-0">
                            {t("detections:detailsView.metaData.osPlatform")}
                        </Typography>
                        <Typography variant="body-2" className="break-all">
                            {showWithFallbackValue(detection.metadata?.os_platform)}
                        </Typography>
                    </div>
                )}

                {detection.metadata?.version !== null && (
                    <div className="flex items-center gap-2">
                        <Typography variant="body-2" color="text-secondary" className="w-[125px] shrink-0">
                            {t("detections:detailsView.metaData.version")}
                        </Typography>
                        <Typography variant="body-2" className="break-all">
                            {showWithFallbackValue(detection.metadata?.version)}
                        </Typography>
                    </div>
                )}

                {detection.metadata?.ip_address !== null && (
                    <div className="flex items-center gap-2">
                        <Typography variant="body-2" color="text-secondary" className="w-[125px] shrink-0">
                            {t("detections:detailsView.metaData.ipAddress")}
                        </Typography>
                        <Typography variant="body-2" className="break-all">
                            {showWithFallbackValue(detection.metadata?.ip_address)}
                        </Typography>
                    </div>
                )}

                {detection.metadata?.user_agent !== null && (
                    <div className="flex items-center gap-2">
                        <Typography variant="body-2" color="text-secondary" className="w-[125px] shrink-0">
                            {t("detections:detailsView.metaData.userAgent")}
                        </Typography>
                        <Typography variant="body-2" className="break-all">
                            {showWithFallbackValue(detection.metadata?.user_agent)}
                        </Typography>
                    </div>
                )}

                {detection.metadata?.user_principal_name !== null && (
                    <div className="flex items-center gap-2">
                        <Typography variant="body-2" color="text-secondary" className="w-[125px] shrink-0">
                            {t("detections:detailsView.metaData.userPrincipalName")}
                        </Typography>
                        <Typography variant="body-2" className="break-all">
                            {showWithFallbackValue(detection.metadata?.user_principal_name)}
                        </Typography>
                    </div>
                )}
            </section>

            <div>
                <div className="h-[2px] bg-border-primary-light w-[80px]"></div>
                <div className="mt-4">
                    <Typography variant="body-3">{t("detections:walkthrough.questions.title")}</Typography>
                    <a
                        href="https://kb.eye.security/resolved-detections"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline"
                    >
                        <Typography variant="body-3-semibold" color="text-link">
                            {t("detections:walkthrough.questions.helpCenter")}
                        </Typography>
                    </a>
                </div>
            </div>
        </div>
    )
}

import { Button } from "@eyectrl/baseplate-design-system"
import { Typography } from "@eyectrl/baseplate-design-system"
import React from "react"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { IHotspotProps } from "./hotspotTypes"

export const HotspotTooltip: React.FC<IHotspotProps> = ({ title, description, buttonText }) => {
    return (
        <div className="dark px-2 py-3 w-[280px]">
            <div className="space-y-2">
                <div className="flex items-start justify-between">
                    <Typography variant="header-5">{title}</Typography>{" "}
                    <button className="hover:stroke-neutral-300">
                        <XMarkIcon width={18} className="text-text-secondary-light" />
                    </button>
                </div>
                <Typography variant="body-2">{description}</Typography>
            </div>
            <div className="mt-3">
                <Button variant="primary" size="small">
                    {buttonText}
                </Button>
            </div>
        </div>
    )
}

import { useTranslation } from "react-i18next"
import { PageHeader } from "../../../header/PageHeader"
import { PageLayout } from "../../../layout/PageLayout"
import { Skeleton } from "./Skeleton"

export const RecommendationSkeleton: React.FunctionComponent = () => {
    const { t } = useTranslation(["pages"])

    return (
        <PageLayout variant="detailPage">
            <div className="flex gap-4 mb-6">
                <Skeleton className="w-[50px] h-[25px]" />
                <Skeleton className="w-[50px] h-[25px]" />
                <Skeleton className="w-[50px] h-[25px]" />
            </div>
            <PageHeader title={t("recommendations.title")} />
            <div className="flex flex-col gap-6 ">
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
            </div>
        </PageLayout>
    )
}

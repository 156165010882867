import React from "react"
import { TimelineItem } from "./TimelineItem"
import { TimelineWrapper } from "./TimelineWrapper"

interface ITimelineItem {
    icon: React.ReactNode
    iconBackground: string
    content: string
    date?: string
}

interface Props {
    items: ITimelineItem[]
}

export const Timeline: React.FC<Props> = ({ items }) => {
    return (
        <TimelineWrapper>
            {items.map((timelineItem, index) => (
                <TimelineItem
                    key={`timeline-item-${index}`}
                    icon={timelineItem.icon}
                    iconBackground={timelineItem.iconBackground}
                    content={timelineItem.content}
                    hasLine={index < items.length - 1}
                    date={timelineItem.date}
                />
            ))}
        </TimelineWrapper>
    )
}

import {
    Table,
    TableBody,
    TableCell,
    TableColumnHeaderCell,
    TableHeader,
    TableRow,
    TableWrapper,
} from "@eyectrl/baseplate-design-system"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
    MfaPolicies: string[]
}

export const MFAPoliciesTable: React.FC<Props> = ({ MfaPolicies }) => {
    const { t } = useTranslation(["pages"])

    return (
        <TableWrapper isRounded>
            <Table isRounded>
                <TableHeader>
                    <TableRow>
                        <TableColumnHeaderCell>{t("recommendations.policies")}</TableColumnHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {MfaPolicies.map((policy, index) => {
                        return (
                            <TableRow key={`recommendation-asset-${policy}-${index}`}>
                                <TableCell>{policy}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableWrapper>
    )
}

import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import { MarkdownText } from "@components/markdownText/MarkdownText"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import { Checkbox, Typography } from "@eyectrl/baseplate-design-system"
import { ThreatHuntAssetsTable } from "@features/threatHunting/components/ThreatHuntAssetsTable"
import { ThreatHuntInfoBlock } from "@features/threatHunting/components/ThreatHuntInfoBlock"
import { useSingleThreatHunt } from "@features/threatHunting/hooks/useSingleThreatHunt"
import { useActiveEyed } from "@hooks/useActiveEyed"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import AwaitingDataIllustration from "@assets/illustrations/awaitingDataIllustration.svg?react"
import { ThreatHuntingSkeleton } from "@designSystem/feedback/skeletons/ThreatHuntingSkeleton"
import { InsuranceActionRequiredBanner } from "@features/threatHunting/components/InsuranceActionRequiredBanner"
import { Button } from "@eyectrl/baseplate-design-system"
import Modal from "@designSystem/overlays/modal/Modal"
import { useAcknowledgeThreatHunt } from "@features/threatHunting/hooks/useAcknowledgeThreatHunt"
import FeedbackModal from "@designSystem/overlays/modal/FeedbackModal"
import { UserContext } from "@/providers/UserContext"

export const ThreatHuntDetailPage: React.FC = () => {
    const { id = "" } = useParams()
    const { t } = useTranslation()
    const { userProfile, updateUserProfileProperty } = useContext(UserContext)
    const activeEyed = useActiveEyed()
    const { selectedThreatHunt, isLoading } = useSingleThreatHunt(activeEyed, id)
    const [acknowlegdeModalActive, setAcknowlegdeModalActive] = useState(false)
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false)

    const { mutate, isPending } = useAcknowledgeThreatHunt(() => {
        setAcknowlegdeModalActive(false)
    })

    const showAcknowledgementBar =
        selectedThreatHunt && selectedThreatHunt?.acknowledged !== true && "assets" in selectedThreatHunt

    if (isLoading) {
        return (
            <PageLayout variant="detailPage">
                <ThreatHuntingSkeleton />
            </PageLayout>
        )
    }

    if (!selectedThreatHunt) {
        return (
            <PageLayout variant="detailPage">
                <NoDataMessage
                    title={t("threatHunting:detailPage.messages.notFoundTitle")}
                    subtitle={t("threatHunting:detailPage.messages.noDataSubtitle")}
                    variant="row"
                    image={<AwaitingDataIllustration className="h-[250px]" />}
                />
            </PageLayout>
        )
    }

    const onClickAcknowledge = () => {
        if (userProfile.skip_threathunting_acknowledgement_confirmation) {
            onAcknowledgeThreatHunt()
        } else {
            setAcknowlegdeModalActive(true)
        }
    }

    const onAcknowledgeThreatHunt = () => {
        mutate({ eyed: activeEyed, threathunt_id: selectedThreatHunt.uuid })
    }

    const formatSubject = (label?: string): string | undefined => {
        if (label) {
            return `Subject: ${label}`
        }

        return undefined
    }

    return (
        <PageLayout variant="emptyPage">
            <Modal open={acknowlegdeModalActive} setOpen={setAcknowlegdeModalActive} className="p-10" withCloseButton>
                <div className="w-[400px] text-left">
                    <Typography variant="header-2">{t("threatHunting:detailPage.acknowledge.confirm")}</Typography>
                    <Typography className="mt-4">{t("threatHunting:detailPage.acknowledge.confirmText")}</Typography>
                    <div className="mt-4">
                        <Checkbox
                            label={t("threatHunting:detailPage.acknowledge.dontAsk")}
                            onCheck={(checked) => {
                                updateUserProfileProperty({ skip_threathunting_acknowledgement_confirmation: checked })
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-end gap-2">
                        <Button
                            variant="secondary"
                            size="small"
                            className="mt-6"
                            onClick={() => setAcknowlegdeModalActive(false)}
                        >
                            {t("threatHunting:detailPage.acknowledge.cancel")}
                        </Button>
                        <Button
                            variant="primary"
                            size="small"
                            className="mt-6"
                            isLoading={isPending}
                            disabled={isPending}
                            onClick={() => {
                                onAcknowledgeThreatHunt()
                            }}
                        >
                            {t("threatHunting:detailPage.acknowledge.button")}
                        </Button>
                    </div>
                </div>
            </Modal>

            <FeedbackModal
                open={isFeedbackOpen}
                setOpen={() => setIsFeedbackOpen(false)}
                hideRating
                subject={formatSubject(selectedThreatHunt.name)}
            />

            <div className="h-full w-full flex flex-col justify-between overflow-auto">
                <section className="detail-page-container">
                    <PageHeader withBreadcrumbs title={selectedThreatHunt?.name ?? ""} />

                    <InsuranceActionRequiredBanner threatHunt={selectedThreatHunt} />

                    <ThreatHuntInfoBlock threatHunt={selectedThreatHunt} />

                    <div className="flex items-center mt-2">
                        <Button variant="text" onClick={() => setIsFeedbackOpen(true)} withPadding={false}>
                            {t("common:provideFeedback")}
                        </Button>
                    </div>

                    {selectedThreatHunt.assessment?.customerFacingSummary && (
                        <section className="mt-6">
                            <Typography variant="header-3" spacingBottom>
                                {t("recommendations.modaldescription")}
                            </Typography>
                            <div>
                                <MarkdownText>{selectedThreatHunt.assessment?.customerFacingSummary}</MarkdownText>
                            </div>
                        </section>
                    )}

                    {selectedThreatHunt.assessment?.mitigation && (
                        <section className="mt-6">
                            <Typography variant="header-5" spacingBottom>
                                {t("threatHunting:detailPage.remediationActions")}
                            </Typography>
                            <div>
                                <MarkdownText>{selectedThreatHunt.assessment.mitigation}</MarkdownText>
                            </div>
                        </section>
                    )}

                    {"assets" in selectedThreatHunt && selectedThreatHunt.assets?.length > 0 && (
                        <section className="mt-6">
                            <Typography variant="header-5" className="mb-4">
                                {t("threatHunting:detailPage.affectedAssets")}
                            </Typography>
                            <ThreatHuntAssetsTable assets={selectedThreatHunt.assets} />
                        </section>
                    )}
                </section>
                {showAcknowledgementBar && (
                    <div className="flex justify-end items-center gap-4 border-t p-6">
                        <Button variant="primary" size="small" onClick={onClickAcknowledge} disabled={isPending}>
                            {t("threatHunting:detailPage.acknowledge.button")}
                        </Button>
                    </div>
                )}
            </div>
        </PageLayout>
    )
}

import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import { Skeleton } from "@designSystem/feedback/skeletons/Skeleton"
import React from "react"
import { useTranslation } from "react-i18next"

export const RecipientsSkeleton: React.FC = () => {
    const { t } = useTranslation()

    return (
        <PageLayout variant="detailPage">
            <div className="flex gap-4 mb-6">
                <Skeleton className="w-[50px] h-[25px]" />
                <Skeleton className="w-[50px] h-[25px]" />
                <Skeleton className="w-[50px] h-[25px]" />
            </div>
            <PageHeader title={t("awareness.recipientInfo.pageTitle")} />
            <div className="flex flex-col gap-6 ">
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
                <Skeleton className="w-[60%] h-[25px]" />
            </div>

            <div className="flex flex-col gap-6 mt-12 ">
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
                <Skeleton className="w-[100%] h-[25px]" />
            </div>
        </PageLayout>
    )
}

import { PageOption, getPagination } from "@utils/paginationUtils"
import { useMemo, useState } from "react"

export const PAGE_OPTION_SMALL = 10
export const PAGE_OPTION_LARGE = 100
export const PAGE_OPTION_ALL = "ALL"

export const usePaginationState = (
    collectionSize: number,
    pageOptions: Array<PageOption> = [PAGE_OPTION_SMALL, PAGE_OPTION_LARGE, "ALL"],
): {
    currentPage: number
    numberOfPages: number
    pagination: Array<string | number>
    goToNextPage: () => void
    goToPreviousPage: () => void
    goToSpecificPage: (page: number) => void
    pageSize: PageOption
    setPageSize: (size: PageOption) => void
    pageOptions: Array<PageOption>
} => {
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(pageOptions[0])

    const setPageSizeAndResetCurrentPage = (size: PageOption): void => {
        setPageSize(size)
        setCurrentPage(1)
    }

    const numberOfPages = pageSize === "ALL" ? 0 : Math.ceil(collectionSize / pageSize)
    const pageSizeToUse = pageSize === "ALL" ? 0 : pageSize

    const pagination = useMemo(() => {
        return getPagination(collectionSize, pageSizeToUse, currentPage)
    }, [collectionSize, currentPage, pageSizeToUse])

    const goToNextPage = (): void => {
        if (currentPage + 1 <= numberOfPages) {
            setCurrentPage(currentPage + 1)
        }
    }

    const goToPreviousPage = (): void => {
        if (currentPage + -1 >= 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const goToSpecificPage = (page: number): void => {
        if (page > 0 && page <= numberOfPages) {
            setCurrentPage(page)
        }
    }
    return {
        currentPage,
        numberOfPages,
        pagination,
        goToNextPage,
        goToPreviousPage,
        goToSpecificPage,
        pageSize,
        setPageSize: setPageSizeAndResetCurrentPage,
        pageOptions,
    }
}

import { useUrlSyncingDateRangePickerState } from "@designSystem/inputs/datePicker/useDateRangePickerState"
import { useGetAllSearchParams } from "@hooks/useGetAllSearchParams"
import { getValidDateRange } from "@utils/dateRangeUtils"
import React from "react"
import { useState } from "react"

export interface IDetectionsFilterSettings {
    normalBehaviour: boolean
    abnormalBehaviour: boolean
    maliciousBehaviour: boolean
    // contacted: boolean
}

export type IDetectionsFilterOption = "triggered_at" | "resolved_at" | "asset_identifier"
export type IDetectionsSortDirection = "ASC" | "DESC"

export const useDetectionsFilters = () => {
    const [searchValue, setSearchValue] = useState("")
    const [sortBy, setSortBy] = useState<IDetectionsFilterOption>("triggered_at")
    const { dateRange, setSelectedDateRange } = useUrlSyncingDateRangePickerState()
    const { fromTime, toTime } = useGetAllSearchParams()
    const [sortDirection, setSortDirection] = useState<IDetectionsSortDirection>("DESC")

    const hasCustomDateRange = fromTime && toTime
    const [filterSettings, setFiltersetting] = useState<IDetectionsFilterSettings>({
        normalBehaviour: true,
        abnormalBehaviour: true,
        maliciousBehaviour: true,
        // contacted: true,
    })

    // Everytime the dateRange changes, we make a validated copy which gets send to the api.
    // This has the following corrected:
    // 1. The from to order is set chronologically
    // 2. The from and to have their startOfDay and endOfDay set
    // 3. The range is stripped of of future dates.
    const validatedRange = React.useMemo(() => {
        return getValidDateRange({ from: dateRange?.from, to: dateRange?.to })
    }, [dateRange])

    return {
        searchValue,
        setSearchValue,
        sortBy,
        setSortBy,
        dateRange,
        setSelectedDateRange,
        hasCustomDateRange,
        filterSettings,
        setFiltersetting,
        validatedRange,
        sortDirection,
        setSortDirection,
    }
}

import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import React from "react"
import { useFeatureFlagPayload } from "posthog-js/react"
import { isAnnouncement } from "@utils/announcementUtils"
import { AnnouncementBlock } from "@components/announcements/AnnouncementBlock"
import { Typography } from "@eyectrl/baseplate-design-system"
import { useTranslation } from "react-i18next"
import { featureFlagConfig } from "@config/featureFlagConfig"

export const Announcements: React.FC = () => {
    const announcementFlag = useFeatureFlagPayload(featureFlagConfig.announcementBanner)
    const { t } = useTranslation()

    return (
        <PageLayout variant="detailPage">
            <PageHeader title={t("announcements.title")} subtitle={t("announcements.subtitle")} />

            <div className="border-t pt-8">
                {isAnnouncement(announcementFlag) ? (
                    <AnnouncementBlock announcement={announcementFlag} />
                ) : (
                    <Typography color="text-secondary">{t("announcements.noAnnouncements")}</Typography>
                )}
            </div>
        </PageLayout>
    )
}

import React, { useMemo } from "react"
import { IManagementReportListItem } from "../reportTypes"
import {
    SortingState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import i18n from "@/i18next"
import {
    SortableTableHeader,
    Table,
    TableBody,
    TableCell,
    TableColumnHeaderCell,
    TableHeader,
    TableRow,
    Typography,
} from "@eyectrl/baseplate-design-system"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import { TablePaginationWrapper } from "@designSystem/layout/table/TablePaginationWrapper"
import { t } from "i18next"
import SearchIllustration from "@assets/illustrations/searchIllustration.svg?react"
import { formatYearMonth } from "@utils/dateUtils"
import { DownloadScheduledReportButton } from "./DownloadScheduledReportButton"
import { groupBy } from "lodash"

interface Props {
    managementReports: IManagementReportListItem[]
}

interface IManagementReportTableItem {
    year: string
    month: string
    languages: string[]
}

const columnHelper = createColumnHelper<IManagementReportTableItem>()

const tableColumns = [
    columnHelper.accessor((row) => `${row.year}-${row.month}`, {
        id: "date",
        header: i18n.t("managementReports:table.date"),
        cell: (info) => (
            <Typography textElipsis shouldPreventWrapping color="text-inactive">
                {info.getValue()}
            </Typography>
        ),
        size: 100,
    }),
    columnHelper.accessor((row) => `${row.year}-${row.month}`, {
        id: "name",
        header: i18n.t("managementReports:table.name"),
        cell: (info) => (
            <Typography textElipsis shouldPreventWrapping>
                {`${i18n.t("managementReports:securityPosture")} ${formatYearMonth(info.getValue())}`}
            </Typography>
        ),
        size: 250,
    }),
    columnHelper.display({
        id: "type",
        header: i18n.t("managementReports:table.type"),
        cell: () => (
            <Typography textElipsis shouldPreventWrapping>
                {`${i18n.t("managementReports:securityPosture")}`}
            </Typography>
        ),
    }),
    columnHelper.display({
        id: "download",
        cell: (info) => (
            <div className="flex justify-end">
                <DownloadScheduledReportButton
                    date={`${info.row.original.year}-${info.row.original.month}`}
                    languages={info.row.original.languages}
                />
            </div>
        ),
    }),
]

export const MangementReportsTable: React.FC<Props> = ({ managementReports }) => {
    const [sorting, setSorting] = React.useState<SortingState>([])

    const tableItems: IManagementReportTableItem[] = useMemo(() => {
        // First put the reports in correct order
        const orderedData = managementReports.reverse()
        // Group the reports by their year/month properties
        const groupedData = groupBy(orderedData, (item) => `${item.year}-${item.month}`)
        // Create a new mapping, combine year/month items with the available languages
        const tableMapping: IManagementReportTableItem[] = Object.entries(groupedData).map(([key, value]) => ({
            year: key.split("-")[0],
            month: key.split("-")[1],
            languages: value.map((item) => item.language).sort((a, b) => a.localeCompare(b)),
        }))
        return tableMapping
    }, [managementReports])

    const table = useReactTable({
        state: {
            sorting,
        },
        data: tableItems,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
    })

    return (
        <TablePaginationWrapper data={tableItems} table={table} isRounded isVisible>
            <Table isRounded>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableColumnHeaderCell key={header.id} width={header.getSize()}>
                                        <div
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer select-none"
                                        >
                                            <SortableTableHeader
                                                getIsSorted={() => {
                                                    return header.getContext().header.column.getIsSorted()
                                                }}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </SortableTableHeader>
                                        </div>
                                    </TableColumnHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>

                <TableBody>
                    {table.getRowModel().rows.length === 0 && (
                        <TableRow>
                            <td colSpan={table.getAllColumns().length} className="p-12">
                                <NoDataMessage
                                    title={t("managementReports:table.noDataMessage.title")}
                                    subtitle={t("managementReports:table.noDataMessage.description")}
                                    variant="row"
                                    image={<SearchIllustration className="w-[250px]" />}
                                />
                            </td>
                        </TableRow>
                    )}

                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id} width={cell.column.getSize()}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TablePaginationWrapper>
    )
}

import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { ClientDetectionAssset, IDetection } from "../detectionsTypes"
import { IDateRange } from "@utils/dateRangeUtils"
import { getDetectionsEndpoint } from "@/api/endpoints"
import { getDetectionsSummary } from "@utils/detectionUtils"
import { useAuth0 } from "@auth0/auth0-react"
import { getRequest } from "@/api/requests"

interface DetectionsDataSource {
    data: IDetection[] | null
    meta_data?: {
        last_updated: string
    } | null
}

const useGetDetectionsByEyed = (
    eyed: string,
    apiDateRange?: IDateRange,
): UseQueryResult<DetectionsDataSource, Error> => {
    const { getAccessTokenSilently } = useAuth0()

    const detectionsEndpoint = getDetectionsEndpoint(eyed, apiDateRange?.from, apiDateRange?.to)

    return useQuery<DetectionsDataSource, Error>({
        queryKey: detectionsEndpoint.queryKey,
        queryFn: () => getRequest(detectionsEndpoint.url, getAccessTokenSilently),
    })
}

type DetectionReturn = UseQueryResult<DetectionsDataSource, Error> & {
    detectionsSummary: ClientDetectionAssset[]
    length: number
}

/**
 * Returns a list of detections given the eyed and an optional dateRange
 * NOTE: Make sure the date range is valid ( i.e. correct from / to set, no future dates are provided.)
 * @param eyed
 * @param dateRange
 * @returns
 */
export const useDetections = (eyed: string, dateRange?: IDateRange): DetectionReturn => {
    const detectionsQuery = useGetDetectionsByEyed(eyed, dateRange)

    const detections: IDetection[] = detectionsQuery.data?.data ?? []

    return {
        ...detectionsQuery,
        detectionsSummary: getDetectionsSummary(detections),
        length: detections.length,
    }
}

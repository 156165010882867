import { getOrganisationThreatHuntsEndpoint, getThreatHuntsEndpoint, patchAcknowledgeThreatHunt } from "@/api/endpoints"
import { patchRequest } from "@/api/requests"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { toastService } from "@/services/toastService"
import { useAuth0 } from "@auth0/auth0-react"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

const toastId = "ack_toast"

export const useAcknowledgeThreatHunt = (onSuccess?: () => void) => {
    const { getAccessTokenSilently } = useAuth0()
    const queryClient = useQueryClient()
    const activeEyed = useActiveEyed()
    const { t } = useTranslation()
    const { captureEvent } = useCapturePostHogEvent()

    return useMutation({
        onMutate: () => {
            captureEvent(POSTHOG_CAPTURE_TYPES.THREAT_HUNTING.ACKNOWLEDGED_THREAT_HUNT)
        },
        mutationFn: (variables: { eyed: string; threathunt_id: string }) => {
            const endpoint = patchAcknowledgeThreatHunt(variables.eyed, variables.threathunt_id)
            toastService.loadingMessage(t("toastMessages:threatHunts.acknowledge.loading"), { id: toastId })
            return patchRequest(endpoint.url, undefined, getAccessTokenSilently)
        },
        onError: () => {
            toastService.errorMessage(t("toastMessages:threatHunts.acknowledge.error"), { id: toastId })
        },
        onSuccess: () => {
            toastService.successMessage(t("toastMessages:threatHunts.acknowledge.success"), { id: toastId })

            // Once a threat hunt is acknowledged, we can invalidate the both threat hunt api calls.
            onSuccess?.()
            queryClient.invalidateQueries({ queryKey: getThreatHuntsEndpoint(activeEyed).queryKey })
            queryClient.invalidateQueries({ queryKey: getOrganisationThreatHuntsEndpoint(activeEyed).queryKey })
        },
    })
}

import { Typography, TypographyProps } from "@eyectrl/baseplate-design-system"
import { useAwarenessLanguages } from "@features/awareness/hooks/useAwarenessLanguages"
import React from "react"

/**
 * Component that takes a language code and displays the language name.
 */
export const DisplayLanguage: React.FC<TypographyProps & { value: string }> = (props) => {
    const { data } = useAwarenessLanguages()
    // Try to match the value to a language code in the languages array. If not we simply show the current value.
    const languages = data?.data ?? []
    const displayName = languages.find((lang) => lang.Code === props.value)?.Name ?? props.value

    return <Typography {...props}>{displayName}</Typography>
}

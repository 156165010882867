import React from "react"
import { formatDate, getRelativeDateRepresentationToNow } from "@utils/dateUtils"
import Tippy from "@tippyjs/react"
import { isNullish } from "@utils/formatUtils"
import { Typography } from "@eyectrl/baseplate-design-system"

interface Props {
    timestamp: string | null | undefined
    prefix?: string
    tooltipPlacement?: "top" | "right" | "bottom" | "left"
    testId?: string
    id?: string
}

export const TimeAgoDate: React.FC<Props> = ({ timestamp, prefix = "", tooltipPlacement = "top", testId }) => {
    if (isNullish(timestamp)) {
        return null
    }

    const timeAgo = getRelativeDateRepresentationToNow(timestamp)
    const readableDate = formatDate(timestamp)

    if (!timeAgo) {
        return null
    }

    return (
        <div className="relative group inline-block" data-testid={testId}>
            <Tippy content={readableDate} placement={tooltipPlacement}>
                <Typography color="text-secondary">{`${prefix}${timeAgo}`}</Typography>
            </Tippy>
        </div>
    )
}

export const buildURLWithQueryParams = (
    endpoint: string,
    from?: string,
    to?: string,
    searchQuery?: string,
    sortBy?: string,
    sortDirection?: "ASC" | "DESC",
    resolutionStatusTags?: string[],
): string => {
    const params = new URLSearchParams()

    if (from) {
        params.append("fromTime", from)
    }

    if (to) {
        params.append("toTime", to)
    }

    if (searchQuery) {
        params.append("search_asset_identifier_filter", searchQuery)
        // Can be added if we want to filter on title again.
        // params.append("search_title_filter", searchQuery)
    }

    if (sortBy && sortDirection) {
        params.append("sorting_field", sortBy)
        params.append("sorting_direction", sortDirection)
    }

    if (resolutionStatusTags) {
        params.append("resolution_status_filter", resolutionStatusTags.join(","))
    }

    return `${endpoint}?${params.toString()}`
}

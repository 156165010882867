import { Typography } from "@eyectrl/baseplate-design-system"
import classNames from "classnames"
import { motion } from "framer-motion"
import React from "react"

interface Props {
    title: string
    subtitle: string
    isActive: boolean
}

const widthClass = "w-[100px] xl:w-[175px] 2xl:w-[250px]"
export const StepHeader: React.FC<Props> = ({ title, subtitle, isActive }) => {
    return (
        <div>
            <div className={`relative ${widthClass} overflow-hidden`}>
                <motion.div
                    layout
                    className={classNames([
                        "h-[4px] absolute top-0 left-0 z-10 bg-brand-eye-blue",
                        { [`${widthClass}`]: isActive === true },
                        // Animation needs to go to 1px, if 0 it does not animate well
                        // Therefore we offset with a small margin so the remaining block can't be seen.
                        { "-ml-[5px] w-[1px] ": isActive === false },
                    ])}
                />

                <div className={classNames([`${widthClass} h-[4px] bg-neutral-200`])}></div>
            </div>

            <div className="mt-3">
                <Typography variant="body-2" color="text-secondary">
                    {subtitle}
                </Typography>
                <Typography variant="body-1-semibold" color={isActive ? "text-primary" : "text-secondary"}>
                    {title}
                </Typography>
            </div>
        </div>
    )
}

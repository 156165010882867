import { PageHeader } from "@components/header/PageHeader"
import { LinkBlock } from "@designSystem/buttons/LinkBlock"
import { Typography } from "@eyectrl/baseplate-design-system"
import React from "react"
import { BookOpenIcon } from "@heroicons/react/24/outline"
import { useTranslation } from "react-i18next"

export const RecipientsStep: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="">
            <PageHeader title={t("awareness.onboarding.steps.recipientsStep.title")} />

            <Typography>{t("awareness.onboarding.steps.recipientsStep.description")}</Typography>

            <section className="mt-8">
                <div className="max-w-md">
                    <a
                        className=""
                        rel="noopener noreferrer"
                        href={t("awareness.onboarding.steps.recipientsStep.linkTo")}
                        target="_blank"
                    >
                        <LinkBlock
                            title={t("awareness.onboarding.steps.recipientsStep.link")}
                            icon={<BookOpenIcon />}
                        />
                    </a>
                </div>
            </section>

            <section className="mt-12">
                <Typography variant="header-3" spacingBottom>
                    {t("awareness.onboarding.steps.recipientsStep.includedTitle")}
                </Typography>
                <Typography>{t("awareness.onboarding.steps.recipientsStep.includedText")}</Typography>
            </section>
        </div>
    )
}

import { Dialog, Transition, DialogPanel } from "@headlessui/react"
import React, { PropsWithChildren, useRef } from "react"

interface Props {
    open: boolean
    setOpen: (open: boolean) => void
}

const SideClapper: React.FC<PropsWithChildren<Props>> = ({ open, setOpen, children }) => {
    // The dialog focuses the first focusable element, in this case always the logout button
    // To prevent this we can pass a ref to a different element as the initial focus.
    const ref = useRef(null)

    return (
        <div ref={ref}>
            <Dialog as="div" className="relative z-30 block sm:hidden" onClose={setOpen} initialFocus={ref} open={open}>
                {/* The sidebar clapper */}
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-[320px] pr-10">
                            <Transition
                                show={open}
                                enter="transition ease-in-out duration-500 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-500 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <DialogPanel className="pointer-events-auto w-screen max-w-md relative">
                                    <div className="flex h-full flex-col bg-background-brand-primary-light shadow-xl">
                                        <div className="p-6 h-full flex">{children}</div>
                                    </div>
                                </DialogPanel>
                            </Transition>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default SideClapper
